import React, {
  useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import {
  faCheck, faColumns, faTimes, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  updateIsReal, deleteTransaction, exportFilteredDataToFile, updateTransactionState, getTransactionsByQuery,
} from '../../../redux/transactions/transactionActions';
import { resolveDepositTransaction, revertTransaction } from '../../../redux/users/userActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
import { ExternalTransactionsSchema } from '../../../DataTableSchemas/ExternalTransactionsSchema/ExternalTransactionsSchema';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter,
} from '../../../redux/crmUser/crmUserActions';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';
import { DatatableFilters } from '../../../components';
import { depositColumns } from '../../../columnsDefaultConfig';
import { DownloadFile } from '../../../components/DownloadFile';
import { useDebounce } from '../../../hooks/useDebounce';

import styles from './styles.module.css';


function ExternalTransactions({ isDeleted = false }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const paginationStorageName = 'DataTable_deposits/pagination';
  const filtersStorageName = 'DataTable_deposits/filters'; 
  const filterIdStorageName = 'DataTable_deposits/filters_id'; 
  const columnsStorageName = 'DataTable_deposits/columns';
  const depositsFiltersJSON = localStorage.getItem(filtersStorageName);
  const depositsColumnsJSON = localStorage.getItem(columnsStorageName);

  const datatableFiltersRef = useRef(null);
  
  const { paginatedData: transactionsData, totalCount } = useSelector((state) => state?.transaction?.allDeposits);
  const exportFilterdDataToFile = useSelector((state) => state.transaction.exportFilterdDataToFile);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const assignToShow = useSelector((state) => state.crmUser?.crmUsers);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const [loader, setLoader] = useState(false);
  
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [columnConfig, setColumnConfig] = useState(depositColumns);
  const [tableLoading, setTableLoading] = useState(false);

  const [transactionIdFilter, setTransactionIdFilter] = useState();
  const [uidFilter, setUidFilter] = useState();
  const [nameFilter, setNameFilter] = useState();
  const [emailFilter, setEmailFilter] = useState();
  const [assetFilters, setAssetFilters] = useState([]);
  const [managerFilters, setManagerFilters] = useState([]);
  const [amountRange, setAmountRange] = useState(null);
  const [realFilter, setRealFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [isAdditionalInfoModalShown, setIsAdditionalInfoModalShown] = useState('');
  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [depositsFilters, setDepositsFilters] = useState({});
  const [inputFieldError, setInputFieldError] = useState({
    transactionIdIsNotValid: false,
    userIdIsNotValid: false,
    emailIsNotValid: false,
    fullNameIsNotValid: false,
    errorMessage: '',
  });
  
  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && depositsFilters[key] !== value) {
      setDepositsFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(transactionIdFilter, 1000, (value) => debounceCallback({ value, key: 'transactionId' }));
  useDebounce(uidFilter, 1000, (value) => debounceCallback({ value, key: 'userId' }));
  useDebounce(nameFilter, 1000, (value) => debounceCallback({ value, key: 'fullName' }));
  useDebounce(emailFilter, 1000, (value) => debounceCallback({ value, key: 'email' }));
  useDebounce(amountRange, 1000, (value) => debounceCallback({ value, key: 'amount' }));

  const setStoredPagination = () => {
    const depositRowsJSON = localStorage.getItem(paginationStorageName);
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const handleClear = () => {
    setTransactionIdFilter(null);
    setUidFilter(null);
    setNameFilter(null);
    setEmailFilter(null);
    setAssetFilters([]);
    setManagerFilters([]);
    setStatusFilter([]);
    setAmountRange(null);
    setTimeInitiatedFilter([]);
    setRealFilter([]);
    setDepositsFilters({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    if (depositsFiltersJSON) {
      const filters = JSON.parse(depositsFiltersJSON);
      setDepositsFilters(filters || {});

      setTransactionIdFilter(filters.transactionId || null);
      setUidFilter(filters.userId || null);
      setAssetFilters(filters.currency || []);
      setNameFilter(filters.fullName || null);
      setEmailFilter(filters.email || null);
      setManagerFilters(filters['assigned to'] || []);
      setRealFilter(filters['real/fake'] || []);
      setAmountRange(filters.amount || null);
      setStatusFilter(filters.status || []);
      setTimeInitiatedFilter(filters['time initiated'] || []);
    }
  };

  const setStoredColumnsData = () => {
    if (depositsColumnsJSON) {
      const columns = JSON.parse(depositsColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRange([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRange([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRange(value);
      }
    }
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId,
          crmUserId: loginUserId,
          resolvedStatus: status,
          additionalInfo: result.value ? result.value : '',
        };

        return Promise.resolve(dispatch(resolveDepositTransaction(rowId, data, false)))
          .then(() => dispatch(getTransactionsByQuery({
            page, limit: rowsPerPage, query: depositsFilters, isDeleted, 
          })));
      }
      return null;
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = { userId };
        return Promise.resolve(dispatch(revertTransaction(rowId, data, false)))
          .then(() => dispatch(getTransactionsByQuery({
            page, limit: rowsPerPage, query: depositsFilters, isDeleted, 
          })));
      }
      return null;
    });
  };

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(deleteTransaction(id));
      });
  };

  const handleAdditionalInfoModalToggle = (id) => {
    if (isAdditionalInfoModalShown === id) setIsAdditionalInfoModalShown(null);
    else setIsAdditionalInfoModalShown(id);
  };

  const setCRMFilters = (filter) => {
    const {
      fullName, email, depositType, amountRange, currency, status, assignedTo, timeOptionIds, uid, transactionId, 
    } = filter;

    const setObject = {
      transactionId,
      userId: uid,
      email,
      fullName,
      amount: amountRange,
      currency,
      status,
      'real/fake': depositType,
      'assigned to': assignedTo,
      'time initiated': timeOptionIds,
    };

    setAssetFilters(currency || null);
    setTransactionIdFilter(transactionId || null);
    setUidFilter(uid || null);
    setNameFilter(fullName || null);
    setEmailFilter(email || null);
    setManagerFilters(assignedTo || null);
    setRealFilter(depositType || null);
    setAmountRange(amountRange || null);
    setStatusFilter(status || null);
    setTimeInitiatedFilter(timeOptionIds || null);
    setDepositsFilters(setObject);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(depositsFiltersJSON);
    const data = {
      name,
      crmUserId,
      pathname,
      uid: filters?.userId || '',
      transactionId: filters?.transactionId || '',
      fullName: filters?.fullName || '',
      email: filters?.email || '',
      status: filters?.status || [],
      assignedTo: filters?.['assigned to'] || [],
      depositType: filters?.['real/fake'] || [],
      amountRange: filters?.amount?.length ? filters.amount : [0, 1000000],
      currency: filters?.currency || [],
      timeOptionIds: filters?.['time initiated'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        uid: filters?.userId || '',
        transactionId: filters?.transactionId || '',
        fullName: filters?.fullName || '',
        email: filters?.email || '',
        status: filters?.status || [],
        assignedTo: filters?.['assigned to'] || [],
        depositType: filters?.['real/fake'] || [],
        amountRange: filters?.amount?.length ? filters.amount : [0, 1000000],
        currency: filters?.currency || [],
        timeOptionIds: filters?.['time initiated'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handleExportTransactions = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);

    if (transactionsData.length && columns.length) {
      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile(transactionsData, columns, fileType)); 
      setLoader(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    const { roleId, _id: uid } = JSON.parse(localStorage.getItem('user'));
    getAllStoredData();

    Promise.allSettled([
      dispatch(showAllCurrencies()),
      dispatch(getSubCRMUsers(roleId, uid)),
      dispatch(getCurrencyRates('USD')),
    ]);
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(depositsFilters));
        setTableLoading(true);
  
        await dispatch(getTransactionsByQuery({
          page, limit: rowsPerPage, query: depositsFilters, isDeleted, 
        }));
  
        setTableLoading(false);
      }
    }

    fetchData();
  }, [page, rowsPerPage, isPaginationDT, depositsFilters]);

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateTransactionState()); 
      setLoader(false);
    }
  }, [exportFilterdDataToFile]);

  const columns = ExternalTransactionsSchema(
    setTransactionIdFilter,
    permissionName,
    currencies,
    transactionIdFilter,
    uidFilter,
    setUidFilter,
    nameFilter,
    setNameFilter,
    emailFilter, 
    setEmailFilter,
    setAssetFilters,
    assetFilters,
    amountRange,
    setAmountRange,
    handleAdditionalInfoModalToggle,
    setManagerFilters,
    managerFilters,
    realFilter,
    setRealFilter,
    statusFilter,
    setStatusFilter,
    isAdditionalInfoModalShown,
    setIsAdditionalInfoModalShown,
    resolveCurrentDepositTransaction,
    handleRevertTransaction,
    assignToShow, 
    depositsFilters,
    setDepositsFilters,
    columnConfig,
    timeInitiatedFilter,
    setTimeInitiatedFilter,
    updateIsReal,
    handleDeleteTransaction,
    isDeleted,
    setDebounceAmountRange,
    inputFieldError, 
    setInputFieldError,
  );

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h3>Deposits</h3>
          <div className="action__btn-row">
            {crmFilters && (
              <DatatableFilters 
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={pathname}
              /> 
            )}
            <DownloadFile handleExport={handleExportTransactions} />
            <Dropdown className="leads-columns-dropdown ms-1">
              <Dropdown.Toggle 
                variant="" 
                className="btn-default"
                style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <FontAwesomeIcon
                  icon={faColumns}
                  size="xs"
                />
                Columns
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul className="leads-columns-list">
                  {columnConfig.map((column) => (
                    <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                      {column.selected ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#6E7F95"
                        />
                      ) : null}
                      <span className="leads-columns-option__name">{column.name}</span>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>

            <button 
              type="button"
              className={`btn btn-default ms-1 ${styles.mainButton}`}
              onClick={handleClear}
            >
              <FontAwesomeIcon icon={faTimes} size="sm" />
              Clear
            </button>

            <button 
              type="button"
              className={`btn btn-default ms-1 ${styles.mainButton}`}
              onClick={refresh}
            >
              <FontAwesomeIcon icon={faRefresh} size="sm" />
              Refresh
            </button>

          </div>

          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            {
              isPaginationDT && (
                <DataTable
                  columns={columns}
                  data={transactionsData}
                  fixedHeader
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  paginationDefaultPage={page}
                  persistTableHead
                  highlightOnHover
                  theme="solarizedd"
                  progressPending={tableLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
            }
          </div>
        </div>
      </div>
    )
  );
}

export default ExternalTransactions;
