import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { 
  GET_USER_STAKING_SETTINGS, 
  CREATE_USER_STAKING_SETTING, 
  UPDATE_USER_STAKING_SETTING,
  DELETE_USER_STAKING_SETTING,
} from './userStakingTypes';

export const getUserStakingSettings = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-staking/query?userId=${userId}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_USER_STAKING_SETTINGS,
        payload: res.data.stakings,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const createUserStakingSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/user-staking', data);
  
    if (res && res.data && res.data.success) {
      toast.success(res.data.message);

      dispatch({
        type: CREATE_USER_STAKING_SETTING,
        payload: res.data.staking,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateUserStakingSetting = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/user-staking/${id}`, data);
    
    if (res && res.data && res.data.success) {
      toast.success(res.data.message);

      dispatch({
        type: UPDATE_USER_STAKING_SETTING,
        payload: res.data.staking,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteUserStakingSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/user-staking/${id}`, '');
      
    if (res && res.data && res.data.success) {
      toast.success(res.data.message);
  
      dispatch({
        type: DELETE_USER_STAKING_SETTING,
        payload: res.data.staking,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
