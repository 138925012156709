import React from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';

export function TpSLModal({
  showTPSL,
  handleCloseTPSL,
  selectedRow,
  TP, setTP,
  SL, setSL,
  updateTPSL,
}) {
  return (
    <Modal
      className="withdrawal-modal limit-modal tp-sl-modal"
      show={showTPSL}
      centered
      onHide={handleCloseTPSL}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="buy-tabs">
          <div className="mb-4">
            <span>TP/SL</span>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <span>Entry Price</span>
            <span>{selectedRow.tradeStartPrice}</span>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <span>Liq. Price</span>
            <span className="text-green">{selectedRow.tradeEndPrice}</span>
          </div>

          <div className="take-profit-usdt">
            <div className="d-flex justify-content-between mb-1">
              <span>Take Profit USDT</span>
              <span>Last Traded Price</span>
            </div>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                min="0"
                value={TP}
                onChange={(e) => setTP(e.target.value)}
              />
              <InputGroup.Text id="basic-addon2">
                <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Take Profit</span>
                {selectedRow?.fromCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>
          </div>

          <div className="stop-loss-usdt">
            <div className="d-flex justify-content-between mb-1">
              <span>
                Stop Loss
                {selectedRow?.fromCurrency?.symbol}
              </span>
              <span>Last Traded Price</span>
            </div>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                min="0"
                value={SL}
                onChange={(e) => setSL(e.target.value)}
              />
              <InputGroup.Text id="basic-addon2">
                <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Stop Loss</span>
                {selectedRow?.fromCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => updateTPSL()}>
          Confirm
        </Button>
        <Button variant="danger" onClick={handleCloseTPSL}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
