import {
  GET_CRYPTO_PAIRS, GET_PAIR_TABLE_DATA, UPDATE_CRYPTO_PAIR, MASS_UPDATE_CRYPTO_PAIR, 
} from './cryptoPairTypes';

const initialState = {
  pairNames: [],
  tableData: {
    pairs: [],
    totalCount: 0,
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CRYPTO_PAIRS:
      return {
        ...state,
        pairNames: action.payload,
      };
    case GET_PAIR_TABLE_DATA:
      return {
        ...state,
        tableData: action.payload,
      };
    case UPDATE_CRYPTO_PAIR:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          pairs: state.tableData.pairs.map((pair) => {
            if (pair._id === action.payload._id) return action.payload;

            return pair;
          }),
        },
      };
    case MASS_UPDATE_CRYPTO_PAIR:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          pairs: state.tableData.pairs.map((pair) => {
            const updatedPair = action.payload.find((updatedPairs) => updatedPairs._id === pair._id);
    
            return updatedPair || pair;
          }),
        },
      };
    default:
      return state;
  }
};
