import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatusChangesHistory } from '../../../../redux/userStatusChangesHistory/userStatusChangesHistoryActions';
import { selectIsStatusChangesLoading, selectStatusChangesHistory } from '../../../../redux/userStatusChangesHistory/userStatusChangesHistorySelectors';
import FullPageTransparentLoader from '../../../FullPageTransparentLoader/fullPageTransparentLoader';
import styles from './UserStatusHistory.module.css';

export function UserStatusHistory({ userId }) {
  const dispatch = useDispatch();
    
  const history = useSelector(selectStatusChangesHistory);
  const isLoading = useSelector(selectIsStatusChangesLoading);
  
  const dateOptions = {
    year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
  };

  useEffect(() => {
    if (userId) {
      dispatch(getUserStatusChangesHistory(userId));
    }
  }, [userId]);

  return (
    isLoading ? (
      <FullPageTransparentLoader />
    )
      : (
        <div className={styles.wrapper}>
          <div className={styles.headerWrapper}>
            <h5>Status changes</h5>
          </div>
          <div className={styles.bodyWrapper}>
            {
              history && history.length
                ? history.map((data) => {
                  const date = new Date(data.createdAt);
                  return (
                    <span className={styles.statusHistoryRow}>
                      {date.toLocaleDateString('en-US', dateOptions)}
                      {' | '}
                      Status changed 
                      {' ('}
                      {data.previousStatus}
                      {' -> '}
                      {data.actualStatus}
                      {') by '}
                      {data.agentName}
                    </span>
                  );
                })
                : 'There hasn\'t been any activity yet'
            }
          </div>
        </div>
      )
  );
}
