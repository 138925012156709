import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faClone, faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { omitColumn } from './helper';
import { ModalFilters } from '../components';
import { RangeModalFilter } from '../pages/PendingWithdraws/modalFilters';
import { revertExchangeOrder, deleteExchangeOrder } from '../redux/exchangeOrder/exchangeOrderActions';

export const ExchangeListColumns = (
  columnConfig,
  orderCoins,
  orderCoinsFilter,
  setOrderCoinsFilter,
  convertedCoins,
  convertedCoinsFilter,
  setConvertedCoinsFilter,
  statusFilter,
  setStatusFilter,
  orderAmountFilter,
  setOrderAmountFilter,
  convertedAmountFilter,
  setConvertedAmountFilter,
  filters,
  setFilters,
  permissionName,
) => {
  const dispatch = useDispatch();

  const isUserCanRevertTransaction = permissionName && permissionName.includes('revert_transaction');
  const isUserCanDeleteTransaction = permissionName && permissionName.includes('delete_transaction');

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleActionConfirmation = (id, action, actionName) => {
    Swal.fire({
      title: `Are you sure you want to ${actionName} the transaction?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(action(id));
      });
  };
  
  const ordersColumns = [
    {
      name: 'UID',
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice((row?._id.length || 0) - 4, row?._id.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <ModalFilters
          data={orderCoins}
          filters={orderCoinsFilter}
          setFilters={setOrderCoinsFilter}
          tabName="Order Coin"
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row?.fromCurrency?.symbol,
      omit: omitColumn(columnConfig, 'Order Coin'),
    },
    {
      name: (
        <RangeModalFilter
          columnName="Order Amount"
          value={orderAmountFilter}
          setValue={setOrderAmountFilter}
          filters={filters}
          setFilters={setFilters}
          filedName="amountRange"
        />
      ),
      selector: (row) => row?.fromAmount,
      omit: omitColumn(columnConfig, 'Order Amount'),
    },
    {
      name: (
        <ModalFilters
          data={convertedCoins}
          filters={convertedCoinsFilter}
          setFilters={setConvertedCoinsFilter}
          tabName="Converted Coin"
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row?.toCurrency?.symbol,
      omit: omitColumn(columnConfig, 'Converted Coin'),
    },
    {
      name: (
        <RangeModalFilter
          columnName="Converted Amount"
          value={convertedAmountFilter}
          setValue={setConvertedAmountFilter}
          filters={filters}
          setFilters={setFilters}
          filedName="convertedAmountRange"
        />
      ),
      selector: (row) => row?.convertedAmount,
      omit: omitColumn(columnConfig, 'Converted Amount'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Declined', _id: 'false' }, { name: 'Completed', _id: 'true' }]}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status"
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (
        !row.isResolved
          ? <span className="badge rounded-pill bg-warning">Declined</span>
          : <span className="badge rounded-pill bg-success">Completed</span>
      ),
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: 'Ordered At',
      selector: (row) => moment(row.initiatedAt || row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'Ordered At'),
    },
    {
      name: 'Actions',
      selector: (row) => ( 
        <>
          {
            isUserCanRevertTransaction && row.isResolved && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => handleActionConfirmation(row._id, revertExchangeOrder, 'revert')}
              >
                <FontAwesomeIcon icon={faUndo} className="header-icon" />
              </button>
            )
          }
          {
            isUserCanDeleteTransaction && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => handleActionConfirmation(row._id, deleteExchangeOrder, 'delete')}
              >
                <FontAwesomeIcon icon={faTrash} className="header-icon" />
              </button>
            )
          }
        </>
      ),
      omit: omitColumn(columnConfig, 'Actions'),
    },
  ];

  return ordersColumns;
};
