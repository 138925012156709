import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { createUserStatusChangesHistory } from '../../redux/userStatusChangesHistory/userStatusChangesHistoryActions';

export function DatatableActions({ 
  ids, 
  managers, 
  statuses, 
  countries, 
  deleteUsers,  
  deleteComments,
  moveUsersToManagers,
  editUsers,
  clientType, 
  sendEmailsMassAction,
  updateUsersWithdrawalEnabled,
  updateAccountsIsTradingEnabled,
}) {
  const dispatch = useDispatch();

  const defaultDropdownName = 'Select';
  const [dropdownName, setDropdownName] = useState('Actions');
  const [statusDropdownName, setStatusDropdownName] = useState(defaultDropdownName);
  const [countryDropdownName, setCountryDropdownName] = useState(defaultDropdownName);
  const [managersDropdownName, setManagersDropdownName] = useState(defaultDropdownName);
  const [isAssignTo, setIsAssignTo] = useState(false);
  const [isChangeStatus, setIsChangeStatus] = useState(false);
  const [isPromote, setIsPromote] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isChangeCountry, setIsChangeCountry] = useState(false);
  const [isRemovedComments, setIsRemovedComments] = useState(false);
  const [isMassSendEmail, setIsMassSendEmail] = useState(false);
  const [isChangeSource, setIsChangeSource] = useState(false);
  const [isChangeWithdrawal, setIsChangeWithdrawal] = useState(false);
  const [isChangeTrading, setIsChangeTrading] = useState(false);
  const [statusId, setStatusId] = useState('');
  const [country, setCountry] = useState({});
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [selectedManagerNames, setSelectedManagerNames] = useState([]);
  const funnelNameRef = useRef('');
  const [withdrawalAction, setWithdrawalAction] = useState('');
  const [tradingAction, setTradingAction] = useState('');

  // filtered data
  const [filteredManagers, setFilteredManagers] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);

  const users = useSelector((state) => state.users.users);
  const crmUser = useSelector((state) => state.crmUser.crmUserInfo);
    
  const isLeadType = useMemo(() => clientType === '1', [clientType]);

  const reversedType = useMemo(() => (isLeadType ? '2' : '1'), [isLeadType]);

  useEffect(() => {
    setFilteredManagers(managers);
    setFilteredCountries(countries);
    setFilteredStatuses(statuses);
  }, [managers, countries, statuses]);

  useEffect(() => {
    if (selectedManagerNames.length) {
      const name = selectedManagerNames.length > 2 ? `${selectedManagerNames.length} items selected` : selectedManagerNames.toString();
      setManagersDropdownName(name);
    } else {
      setManagersDropdownName(defaultDropdownName);
    }
  }, [selectedManagerNames]);

  const hideAllCmp = () => {
    setIsChangeStatus(false);
    setIsRemoved(false);
    setIsPromote(false);
    setIsChangeCountry(false);
    setIsRemovedComments(false);
    setIsMassSendEmail(false);
    setIsChangeSource(false);
    setIsChangeWithdrawal(false);
    setIsChangeTrading(false);
    setIsAssignTo(false);
  };

  const showCmp = (name, event) => {
    hideAllCmp();
    event(true);
    setDropdownName(name);
  };

  const handleSendEmailMassAction = () => {
    sendEmailsMassAction();
    showCmp('Send Email', setIsMassSendEmail);
  };

  const selectStatus = (name, id) => {
    setStatusDropdownName(name);
    setStatusId(id);
  };

  const selectCountry = (country) => {
    setCountryDropdownName(country.nicename);
    setCountry(country);
  };

  const selectManager = (name, id) => {
    if (!selectedManagers.includes(id)) {
      setSelectedManagerNames((prev) => [...prev, name]);
      setSelectedManagers((prev) => [...prev, id]);
    } else {
      setSelectedManagerNames((prev) => prev.filter((item) => item !== name));
      setSelectedManagers((prev) => prev.filter((item) => item !== id));
    }
  };

  const handleChangeStatus = () => {
    if (!statusId.length) {
      toast.error('Select status to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      salesStatusId: statusId,
    };

    const usersData = users.filter((user) => ids.includes(user._id));
    const statusTitle = statuses.find((status) => status._id === statusId)?.name || '';

    editUsers(data, 'Are you sure you want to change status for this user(s)?')
      .then((res) => {
        if (res) {
          usersData.forEach((user) => {
            const oldStatus = user?.salesStatus?.name || '';

            dispatch(createUserStatusChangesHistory({
              user: user._id,
              agent: crmUser._id,
              agentName: `${crmUser.firstName} ${crmUser.lastName}`,
              previousStatus: oldStatus,
              actualStatus: statusTitle,
            }));
          });

          setStatusId('');
          setStatusDropdownName(defaultDropdownName);
        }
      });
  };

  const handleChangeCountry = () => {
    if (!Object.keys(country).length) {
      toast.error('Select country to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      countryCode: country.iso,
      country,
    };

    editUsers(data, 'Are you sure you want to change country for this user(s)?')
      .then((res) => {
        if (res) {
          setCountry([]);
          setCountryDropdownName(defaultDropdownName);
        }
      });
  };

  const moveUsersToCManagers = () => {
    if (!selectedManagers.length) {
      toast.error('Select manager(s) to complete this action', {
        autoClose: 1000,
      });

      return;
    };

    moveUsersToManagers(selectedManagers)
      .then((res) => {
        if (res) {
          setSelectedManagers([]);
          setSelectedManagerNames([]);
        }
      });
  };

  const onChangeFunnelName = async () => {
    if (!funnelNameRef.current.value.length) {
      toast.error('Enter new funnel name', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      source: funnelNameRef.current.value,
    };

    editUsers(data, 'Are you sure you want to change funnel name for this user(s)?')
      .then((res) => {
        if (res) {
          funnelNameRef.current.value = '';
        }
      });
  };

  const searchManager = (value) => {
    const filtered = managers.filter((manager) => manager.firstName.toLowerCase().includes(value.toLowerCase()) 
            || manager.lastName.toLowerCase().includes(value.toLowerCase())
            || String(`${manager.firstName.toLowerCase()} ${manager.lastName.toLowerCase()}`).includes(value.toLowerCase()));

    setFilteredManagers(filtered);
  };

  const searchCountry = (value) => {
    const filtered = countries.filter((country) => country.nicename.toLowerCase().includes(value.toLowerCase())
            || country.iso.toLowerCase().includes(value.toLowerCase())
            || country.iso3.toLowerCase().includes(value.toLowerCase()));

    setFilteredCountries(filtered);
  };

  const searchStatus = (value) => {
    const filtered = statuses.filter((status) => status.name.toLowerCase().includes(value.toLowerCase()));

    setFilteredStatuses(filtered);
  };

  const handleChangeWithdrawalEnabled = () => {
    if (!withdrawalAction) {
      toast.error('Select withdrawal action to complete this action', {
        autoClose: 1000,
      });
      return;
    }

    const isWithdrawalEnabled = withdrawalAction === 'Yes';

    updateUsersWithdrawalEnabled(ids, isWithdrawalEnabled, `Are you sure you want to change withdrawal status to ${withdrawalAction} for this user(s)?`)
      .then((res) => {
        if (res) {
          setWithdrawalAction('');
        }
      });
  };

  const handleChangeTradingEnabled = () => {
    if (!tradingAction) {
      toast.error('Select trading action to complete this action', {
        autoClose: 1000,
      });
      return;
    }

    const isTradingEnabled = tradingAction === 'Yes';

    updateAccountsIsTradingEnabled(ids, isTradingEnabled, `Are you sure you want to change trading status to ${tradingAction} for this user(s)?`)
      .then((res) => {
        if (res) {
          setTradingAction('');
        }
      });
  };

  return (
    <div className="dt-actions__container">
      <span className="dt-actions__selected-counter">{`Selected ${ids.length} records`}</span>
      <Dropdown className="dt-actions__dropdown ms-1">
        <Dropdown.Toggle variant="" className="btn-default">{dropdownName}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => showCmp('Assign to Manager', setIsAssignTo)}>Assign to Manager</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Change Status', setIsChangeStatus)}>Change Status</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Remove', setIsRemoved)}>Remove</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Remove Users Comments', setIsRemovedComments)}>Remove Users Comments</Dropdown.Item>
          <Dropdown.Item onClick={handleSendEmailMassAction}>Send Email</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp(`${isLeadType ? 'Promote' : 'Demote'}`, setIsPromote)}>{isLeadType ? 'Promote' : 'Demote'}</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Change Country', setIsChangeCountry)}>Change Country</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Change Funnel Name', setIsChangeSource)}>Change Funnel Name</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Change Withdrawal', setIsChangeWithdrawal)}>Change Withdrawal</Dropdown.Item>
          <Dropdown.Item onClick={() => showCmp('Change Trading', setIsChangeTrading)}>Change Trading</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {
        isAssignTo ? (
          <>
            <Dropdown className="dt-actions__dropdown ms-1">
              <Dropdown.Toggle variant="" className="btn-default">{managersDropdownName}</Dropdown.Toggle>
              <Dropdown.Menu>
                <ul>
                  <input className="leads-search-bar-filter" autoComplete="off" type="text" placeholder="Search" onChange={({ target }) => searchManager(target.value)} />
                  {filteredManagers.map((manager) => (
                    <li className="dropdown-item" key={manager._id} onClick={() => selectManager(`${manager.firstName} ${manager.lastName}`, manager._id)}>
                      {selectedManagers.includes(manager._id) ? <FontAwesomeIcon icon={faCheck} color="#6E7F95" /> : null}
                      <span className="ms-1">{`${manager.firstName} ${manager.lastName}`}</span>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <button className="btn-default ms-1 bg-green" type="button" onClick={moveUsersToCManagers}>Apply</button>
          </>
        ) : null
      }
      {
        isChangeStatus ? (
          <>
            <Dropdown className="dt-actions__dropdown ms-1">
              <Dropdown.Toggle variant="" className="btn-default">{statusDropdownName}</Dropdown.Toggle>
              <Dropdown.Menu>
                <input className="leads-search-bar-filter" autoComplete="off" type="text" placeholder="Search" onChange={({ target }) => searchStatus(target.value)} />
                {filteredStatuses.map((status) => (
                  <Dropdown.Item key={status._id} onClick={() => selectStatus(status.name, status._id)}>{status.name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <button className="btn-default ms-1 bg-green" type="button" onClick={handleChangeStatus}>Apply</button>
          </>
        ) : null
      }
      {
        isChangeCountry ? (
          <>
            <Dropdown className="dt-actions__dropdown ms-1">
              <Dropdown.Toggle variant="" className="btn-default">{countryDropdownName}</Dropdown.Toggle>
              <Dropdown.Menu>
                <input className="leads-search-bar-filter" autoComplete="off" type="text" placeholder="Search" onChange={({ target }) => searchCountry(target.value)} />
                {filteredCountries.map((country) => (
                  <Dropdown.Item key={country._id} onClick={() => selectCountry(country)}>{country.nicename}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <button className="btn-default ms-1 bg-green" type="button" onClick={handleChangeCountry}>Apply</button>
          </>
        ) : null
      }
      {
        isChangeSource ? (
          <div className="dt-actions__action-row">
            <input className="leads-search-bar-filter ms-1" autoComplete="off" type="text" ref={funnelNameRef} placeholder="Enter Funnel Name" />
            <button className="btn-default ms-1 bg-green" type="button" onClick={onChangeFunnelName}>Apply</button>
          </div>
        ) : null
      }
      {isRemoved ? <button className="btn-default ms-1 bg-red" type="button" onClick={deleteUsers}>Remove</button> : null}
      {isRemovedComments ? <button className="btn-default ms-1 bg-red" type="button" onClick={deleteComments}>Remove Users Comments</button> : null}
      {
      isChangeWithdrawal ? (
        <>
          <Dropdown className="dt-actions__dropdown ms-1">
            <Dropdown.Toggle variant="" className="btn-default">
              {withdrawalAction ? `Withdrawal Enabled: ${withdrawalAction}` : 'Withdrawal Enabled'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setWithdrawalAction('Yes')}>Yes</Dropdown.Item>
              <Dropdown.Item onClick={() => setWithdrawalAction('No')}>No</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button className="btn-default ms-1 bg-green" type="button" onClick={handleChangeWithdrawalEnabled}>Apply</button>
        </>
      ) : null
      }
      {
      isChangeTrading ? (
        <>
          <Dropdown className="dt-actions__dropdown ms-1">
            <Dropdown.Toggle variant="" className="btn-default">
              {tradingAction ? `Trading Enabled: ${tradingAction}` : 'Trading Enabled'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setTradingAction('Yes')}>Yes</Dropdown.Item>
              <Dropdown.Item onClick={() => setTradingAction('No')}>No</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button className="btn-default ms-1 bg-green" type="button" onClick={handleChangeTradingEnabled}>Apply</button>
        </>
      ) : null
      }
    </div>
  );
};
