import React from 'react';
import Select from 'react-select';
import selectDefaultStyle from '../styles/select-default-style';


export const TradeTypes = { SELL: { label: 'Sell', value: 0 }, BUY: { label: 'Buy', value: 1 } };

function TradeTypeSelect({ tradeType, onTradeTypeChange }) {
  return (
    <Select
      value={tradeType}
      onChange={onTradeTypeChange}
      options={Object.values(TradeTypes)}
      styles={selectDefaultStyle}
    />
  );
}

export default TradeTypeSelect;
