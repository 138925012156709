import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getStockSetting, editStockSetting, updateStockSettingState } from '../../redux/stockSetting/stockSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function EditStockSetting() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [stockSetting, setStockSetting] = useState('');

  const error = useSelector((state) => state.stockSetting?.stockSettingError);
  const stockSettingData = useSelector((state) => state.stockSetting?.stockSetting);
  const stockSettingFetched = useSelector((state) => state.stockSetting?.stockSettingFetched);
  const stockSettingEditted = useSelector((state) => state.stockSetting?.stockSettingEditted);

  const {
    register, handleSubmit, control, reset, formState: { errors }, 
  } = useForm();

  useEffect(() => {
    reset();
    setLoader(true);
    dispatch(getStockSetting(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(stockSettingData)?.length > 0 && stockSettingFetched) {
      setStockSetting(stockSettingData);
      reset(stockSettingData);
      dispatch(updateStockSettingState());
      setLoader(false);
    }
  }, [stockSettingData, stockSettingFetched]);

  useEffect(() => {
    if (stockSettingEditted) {
      dispatch(updateStockSettingState());
      setLoader(false);
      history.goBack();
    }
  }, [stockSettingEditted]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateStockSettingState());
    }
  }, [error]);

  const handleEditStockSetting = (formData) => {
    setLoader(true);
    const data = {
      alias: formData.alias ? formData.alias : '',
      symbol: formData.symbol ? formData.symbol : '',
      rateSymbol: formData.rateSymbol ? formData.rateSymbol : '',
      leverage: formData.leverage ? formData.leverage : 0,
      lotStep: formData.lotStep ? Number(formData.lotStep) : 0,
      lotSize: formData.lotSize ? formData.lotSize : 0,
    };

    dispatch(editStockSetting(id, data));
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>
            Edit Settings For
            {' '}
            {stockSetting ? `${stockSetting?.symbol}` : ''}
          </h3>
          <form onSubmit={handleSubmit(handleEditStockSetting)}>
            <div className="form-group col-md-12">
              <label className="control-label">Base Currency</label>
              <input type="text" className="form-control" defaultValue={stockSetting?.secondaryCurrency?.symbol} disabled />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('symbol')}
                name="symbol"
                control={control}
                defaultValue={stockSetting?.symbol}
              />
              {errors?.symbol && <span className="errMsg">{errors.symbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Alias</label>
              <input
                type="text"
                className="form-control"
                {...register('alias')}
                name="alias"
                control={control}
                defaultValue={stockSetting?.alias}
              />
              {errors?.alias && <span className="errMsg">{errors.alias.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Rate Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('rateSymbol')}
                name="rateSymbol"
                control={control}
                defaultValue={stockSetting?.rateSymbol}
              />
              {errors?.rateSymbol && <span className="errMsg">{errors.rateSymbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Leverage</label>
              <input
                type="number"
                className="form-control"
                {...register('leverage')}
                name="leverage"
                control={control}
                defaultValue={stockSetting?.leverage}
              />
              {errors?.leverage && <span className="errMsg">{errors.leverage.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Step</label>
              <input
                type="text"
                className="form-control"
                {...register('lotStep')}
                name="lotStep"
                control={control}
                defaultValue={stockSetting?.lotStep}
              />
              {errors?.lotStep && <span className="errMsg">{errors.lotStep.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Size</label>
              <input
                type="number"
                className="form-control"
                {...register('lotSize')}
                name="lotSize"
                control={control}
                defaultValue={stockSetting?.lotSize}
              />
              {errors?.lotSize && <span className="errMsg">{errors.lotSize.message}</span>}
            </div>
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default EditStockSetting;
