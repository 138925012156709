import {
  GET_SYSTEM_SETTINGS_TYPES,
  UPDATE_SYSTEM_SETTINGS_TYPES,
} from './systemSettingsTypes';

const initialState = {
  systemSettings: [],
  success: false,
  systemSettingsFetched: false,
};

const SystemSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEM_SETTINGS_TYPES:
      return {
        ...state,
        systemSettings: action.payload,
        systemSettingsFetched: true,
      };
    case UPDATE_SYSTEM_SETTINGS_TYPES:
      return {
        ...state,
        systemSettings: action.payload,
        systemSettingsFetched: true,
      };
    default:
      return state;
  }
};

export default SystemSettingsReducer;
