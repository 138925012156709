export const GET_SPOT_ORDER = 'GET_SPOT_ORDER';
export const IS_GET_SPOT_ORDER_LOADING = 'IS_GET_SPOT_ORDER_LOADING';
export const GET_USER_SPOT_ORDER = 'GET_USER_SPOT_ORDER';
export const ADD_SPOT_ORDER = 'ADD_SPOT_ORDER';
export const CLEAR_SPOT_ORDER = 'CLEAR_SPOT_ORDER';
export const SPOT_ORDER_ERROR = 'SPOT_ORDER_ERROR';
export const STOP_SPOT_ORDER = 'STOP_SPOT_ORDER';
export const COMPLETE_ORDER = 'COMPLETE_ORDER';
export const UPDATE_SPOT_SOCKET_ORDER = 'UPDATE_SPOT_SOCKET_ORDER';
export const GET_SPOT_PAIRS = 'GET_SPOT_PAIRS';
