import React from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';
import Slider from 'rc-slider';

export function LimitCloseModal(
  {
    showLimit,
    handleCloseLimit,
    selectedRow,
    orderRate,
    setOrderRate,
    avbl,
    coinAmount,
    setRate,
    rate,
    setCoinAmount,
    handleLimit,
    percentage,
    rangeValue,
    updateMarket,
  },
) {
  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  return (
    <Modal
      className="withdrawal-modal limit-modal"
      show={showLimit}
      centered
      onHide={handleCloseLimit}
      backdrop="static"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="buy-tabs">
          <div className="mb-4">
            <span>Limit Close</span>
          </div>
          <span>
            Entry Price
            {selectedRow?.toCurrency?.symbol}
          </span>
          <InputGroup className="mb-4">
            <FormControl
              placeholder="Rate"
              aria-label=""
              aria-describedby=""
              value={orderRate}
              onChange={(e) => {
                setOrderRate(e.target.value);
              }}
            />
            <InputGroup.Text
              className="point"
              onClick={() => {
                setRate(!rate);
              }}
            >
              +/-
            </InputGroup.Text>
          </InputGroup>
          <span>
            Order by Qty
            {selectedRow?.toCurrency?.symbol}
          </span>
          <InputGroup className="mb-3">
            <FormControl
              type="number"
              step="0.1"
              placeholder="Price"
              min="0.0"
              max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
              value={coinAmount}
              onChange={(e) => {
                const val = e.target.value;
                setCoinAmount(val);
              }}
              onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
            />
            <InputGroup.Text id="basic-addon2">{selectedRow?.toCurrency?.symbol}</InputGroup.Text>
          </InputGroup>

          <Slider
            min={0}
            step={0.1}
            marks={marks}
            defaultValue={[0, 25, 50, 75, 100]}
            value={percentage.replace('%', '')}
            onChange={() => rangeValue}
            className="mb-4 range-slider"
          />
          <p style={{ fontSize: '12px', padding: '0 12px' }} className="">
            4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be
            9.7290USDT (includng of EST, closing tees 3.4309USDT)
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => updateMarket()}>
          Confirm
        </Button>
        <Button variant="danger" onClick={handleCloseLimit}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
