import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthDataTypes } from '../../../redux/smsServices/smsServicesSelectors';
import { fetchSmsAuthDataTypes } from '../../../redux/smsServices/smsServicesActions';
import {
  convertObjectToArray, handleAddData, handleKeyChange, handleValueChange, 
} from '../../../helpers/smsHelpers';
import styles from './AuthDataComponent.module.css';

function AuthDataComponent({
  onAuthDataChange,
  defaultAuthData = [],
}) {
  const dispatch = useDispatch();

  const authDataTypes = useSelector(selectAuthDataTypes);
  const [authData, setAuthData] = useState(convertObjectToArray(defaultAuthData));

  useEffect(() => {
    dispatch(fetchSmsAuthDataTypes());
  }, []);

  useEffect(() => {
    const authDataObject = authData.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    onAuthDataChange(authDataObject);
  }, [authData]);

  return (
    <div className="form-group">
      <label className="lead">
        Auth Data
        <button
          className={styles.addBtn}
          type="button"
          onClick={() => handleAddData(authData, setAuthData, authDataTypes[0])}
        >
          Add+
        </button>
      </label>
      <div className={styles.wrapper}>
        {
          authData.map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.itemWrapper}>
              <select
                value={data.key}
                className={styles.selector}
                onChange={(e) => handleKeyChange(index, e.target.value, authData, setAuthData)}
              >
                {authDataTypes.map((type) => (
                  <option key={type}>{type}</option>
                ))}
              </select>
              <input
                type="text"
                className={styles.input}
                value={data.value}
                onChange={(e) => handleValueChange(index, e.target.value, authData, setAuthData)}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default AuthDataComponent;
