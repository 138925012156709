import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  GET_CRYPTO_PAIRS, GET_PAIR_TABLE_DATA, UPDATE_CRYPTO_PAIR, MASS_UPDATE_CRYPTO_PAIR, 
} from './cryptoPairTypes';

export const getCryptoPairs = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/pair/get-coins-string/', '');

    if (res && res.data && res.data.data) {
      dispatch({
        type: GET_CRYPTO_PAIRS,
        payload: res.data.data.coins,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getCryptoPairTableData = ({ limit = 10, page = 1, name = [] }) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}`;

    if (name && name.length) {
      url += `&name=${name}`;
    }
    
    const res = await apiHelper('get', `/api/pair/table${url}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_PAIR_TABLE_DATA,
        payload: res.data.result,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateCryptoPair = (id = '', data = {}) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/pair/${id}`, data);

    if (res && res.data && res.data.success) {
      toast.success(res.data.message);

      dispatch({
        type: UPDATE_CRYPTO_PAIR,
        payload: res.data.pair,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const massUpdateCryptoPair = (data = {}) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/pair/mass-pairs-update/', data);
    if (res && res.data && res.data.success) {
      toast.success(res.data.message);

      dispatch({
        type: MASS_UPDATE_CRYPTO_PAIR,
        payload: res.data.updatedPairs,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
