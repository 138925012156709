import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deleteCurrency, showAllCurrencies, updateState } from '../../redux/currency/currencyActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { GetCoinImg } from '../../helpers/getCoinImg';
import { ModalContext } from '../../context';
import { EditFiatCurrencyModal } from './components';

function FiatCurrency() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [loader, setLoader] = useState(false);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const success = useSelector((state) => state.currency?.currencies?.success);

  const isUserCanAddFiatCurrencies = permissionName && permissionName.includes('add_fiat_currency');
  const isUserCanEditFiatCurrencies = permissionName && permissionName.includes('edit_fiat_currency');
  const isUserCanDeleteFiatCurrencies = permissionName && permissionName.includes('delete_fiat_currency');
    <h3>Edit Fiat Currency</h3>;

    useEffect(() => {
      setLoader(true);

      async function fetchData() {
        await dispatch(showAllCurrencies());
        if (success) {
          setLoader(false);
          await dispatch(updateState());
        }
      }
      fetchData();
    }, [success]);

    const deleteAction = (id) => {
      Swal.fire({
        title: 'Are you sure you want to Delete?',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await dispatch(deleteCurrency(id));
        }
      });
    };

    return loader ? (
      <FullPageTransparentLoader />
    ) : (
      <>
        {/* <div className="col-lg-9 col-md-8"> */}
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>Fiat Currencies Details</h3>
            {/* {isUserCanAddFiatCurrencies
              ? <Link to='/add-fiat-currency'><button className="btn btn-default" type='button'>Add Fiat Currency</button></Link>
              : null} */}
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              <table className="table">
                <thead className="table_head">
                  <tr>
                    <th>Name</th>
                    <th>Symbol</th>
                    <th>Min Con</th>
                    <th>Max Con</th>
                    <th>Con Fee(%)</th>
                    <th>Color</th>
                    {/* <th>Currency Icon</th> */}
                    {isUserCanDeleteFiatCurrencies || isUserCanEditFiatCurrencies ? (
                      <th>Action(s)</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {currencies
                  && currencies?.length > 0
                  && currencies
                    ?.filter(
                      (row) => row?.isFiat === true && row?.status === true,
                    )
                    ?.map((currency) => (
                      <tr key={currency._id}>
                        <td style={{ textAlign: 'start' }}>
                          <img
                            src={GetCoinImg(currency?.symbol)}
                            alt=""
                            className="img-fluid coin-img pe-1"
                            width="44px"
                            height="44px"
                          />
                          {currency.name}
                        </td>
                        <td>{currency.symbol}</td>
                        <td>{currency.minAmount}</td>
                        <td>{currency.maxAmount}</td>
                        <td>{currency.conversionFee}</td>
                        <td>
                          <span
                            className="picked-value"
                            style={{
                              borderLeftColor: currency?.color
                                ? currency?.color
                                : '#aabbcc',
                            }}
                          >
                            {currency?.color ? currency?.color : '#aabbcc'}
                          </span>
                        </td>
                        {(isUserCanDeleteFiatCurrencies || isUserCanEditFiatCurrencies) && (
                          <td>
                            {isUserCanEditFiatCurrencies && (
                            <button 
                              type="button"
                              className="btn btn-primary me-2 text-decoration-none text-light"
                              onClick={() => showModal({ 
                                bodyContent: <EditFiatCurrencyModal currencyData={currency} />, 
                                headerContent: <h3>Edit Fiat Currency</h3>, 
                              })}
                            >
                              Edit
                            </button>
                            )}
                            {/* {isUserCanDeleteFiatCurrencies && (
                            <button
                              type="button"
                              className="btn btn-danger me-2"
                              onClick={() => deleteAction(currency._id)}
                            >
                              Delete
                            </button>
                            )} */}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
}

export default FiatCurrency;
