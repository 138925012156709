import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { deleteTransactionFee, displayTransactionFee } from '../../redux/transactionFee/transactionFeeActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function TransactionFee() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const transactionFeeDta = useSelector((state) => state.transactionFee.transactionFee?.transactionManagements);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const success = useSelector((state) => state.transactionFee.success);
  const fetched = useSelector((state) => state.transactionFee.fetched);

  useEffect(() => {
    setLoader(true);
    dispatch(displayTransactionFee());
    if (fetched) setLoader(false);
  }, [success, fetched]);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteTransactionFee(id));
      }
    });
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <>
          {/* <div className="col-lg-9 col-md-8"> */}
          <div className="content-wrapper right-content-wrapper">
            <div className="content-box">
              <h3>Conversion Fee Details</h3>
              {permissionName && permissionName.length > 0 && permissionName.includes('set_transaction_fee')
                ? <Link to="/set-conversion-fee"><button type="button" className="btn-default btn">Set Conversion Fee</button></Link>
                : null}
              <Table responsive>
                <thead>
                  <tr>
                    <th>Currency</th>
                    <th>Fee</th>
                    <th>Min Convertable Amount</th>
                    {permissionName && permissionName.length > 0 && permissionName.includes('edit_transaction_fee', 'delete_transaction_fee')
                      ? <th>Action(s)</th>
                      : null}
                  </tr>
                </thead>
                <tbody>
                  {transactionFeeDta && transactionFeeDta.length > 0 && transactionFeeDta.map((transactionFee) => (
                    <tr key={transactionFee._id}>
                      <td>{transactionFee.currencies.name}</td>
                      <td>{transactionFee.fee}</td>
                      <td>{transactionFee.min}</td>
                      <td>
                        {permissionName && permissionName.length > 0 && permissionName.includes('edit_transaction_fee')
                          ? <Link to={`/edit-conversion-fee/${transactionFee._id}`} className="btn btn-primary me-2 text-decoration-none text-light">Edit</Link>
                          : null}
                        {permissionName && permissionName.length > 0 && permissionName.includes('delete_transaction_fee')
                          ? <button type="button" className="btn btn-danger me-2" onClick={() => deleteAction(transactionFee._id)}>Delete</button>
                          : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
          {/* </div> */}
        </>
      )
  );
}

export default TransactionFee;
