export const GET_CRM_USERS = 'GET_CRM_USERS';
export const GET_UPLINE_USERS = 'GET_UPLINE_USERS';
export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_HIERARCHY_ORDER = 'GET_HIERARCHY_ORDER';
export const GET_CRM_USER = 'GET_CRM_USER';
export const ADD_CRM_USER = 'ADD_CRM_USER';
export const EDIT_CRM_USER = 'EDIT_CRM_USER';
export const DELETE_CRM_USER = 'DELETE_CRM_USER';
export const TOGGLE_CRM_USER_STATE = 'TOGGLE_CRM_USER_STATE';
export const ERROR_CRM_USER_STATE = 'ERROR_CRM_USER_STATE';
export const CURRENT_USER_PERMISSIONS = 'CURRENT_USER_PERMISSIONS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_REG_UPINE_USERS = 'GET_REG_UPINE_USERS';
export const CREATE_CRM_FILTER = 'CREATE_CRM_FILTER';
export const UPDATE_CRM_FILTER = 'UPDATE_CRM_FILTER';
export const DELETE_CRM_FILTER = 'DELETE_CRM_FILTER';
export const SEND_EMAIL = 'SEND_EMAIL';
export const GET_CRM_USER_INFO = 'GET_CRM_USER_INFO';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const EXPORT_FILTERED_DATA_TO_FILE = 'EXPORT_FILTERED_DATA_TO_FILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const GET_CRM_USERS_BY_QUERY = 'GET_CRM_USERS_BY_QUERY';
