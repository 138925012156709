import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showAllCurrencies } from '../../../redux/currency/currencyActions';
import { getRole } from '../../../redux/roles/roleActions';
import { nonNegativeAmount } from '../../../helpers/helper';

const currencyFormatter = require('currency-formatter');

function AssetInformation() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { amounts, previousTotalAmount } = useSelector((state) => state.users?.user?.account);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);

  useEffect(() => {
    const fetchData = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = data?.roleId;
      dispatch(getRole(roleId));

      await dispatch(showAllCurrencies());
    };
    fetchData();
  }, [id]);

  return (
    <div>
      <h5> Account Balance </h5>
      <div className="row">
        <div className="form-group col-md-6">
          <label className="control-label">
            Total Balance
          </label>
          <input
            type="text"
            className="form-control"
            value={currencyFormatter.format(nonNegativeAmount(previousTotalAmount), { code: 'USD' })}
            disabled
          />
        </div>
        {amounts && amounts.length
          ? amounts.map((coin) => (
            currencies?.find((row) => row._id === coin.currencyId)?.name
              ? (
                <div key={coin._id} className="form-group col-md-6">
                  <label className="control-label">
                    {currencies?.find((row) => row._id === coin.currencyId)?.name}
                    (
                    {currencies?.find((row) => row._id === coin.currencyId)?.symbol}
                    )
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={nonNegativeAmount(Number(coin.amount) + Number(coin.futures_amount) + Number(coin.cfd_amount) + Number(coin.frozen_amount)).toFixed(2)}
                    disabled
                  />
                </div>
              )
              : null
          ))
          : 'Empty Wallet'}
      </div>
      <br />
    </div>
  );
}

export default AssetInformation;
