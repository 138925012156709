import React, { useState } from 'react';
import OrdersHistory from './OrdersHistory';
import SpotOrders from './SpotOrders';


function OrdersHistoryNavigation() {
  const [activeTab, setActiveTab] = useState('futures');

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4>Trade History</h4>
        <br />
        <div className="user-details-tabs" style={{ color: '#ffffff' }}>
          <div
            className={activeTab === 'futures' ? 'tab-active' : 'tab'}
            onClick={() => setActiveTab('futures')}
          >
            Features
          </div>
          <div
            className={activeTab === 'spot' ? 'tab-active' : 'tab'}
            onClick={() => setActiveTab('spot')}
          >
            Spot Orders
          </div>
        </div>
        <br />
        {(
          (activeTab === 'futures' && <OrdersHistory />)
          || (activeTab === 'spot' && <SpotOrders />)
        )}
      </div>
    </div>
  );
}

export default OrdersHistoryNavigation;
