import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ModalContext } from '../../../../context';
import { getLeverageSettingsByQuery, deleteLeverageSetting } from '../../../../redux/leverageSetting/leverageSettingActions';
import LeverageSettingModal from './LeverageSettingModal';


export default function LeverageDataTable({ id = '' }) {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);
  const settings = useSelector((state) => state.leverageSettings);

  useEffect(() => {
    dispatch(getLeverageSettingsByQuery(id));
  }, [id]);

  const deleteSetting = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteLeverageSetting(id));
        }
      });
  };

  const columns = [
    {
      name: 'Pair Name',
      cell: ({ pairName }) => pairName,
    },
    {
      name: 'Leverage',
      cell: ({ leverage }) => leverage,
    },
    {
      name: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ pairName, leverage, _id }) => (
        <>
          <button 
            type="button" 
            className="btn btn-warning btn-sm me-1 p-1" 
            onClick={() => showModal({
              headerContent: <h3>EDIT LEVERAGE</h3>,
              bodyContent: <LeverageSettingModal userId={id} setting={{ pairName, leverage, _id }} />,
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button 
            type="button" 
            className="btn btn-danger btn-sm" 
            onClick={() => deleteSetting(_id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>

      ),
    },
  ];

  return (
    <div>
      <button
        type="button"
        className="btn btn-default icon-btn btn-bg-green"
        onClick={() => {
          showModal({
            headerContent: <h3>ADD LEVERAGE</h3>,
            bodyContent: <LeverageSettingModal userId={id} isCreate />,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
        Add
      </button>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={settings}
          theme="solarizedd"
        />
      </div>
    </div>
  );
}
