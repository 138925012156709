import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { DownloadFile } from '../../components/DownloadFile';
import { DatatableColumns, DatatableFilters } from '../../components';
import { SpotOrdersSchema } from '../../DataTableSchemas/SpotOrdersSchema/SpotOrdersSchema';
import { exportSpotDataToFile, getSpotOrders, getUniqueSpotPairs } from '../../redux/spotOrder/spotOrderActions';
import { selectSpotOrders, selectSpotOrdersPagination, selectSpotOrdersPairs } from '../../redux/spotOrder/spotOrderSelectors';
import { spotOrdersColumns } from '../../columnsDefaultConfig';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { useDebounce } from '../../hooks/useDebounce';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';

function SpotOrders() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const spotOrdersData = useSelector(selectSpotOrders);
  const paginationData = useSelector(selectSpotOrdersPagination);
  const totalCount = paginationData?.totalOrders ?? null;
  const isLoading = paginationData?.isLoading ?? false;

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const columnsStorageName = 'DataTable_spotOrders/spot-orders_columns';
  const filtersStorageName = 'DataTable_spotOrders/spot-orders_filters';
  const filterIdStorageName = 'DataTable_spotOrders/spot-orders_filter_id'; 
  const paginationStorageName = 'DataTable_spotOrders/spot-orders_pagination';
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);

  // filters state
  const [filters, setFilters] = useState({});
  const [typeFilter, setTypeFilter] = useState([]);
  const [directionFilter, setDirectionFilter] = useState([]);
  const [spotPairsFilter, setSpotPairsFilter] = useState([]);
  const [amountRangeFilter, setAmountRangeFilter] = useState(null);

  const datatableFiltersRef = useRef(null);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(amountRangeFilter, 1000, (value) => debounceCallback({ value, key: 'amountRange' }));

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRangeFilter([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRangeFilter([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRangeFilter(value);
      }
    }
  };

  // column state
  const [columnConfig, setColumnConfig] = useState(spotOrdersColumns);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (spotOrdersData.length && columns.length) {
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportSpotDataToFile(spotOrdersData, columns, fileType)); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  const handlePageChange = (page) => {
    localStorage.setItem(
      paginationStorageName, 
      JSON.stringify({ limit: rowsPerPage, page }),
    );
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(
      paginationStorageName,
      JSON.stringify({ limit: currentRowsPerPage, page }),
    );
  };

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const pairNames = useSelector(selectSpotOrdersPairs);

  const columns = SpotOrdersSchema(
    filters,
    setFilters,
    pairNames,
    spotPairsFilter,
    setSpotPairsFilter,
    typeFilter,
    setTypeFilter,
    directionFilter,
    setDirectionFilter,
    amountRangeFilter,
    setAmountRangeFilter,
    setDebounceAmountRange,
    permissionName,
    columnConfig,
  );

  const setStoredPagination = () => {
    const openOrdersRowsJSON = localStorage.getItem(paginationStorageName);
    if (openOrdersRowsJSON) {
      const filterRows = JSON.parse(openOrdersRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters || {});

      setAmountRangeFilter(filters.amountRange || null);
      setSpotPairsFilter(filters['spot pair'] || []);
      setTypeFilter(filters.type || []);
      setDirectionFilter(filters.direction || []);
    } 
  };

  const handleClear = () => {
    setAmountRangeFilter(null);
    setSpotPairsFilter([]);
    setTypeFilter([]);
    setDirectionFilter([]);

    setFilters({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const filters = {
      amountRange: filter.amountRange,
      'spot pair': filter.status,
      type: filter.type,
      direction: filter.currency,
    };

    setFilters(filters);

    setAmountRangeFilter(filter.amountRange || null);
    setSpotPairsFilter(filter.status || []);
    setTypeFilter(filter.type || []);
    setDirectionFilter(filter.currency || []);

    localStorage.setItem(filtersStorageName, JSON.stringify(filters));
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}_spot`,
      currency: filters.direction || [],
      status: filters['spot pair'] || [],
      type: filters.type || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 100000000],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.direction || [],
        status: filters['spot pair'] || [],
        type: filters.type || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 100000000],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  // fetch data
  useEffect(async () => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(filters));
      dispatch(getSpotOrders(page, rowsPerPage, filters));
    }
  }, [page, rowsPerPage, isPaginationDT, filters]);

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    dispatch(getUniqueSpotPairs());
  }, []);

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };
  const refresh = () => getAllStoredData();

  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  return (
    isLoading ? (
      <FullPageTransparentLoader />
    ) : (
      <div>
        <div className="action__btn-row">
          {
          crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={`${pathname}_spot`}
            />
          )
        }
          <DownloadFile handleExport={handleExportOrders} />
          <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {
          isPaginationDT
            && (
              <DataTable
                columns={columns}
                data={spotOrdersData}
                fixedHeader
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                paginationDefaultPage={page}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                persistTableHead
                highlightOnHover
                theme="solarizedd"
                progressPending={isLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
            ) 
        }
        </div>
      </div>
    )
  );
}

export default SpotOrders;
