import { toast } from 'react-toastify';
import {
  SET_IS_DATA_LOADING,
  SET_STATUS_HISTORY,
  ADD_STATUS_HISTORY, 
} from './userStatusChangesHistoryTypes';
import { apiHelper } from '../apiHelper';

// action creators below

const setStatusHistoryCreator = (status) => ({
  type: SET_STATUS_HISTORY,
  payload: status,
});

const addStatusHistoryCreator = (status) => ({
  type: ADD_STATUS_HISTORY,
  payload: status,
});

const setIsOrderHistoryLoadingCreator = (status) => ({
  type: SET_IS_DATA_LOADING,
  payload: status,
});


// Thunks below

export const createUserStatusChangesHistory = (data) => async (dispatch) => {
  try {
    const response = await apiHelper('post', '/api/user-status-changes-history/', data);

    if (response?.data) {
      const { result } = response.data;

      if (result) {
        dispatch(addStatusHistoryCreator(result));
      }
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const getUserStatusChangesHistory = (userId) => async (dispatch) => {
  dispatch(setIsOrderHistoryLoadingCreator(true));

  try {
    const response = await apiHelper('get', `/api/user-status-changes-history/${userId}`);

    if (response?.data && response?.data?.result) {
      const result = response?.data?.result ?? [];

      dispatch(setStatusHistoryCreator(result));
    } else {
      dispatch(setStatusHistoryCreator([]));
    }

    dispatch(setIsOrderHistoryLoadingCreator(false));
  } catch (error) {
    toast.error(error.response.message);
    dispatch(setIsOrderHistoryLoadingCreator(false));
  }
};

