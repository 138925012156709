import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faBell, faGear, faCheck, faColumns, faVolumeMute, faVolumeHigh,
} from '@fortawesome/free-solid-svg-icons';
import { 
  getNotificationsByModules, 
  readNotification,
  readAllNotification, 
  addNotification, 
  getNotificationModule,
  updateNotificationModuleState,
} from '../redux/notifications/notificationActions';
import { setHeaderMounted, setSideBarMounted } from '../redux/staticComponent/staticComponentActions';
import { getGlobalUsers } from '../redux/users/userActions';
import { getCRMUserInfo } from '../redux/crmUser/crmUserActions';
import { logout } from '../services/navigatorService';
import socket from '../services/socket';
import NotificationSound from '../assets/audio/notification.wav';
import logoImage from '../assets/images/ChainPilot.png';
import { fetchSmsServiceByAgentId, setCrmUserSelectedSmsService } from '../redux/smsServices/smsServicesActions';
import { selectCrmUserSelectedSmsService, selectCrmUserSmsServices, selectSmsServices } from '../redux/smsServices/smsServicesSelectors';
import { useVersionNotification } from '../hooks';
import { getSystemSettings } from '../redux/systemSettings/systemSettingsActions';

const NOTIFICATION_FILTERS_STORAGE = 'notifications_filters';
const NOTIFICATION_SOUND_STORAGE = 'notifications_sound';
const audio = new Audio(NotificationSound);
audio.volume = 1;

function Header() {
  useVersionNotification();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const userData = useSelector((state) => state?.crmUser?.crmUserInfo);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [notification, setNotification] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sound, setSound] = useState(() => JSON.parse(localStorage.getItem(NOTIFICATION_SOUND_STORAGE)) || 'on');
  const [notificationFilters, setNotificationFilters] = useState([]);

  const searchedUsers = useSelector((state) => state?.users?.globalUsers);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const unreadNotifications = useSelector((state) => state.notification.unreadNotifications);
  const notificationModules = useSelector((state) => state.notification.notificationModules);
  const isHeaderMounted = useSelector((state) => state.staticComponents.isHeaderMounted);
  const crmUserSmsServices = useSelector(selectCrmUserSmsServices);
  const crmUserSelectedSmsService = useSelector(selectCrmUserSelectedSmsService);
  const smsServices = useSelector(selectSmsServices);

  const isUserCanSeeLeads = permissionName && permissionName.includes('leads');
  const notificationFiltersRef = useRef(notificationFilters);
  const soundRef = useRef(sound);

  const token = localStorage.getItem('token');
  useEffect(() => {
    localStorage.getItem('user');
  }, [token]);

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem(NOTIFICATION_FILTERS_STORAGE));
    const newFilters = storedFilters || (notificationModules ? notificationModules.map((item) => item._id) : []);
    setNotificationFilters(newFilters);
    dispatch(updateNotificationModuleState());
  }, [notificationModules]);

  useEffect(() => {
    if (notificationFilters.length && userData._id) {
      const data = {
        userId: userData._id,
        modules: notificationFilters,
      };
      dispatch(getNotificationsByModules(data));
    }
  }, [notificationFilters, userData]);

  // getting new data if userData is loaded
  // or smsServices array have been changed
  useEffect(() => {
    if (userData?._id) {
      dispatch(fetchSmsServiceByAgentId(userData._id));
    }
  }, [userData?._id, smsServices]);

  // SMS  services effect
  useEffect(() => {
    // if all services removed
    if (!crmUserSmsServices.length) {
      dispatch(setCrmUserSelectedSmsService(null));
    }
    // if no one service selected and appeared services in selector
    if (crmUserSelectedSmsService === null && crmUserSmsServices.length) {
      dispatch(setCrmUserSelectedSmsService(crmUserSmsServices[0]));
    }
    // if selected service is not available anymore
    if (crmUserSelectedSmsService !== null && crmUserSmsServices.length) {
      const selectedServiceId = crmUserSelectedSmsService._id;
      const isServiceAvailable = crmUserSmsServices.some(
        (service) => service._id === selectedServiceId,
      );

      if (!isServiceAvailable) {
        dispatch(setCrmUserSelectedSmsService(crmUserSmsServices[0]));
      }
    }
  }, [crmUserSmsServices]);

  const handleModuleToggle = (moduleId) => {
    const updatedFilters = notificationFilters.includes(moduleId)
      ? notificationFilters.filter((id) => id !== moduleId)
      : [...notificationFilters, moduleId];

    setNotificationFilters(updatedFilters);
    localStorage.setItem(NOTIFICATION_FILTERS_STORAGE, JSON.stringify(updatedFilters));
  };

  const handleSmsSelectorChange = ({ target }) => {
    const smsServiceId = target.value;
    const smsService = crmUserSmsServices.find((service) => service._id === smsServiceId);
    dispatch(setCrmUserSelectedSmsService(smsService));
  };

  const toggleSound = () => {
    const newSoundStatus = sound === 'on' ? 'off' : 'on';
    setSound(newSoundStatus);
    localStorage.setItem(NOTIFICATION_SOUND_STORAGE, JSON.stringify(newSoundStatus));
  };

  useEffect(() => {
    notificationFiltersRef.current = notificationFilters;
    soundRef.current = sound;
  }, [notificationFilters, sound]);

  const handleNotificationResponse = (data) => {
    const currentFilters = notificationFiltersRef.current;
    const currentSound = soundRef.current;
    
    if (currentFilters.includes(data.module)) {
      if (currentSound === 'on') {
        audio.play();
      }
      
      dispatch(addNotification(data));
    }
  };

  const logoutFromApp = () => {
    dispatch(setSideBarMounted(false));
    dispatch(setHeaderMounted(false));
    socket.destroy();
    logout();
  };

  const checkIfUserLogged = (userData) => {
    if (!userData.isLogin) logoutFromApp();
  };

  const handleSystemSettingsCreateOrUpdate = () => {
    dispatch(getSystemSettings());
  };

  useEffect(() => {
    if (userData?._id) {
      checkIfUserLogged(userData);

      socket.emit('startStreaming', { userId: userData._id, isCRMUser: true });
      socket.on(`onNotificationResponse${userData._id}`, handleNotificationResponse);
    }

    socket.on(`onLogoutUser&${userData._id}`, () => logoutFromApp());

    socket.on('receiveSystemSettingsUpdate', handleSystemSettingsCreateOrUpdate);
    socket.on('receiveSystemSettingsCreate', handleSystemSettingsCreateOrUpdate);
    
    return () => {
      if (userData?._id) {
        socket.off(`onNotificationResponse${userData._id}`);
      } 
      socket?.off(`onLogoutUser&${userData._id}`);
    };
  }, [userData]);

  useEffect(() => {
    if (unreadNotifications) {
      setNotification(unreadNotifications);
    }
  }, [unreadNotifications]);

  const redirectToPage = (url) => {
    history.push(url);
  };

  const markReadNotifcation = (id) => {
    dispatch(readNotification(id, userData._id));
  };

  const MakeAllNotificationsRead = async () => {
    const ids = notification.map((element) => element._id);
    dispatch(readAllNotification(ids, userData._id));
  };

  /* Global Search */
  const handleSearchValueChange = (e) => {
    const searchText = e.target.value;
    const uId = JSON.parse(localStorage.getItem('userId'));

    setSearchValue(searchText);
    dispatch(getGlobalUsers(uId, searchText));
  };

  useEffect(() => {
    if (!isHeaderMounted) {
      const loginUser = localStorage.getItem('userId');
      const uId = JSON.parse(loginUser);
      dispatch(setHeaderMounted(true));
      dispatch(getCRMUserInfo(uId));
      dispatch(getNotificationModule());
    }
  }, [isHeaderMounted]);

  

  return (
    <header id="header">
      <div className="container-fluid main-menu">
        <div className="row">
          <nav className="navbar navbar-expand-lg w-100 fixed-top main-padding">
            <Link to={isUserCanSeeLeads ? '/leads' : '/'} className="logo ">
              <img className="img-fluid" src={logoImage} alt="Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon fa fa-bars" />
            </button>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <div
                className="d-flex user-details-search"
                style={{
                  display: 'flex', alignItems: 'center', gap: '10px', position: 'relative',
                }}
              >
                <form>
                  <input
                    id="search"
                    type="search"
                    className="form-control"
                    placeholder="Global search..."
                    autoComplete="off"
                    value={searchValue}
                    style={{ right: 0 }}
                    onChange={(event) => handleSearchValueChange(event)}
                  />
                </form>

                {searchValue !== '' ? (
                  <div className="modal-search">
                    <h6>
                      Search Results:
                      {searchedUsers.length}
                    </h6>
                    <div className="modal-search-body">
                      {searchedUsers?.length && searchedUsers.map((searchedUser) => {
                        const userFullName = `${searchedUser?.firstName} ${searchedUser?.lastName}`;
                        const userEmail = searchedUser?.email;
                        const isClient = searchedUser?.roleId?.name === 'Client';
                        const userRoute = isClient ? `/user-detail/${searchedUser?._id}` : `/edit-admin/${searchedUser?._id}`;

                        return (
                          <Link
                            className="modal-search-link"
                            to={userRoute}
                            target="_blank"
                            key={`user-${searchedUser?._id}`}
                            onClick={() => setSearchValue('')}
                          >
                            {`${userFullName} (${userEmail})`}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>

            {
              crmUserSmsServices.length ? (
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <div className="header-smtp-boxes-wrapper" id="navbarNavDropdown">
                    <span className="header-smtp-boxes-title">SMS:</span>
                    <select
                      defaultValue={crmUserSelectedSmsService?._id}
                      onChange={handleSmsSelectorChange}
                      className="form-control header-sms-services-selector"
                    >
                      {crmUserSmsServices.map((service) => (
                        <option key={service._id} value={service._id}>
                          {service.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null
            }

            <div className="custom-items">
              {token ? (
                <>
                  {userData.firstName ? <span style={{ color: '#fff', marginRight: '30px' }}>{`${userData.firstName} ${userData.lastName}`}</span> : null}
                  <Link className="user-dropdown noti-btn-des" to="/additional-security">
                    <FontAwesomeIcon icon={faGear} />
                  </Link>

                  <Button className="noti-button noti-btn-des" onClick={handleShow}>
                    <FontAwesomeIcon icon={faBell} />
                    <div className="notifiction-badge">
                      {notification?.length ? notification?.length : '0'}
                    </div>
                  </Button>

                  <Modal className="noti-modal" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Notifications</Modal.Title>
                      <div className="action__btn-row">
                        <Dropdown className="leads-columns-dropdown ms-3">
                          <Dropdown.Toggle 
                            variant="" 
                            className="btn-default"
                            style={{ 
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faColumns}
                              size="xs"
                              style={{ padding: '5px' }}
                            />
                            Type
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <ul className="leads-columns-list">
                              {notificationModules?.map((currmodule) => (
                                <li className="leads-columns-option" key={currmodule._id} onClick={() => handleModuleToggle(currmodule._id)}>
                                  {notificationFilters.includes(currmodule._id) && (
                                    <FontAwesomeIcon
                                      size="xs"
                                      icon={faCheck}
                                      color="#6E7F95"
                                    />
                                  )}
                                  <span className="leads-columns-option__name">{currmodule.name}</span>
                                </li>
                              ))}
                            </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                        <Button
                          className="ms-2 btn-default"
                          onClick={toggleSound}
                          title={`Sound is ${sound.toUpperCase()}, click to turn ${sound === 'on' ? 'off' : 'on'}`}
                        >
                          <FontAwesomeIcon icon={sound === 'on' ? faVolumeHigh : faVolumeMute} />
                        </Button>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      {notification.length ? (
                        notification.map((i) => (
                          <span
                            onClick={() => {
                              markReadNotifcation(i._id);
                              redirectToPage(`${i.redirectUrl}`);
                            }}
                            className="content active"
                            key={i._id}
                          >
                            <span className="unread">Unread</span>
                            <h6 className="status">
                              {i.message}
                            </h6>
                            <p>
                              <span className="date">{moment(i.createdAt).format('LLL')}</span>
                              {/* <span className='time'>10:00 PM</span> */}
                            </p>
                          </span>
                        ))
                      ) : (
                        <div className="no-noti-found">
                          <span className="text-white">No notification found!</span>
                        </div>
                      )}
                    </Modal.Body>
                    {notification.length ? (
                      <Modal.Footer style={{ border: '0' }}>
                        <button
                          type="button"
                          className="btn-default"
                          style={{ backgroundColor: '#6E7F95' }}
                          onClick={MakeAllNotificationsRead}
                        >
                          Read All
                        </button>
                      </Modal.Footer>
                    ) : (
                      ''
                    )}
                  </Modal>

                  <Dropdown className="user-dropdown">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="noti-button m-0"
                    >
                      <FontAwesomeIcon icon={faUser} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* <Link className="dropdown-item conntect-wallet-btn">Connect Wallet</Link> */}
                      <Link className="dropdown-item" to="/user-profile">
                        Profile
                      </Link>
                      <Link className="dropdown-item" to="/change-password">
                        Change Password
                      </Link>
                      <Link className="dropdown-item" to="/settings">
                        Settings
                      </Link>
                      <a type="button" className="dropdown-item" onClick={logoutFromApp}>
                        Log Out
                      </a>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              ) : (
                <>
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
