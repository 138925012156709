import { toast } from 'react-toastify';
import {
  GET_SYSTEM_SETTINGS_TYPES, 
  UPDATE_SYSTEM_SETTINGS_TYPES,
} from './systemSettingsTypes';

import { apiHelper } from '../apiHelper';

export const getSystemSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/system-settings');
    if (res && res.data && res.data.success) {
      const { data } = res;

      await dispatch({
        type: GET_SYSTEM_SETTINGS_TYPES,
        payload: data.systemSettings,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  } 
};

export const updateSystemSettings = (systemSettings) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/system-settings', systemSettings);
    if (res && res.data && res.data.success) {
      toast.success(res.data.message);

      dispatch({
        type: UPDATE_SYSTEM_SETTINGS_TYPES,
        payload: res.data.systemSettings,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
