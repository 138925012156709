import React from 'react';
import { StakingInvestmentsDatatable } from '../../DataTableSchemas/StakingInvestmentsDatatable';

function StakingHistory() {
  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4 className="mb-3 text-capilatize">Staking History</h4>
        <StakingInvestmentsDatatable />
      </div>
    </div>
  );
};

export default StakingHistory;
