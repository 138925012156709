export const userFutureOrdersColumns = [
  {
    name: 'Contracts',
    selected: true,
  },
  {
    name: 'QTY',
    selected: true,
  },
  {
    name: 'Value',
    selected: true,
  },
  {
    name: 'Entry Price',
    selected: true,
  },
  {
    name: 'Liq Price',
    selected: true,
  },
  {
    name: 'Position Margin',
    selected: true,
  },
  {
    name: 'Commission',
    selected: true,
  },
  {
    name: 'Swap',
    selected: true,
  },
  {
    name: 'Unrealized P&L',
    selected: true,
  },
  {
    name: 'Time Opened',
    selected: true,
  },
  {
    name: 'Actions',
    selected: true,
  },
];
