/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCRMUser, editCRMUser, updateCRMUserState } from '../../../redux/crmUser/crmUserActions';

export function Voip() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [user, setUser] = useState('');
  const [, setLoader] = useState(false);

  const userData = useSelector((state) => state.crmUser?.crmUser);
  const crmUserFetched = useSelector((state) => state.crmUser?.crmUserFetched);

  const editVoip = {
    voipToken: {
      required: false,
      patern: {
        value: /^[0-9]*$/,
      },
    },
  };

  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getCRMUser(id));
  }, [id]);

  const handleSave = (formData) => {
    setLoader(true);
    const data = {
      voipToken: formData.voipToken,
    };
    dispatch(editCRMUser(id, data));
  };

  useEffect(() => {
    reset();
    dispatch(getCRMUser(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(userData)?.length > 0 && crmUserFetched) {
      setUser(userData);
      dispatch(updateCRMUserState());
    }
    reset(userData);
  }, [userData, crmUserFetched]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="tab-pane fade show active tab-main-box" id="home" role="tabpanel" aria-labelledby="home-tab">
            <h3 className="text-capitalize">VOIP</h3>
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="form-group col-md-12">
                <label className="control-label">PROVIDER</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="VOISO"
                  disabled
                //   {...register('firstName', editSubAdmin.firstName)}
                //   name="firstName"
                //   control={control}
                //   defaultValue={user?.firstName}
                />
                {/* {errors?.firstName && <span className="errMsg">{errors.firstName.message}</span>} */}
              </div>
              <div className="form-group col-md-12 pt-2">
                <label className="control-label">Agent VOIP ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter ID"
                  {...register('voipToken', editVoip.voipToken)}
                  name="voipToken"
                  control={control}
                  defaultValue={user?.voipToken}
                />
                {errors?.voipToken && <span className="errMsg">{errors.voipToken.message}</span>}
              </div>
              <div>
                <button className="btn btn-default" type="submit">Update VOIP Details</button>
              </div>
            </form>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
