import { toast } from 'react-toastify';
import {
  GET_INDICES_SETTINGS,
  GET_INDICES_SETTING,
  ADD_INDICES_SETTING,
  EDIT_INDICES_SETTING,
  DELETE_INDICES_SETTING,
  TOGGLE_INDICES_SETTING_STATE,
  ERROR_INDICES_SETTING_STATE,
} from './indicesSettingTypes';
import { apiHelper } from '../apiHelper';
  
export const getIndicesSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/indices-setting', '');
    const payload = res?.data?.indicesSettings || [];
    dispatch({
      type: GET_INDICES_SETTINGS,
      payload,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const getIndicesSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/indices-setting/${id}`, '');
    if (res?.data && res?.data?.indicesSetting) {
      const { data } = res;
      dispatch({
        type: GET_INDICES_SETTING,
        payload: data?.indicesSetting,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const addIndicesSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/indices-setting/add', data);
  
    if (res?.data && res.data?.indicesSetting_) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: ADD_INDICES_SETTING,
        payload: data?.indicesSetting_,
      });
    } else {
      dispatch({
        type: ERROR_INDICES_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_INDICES_SETTING_STATE,
    });
    toast.error(error.message);
  }
};
  
export const editIndicesSetting = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/indices-setting/${id}`, bodyData);
    if (res?.data && res?.data?.indicesSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: EDIT_INDICES_SETTING,
        payload: data?.indicesSetting,
      });
    } else {
      dispatch({
        type: ERROR_INDICES_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_INDICES_SETTING_STATE,
    });
    toast.error(error.response.message);
  }
};
  
export const deleteIndicesSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/indices-setting/${id}`, '');
    if (res?.data && res?.data?.indicesSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: DELETE_INDICES_SETTING,
        payload: data?.indicesSetting?._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const updateIndicesSettingState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_INDICES_SETTING_STATE,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
