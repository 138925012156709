export const userPendingOrdersColumns = [
  {
    name: 'Contracts',
    selected: true,
  },
  {
    name: 'QTY',
    selected: true,
  },
  {
    name: 'Value',
    selected: true,
  },
  {
    name: 'Entry Price',
    selected: true,
  },
  {
    name: 'LIQ Price',
    selected: true,
  },
  {
    name: 'Position Margin',
    selected: true,
  },
  // {
  //   name: 'Unrealized P&L',
  //   selected: true,
  // },
  {
    name: 'TP/SL',
    selected: true,
  },
  {
    name: 'Trailing Stop',
    selected: true,
  },
  {
    name: 'Close By',
    selected: true,
  },
];
