/* eslint-disable react/no-danger */
import React, {
  useEffect, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { getEmailTemplate } from '../../../redux/emailTemplates/emailTemplatesActions';
import { selectEmailTemplate, selectIsEmailTemplateLoading } from '../../../redux/emailTemplates/emailTemplatesSelectors';
import logoImage from '../../../assets/images/emailTemplates/logoPlaceholder.png';
import s from './EmailTemplateShow.module.css';

function EmailTemplateShow({ id }) {
  const dispatch = useDispatch();

  const emailTemplate = useSelector(selectEmailTemplate);
  const isEmailTemplateLoading = useSelector(selectIsEmailTemplateLoading);

  useEffect(() => {
    dispatch(getEmailTemplate(id));
  }, [dispatch, id]);

  const sanitizedHTML = DOMPurify.sanitize(emailTemplate?.mailBodyHtml);
  const HTMLWithEncodedBtn = sanitizedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const HTMLWithProperClass = HTMLWithEncodedBtn.replace(/class="button"/g, `class="${s.button}"`);

  if (isEmailTemplateLoading) {
    return <>Loading</>;
  }

  return (
    <div className={s.wrapper}>
      <div className="col-xl-12 col-lg-12">
        <center className={s.emailWrapper}>
          <table className={s.main} width="100%">
            <tr>
              <td>
                <a
                  href="%siteURL%"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logoImage}
                    alt="logo"
                    className={s.bigLogo}
                  />
                </a>
              </td>
            </tr>

            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: HTMLWithProperClass }} />
              </td>
            </tr>
          </table>

          <table className={s.footer}>
            <tr>
              <td>
                <p style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  marginBottom: 0,
                }}
                >
                  Best regards,
                </p>
                <p style={{ fontWeight: 700 }}>
                  %companyName% Team
                </p>
                <img
                  className={s.smallLogo}
                  src={logoImage}
                  alt="logo"
                />
                <p style={{ fontSize: '13px', lineHeight: '13px' }}>
                  © 2017 - 
                  {' '}
                  {(new Date()).getFullYear()}
                  <a
                    href="%siteUrl%"
                    target="_blank"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {' '}
                    %siteUrl%
                  </a>
                  ,
                  {' '}
                  All Rights Reserved.
                </p>
              </td>
            </tr>
          </table>
        </center>
      </div>
    </div>
  );
}

export default EmailTemplateShow;
