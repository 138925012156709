/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect, useContext, 
} from 'react';
import {
  Dropdown, Button, Row, Col, 
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalContext } from '../../../../context';
import { createUserStakingSetting, updateUserStakingSetting } from '../../../../redux/userStaking/userStakingActions';
    
export default function StakingModal({ isCreate = false, setting = {}, userId = '' }) {
  const dispatch = useDispatch();
 
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const stakingOptions = useSelector((state) => state.staking.options);

  const { hideModal } = useContext(ModalContext);
  
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [options, setOptions] = useState([]);
  const [minDeposit, setMinDeposit] = useState(0);

  useEffect(() => {
    setOptions(isCreate ? stakingOptions : setting.options);
  }, [isCreate]);

  useEffect(() => {
    if (!setting._id) return;

    setSelectedCurrency(setting.currency || {});
    setMinDeposit(setting.minDeposit || 0);
  }, [setting]);

  const isValid = () => {
    console.log(minDeposit);
    if (!selectedCurrency || !selectedCurrency._id) {
      toast.warning('Select Currency');
    
      return false;
    }
  
    if (minDeposit <= 0) {
      toast.warning('Min deposit should be greater then 0');

      return false;
    }

    for (const option of options) {
      if (option.percent < 1 || option.percent > 100) {
        toast.warning('Please fill all fields. Note: Min value for input filed is 1. Max value - 100.');
    
        return false;
      }
    }

    return true;
  };
  
  
  const submit = () => {
    if (!isValid()) return;
  
    if (isCreate) {
      const data = {
        options,
        userId,
        minDeposit,
        currencyId: selectedCurrency._id,
      };

      dispatch(createUserStakingSetting(data));
    } else {
      const data = {
        options,
        minDeposit,
      };
          
      dispatch(updateUserStakingSetting(setting._id, data));
    }
  
    hideModal();
  };

  const handleOptionChange = (value, id) => {
    setOptions((prev) => prev.map((option) => (option._id === id ? { ...option, percent: value } : option)));
  };
  
  return (
    <div className="withdrawal-data" style={{ overflowX: 'hidden', overflowY: 'scroll', maxHeight: '500px' }}>
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Currency</label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" disabled={!isCreate} variant="">{selectedCurrency.name || 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {currencies.map(({ _id, name }) => (
                  <Dropdown.Item key={_id} onClick={() => setSelectedCurrency({ _id, name })}>{name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Minimum Deposit</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={minDeposit}
                step="1"
                min="1"
                onChange={({ target: { value } }) => setMinDeposit(value)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {
        options?.map((option) => (
          <Row key={option._id}>
            <Col md={12}>
              <div className="form-group me-2">
                <label className="control-label mb-2">{!option.isFlexible ? `${option.period} ${option.durationType}` : option.label}</label>
                <div className="input-wrapper">
                  <input 
                    type="number" 
                    className="mb-3 form-control" 
                    value={option.percent}
                    step="1"
                    min="1"
                    max="100"
                    onChange={({ target: { value } }) => handleOptionChange(value, option._id)}
                  />
                </div>
              </div>
            </Col>
          </Row>
        ))
      }
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" onClick={submit}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}
  
