import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteNetwork,
  showAllNetworks,
} from '../../redux/network/networkActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function Network() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const permissionName = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );
  const networksData = useSelector((state) => state.network.networks);
  const success = useSelector((state) => state.network?.success);
  const isUserCanAddNetwork = permissionName && permissionName.includes('add_network');
  const isUserCanEditNetwork = permissionName && permissionName.includes('edit_network');
  const isUserCanDeleteNetwork = permissionName && permissionName.includes('delete_network');

  useEffect(() => {
    async function fetchData() {
      await dispatch(showAllNetworks());
      if (success) {
        setLoader(false);
      }
    }
    fetchData();
  }, [success]);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteNetwork(id));
      }
    });
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h3>Networks Details</h3>
          {isUserCanAddNetwork && (
          <Link to="/add-network">
            <button type="button" className="btn btn-default">Add Network</button>
          </Link>
          )}
          <div className="mt-3 table-responsive">
            <table className="table">
              <thead className="table_head">
                <tr>
                  <th>Name</th>
                  <th>Symbol</th>
                  {isUserCanEditNetwork && isUserCanDeleteNetwork && <th>Action(s)</th>}
                </tr>
              </thead>
              <tbody>
                {networksData
                    && networksData.length > 0
                    && networksData.map((network) => (
                      <tr key={network?._id}>
                        <td>{network.name}</td>
                        <td>{network.symbol}</td>
                        {(isUserCanDeleteNetwork || isUserCanEditNetwork) && (
                          <td>
                            {isUserCanEditNetwork && (
                              <Link
                                to={`/edit-network/${network._id}`}
                                className="btn btn-primary me-2 text-decoration-none text-light"
                              >
                                Edit
                              </Link>
                            )}
                            {isUserCanDeleteNetwork && (
                              <button
                                type="button"
                                className="btn btn-danger me-2"
                                onClick={() => deleteAction(network._id)}
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
}

export default Network;
