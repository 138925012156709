import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export function StopTradeModal({
  showFuture, handleCloseFuture, stopRate, handleFutureStop,
}) {
  return (
    <Modal
      className="withdraw-details two-factor-auth text-center"
      centered
      backdrop="static"
      show={showFuture}
      onHide={handleCloseFuture}
    >
      <Modal.Header className="modal-main-heading" closeButton />
      <Modal.Body>
        <div className="mb-5">
          <span>
            {`Are you sure want to stop it at ${stopRate} rate?`}
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleCloseFuture}>
          No
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleFutureStop();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
