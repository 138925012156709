import React, { useState, useEffect } from 'react';

export function AdditionalInputComponent({
  id, value, disabled = false, type, name, setValue, labelTitle,
}) {
  return (
    <div className="form-group me-2">
      <label className="control-label mb-2">{labelTitle}</label>
      <div className="input-wrapper">
        <input 
          type={type}
          className="mb-3 form-control"
          name={name} 
          value={value}
          disabled={disabled}
          id={id}
          onChange={(e) => setValue(e)}
        />
      </div>
    </div>
  );
}
