import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { PendingWithdrawsSchema } from '../../DataTableSchemas/PendingWithdrawsSchema/PendingWithdrawsSchema';
import { getRole } from '../../redux/roles/roleActions';
import { resolveWithDrawFiatTransaction } from '../../redux/externalFiatTransactions/externalFiatTransactionActions';
import { resolveWithDrawBankTransaction } from '../../redux/externalBankTransactions/externalBankTransactionActions';
import { resolveWithDrawTransaction, revertTransaction } from '../../redux/users/userActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { showAllCurrencies } from '../../redux/currency/currencyActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import {
  updateIsReal, deleteTransaction, exportFilteredDataToFile, updateTransactionState, getWithdrawalsByQuery,
} from '../../redux/transactions/transactionActions';
import { getCurrencyRates } from '../../redux/currencyRate/currencyRateActions';
import { withdrawalColumns } from '../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters } from '../../components';
import { DownloadFile } from '../../components/DownloadFile';
import { useDebounce } from '../../hooks/useDebounce';


function PendingWithdraws({ isDeleted = false }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const columnsStorageName = 'DataTable_withdraws/columns';
  const filtersStorageName = 'DataTable_withdraws/filters';
  const filterIdStorageName = 'DataTable_withdraws/filter_id'; 
  const paginationStorageName = 'DataTable_withdraws/pending_pagination';
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(withdrawalColumns);

  const datatableFiltersRef = useRef(null);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const { paginatedData: withdrawalsData, totalCount } = useSelector((state) => state.transaction?.allWithdrawals);
  const exportFilterdDataToFile = useSelector((state) => state.transaction.exportFilterdDataToFile);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [userIdFilter, setUserIdFilter] = useState();
  const [transactionIdFilter, setTransactionIdFilter] = useState();
  const [currencyFilter, setCurrencyFilter] = useState([]);
  const [amountRange, setAmountRange] = useState(null);
  const [statusFilter, setStatusFilter] = useState([]);
  const [assignedToFilter, setAssignedToFilter] = useState([]);
  const [transactionFilter, setTransactionFilter] = useState([]);
  const [isRealFilter, setIsRealFilter] = useState([]);
  const [timeInitiatedFilter, setTimeInitiatedFilter] = useState([]);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [queryFilter, setQueryFilter] = useState({});
  const [activeInfo, setActiveInfo] = useState(null);
  const [inputFieldError, setInputFieldError] = useState({
    transactionIdIsNotValid: false,
    userIdIsNotValid: false,
    errorMessage: '',
  });

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && queryFilter[key] !== value) {
      setQueryFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(transactionIdFilter, 1000, (value) => debounceCallback({ value, key: 'transactionId' }));
  useDebounce(userIdFilter, 1000, (value) => debounceCallback({ value, key: 'userId' }));
  useDebounce(amountRange, 1000, (value) => debounceCallback({ value, key: 'amountRange' }));

  const setStoredPagination = () => {
    const pendingWithdrawalsRowsJSON = localStorage.getItem(paginationStorageName);
    if (pendingWithdrawalsRowsJSON) {
      const filterRows = JSON.parse(pendingWithdrawalsRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  
  const handleInfoModalToggle = (id) => {
    if (activeInfo === id) setActiveInfo(null);
    else setActiveInfo(id);
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setQueryFilter(filters || {});

      setTransactionIdFilter(filters.transactionId || null);
      setUserIdFilter(filters.userId || null);
      setAmountRange(filters.amountRange || null);
      setCurrencyFilter(filters.currency || []);
      setAssignedToFilter(filters.manager || []);
      setTransactionFilter(filters['transaction type'] || []);
      setIsRealFilter(filters['real/fake'] || []);
      setStatusFilter(filters.status || []);
      setTimeInitiatedFilter(filters['time initiated'] || []);
    }
  };

  const handleClear = () => {
    setUserIdFilter(null);
    setTransactionIdFilter(null);
    setAmountRange(null);
    setStatusFilter([]);
    setTransactionFilter([]);
    setCurrencyFilter([]);
    setAssignedToFilter([]);
    setIsRealFilter([]);
    setTimeInitiatedFilter([]);
    setQueryFilter({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  useEffect(() => {
    const { roleId, _id: uid } = JSON.parse(localStorage.getItem('user'));
    getAllStoredData();

    Promise.allSettled([
      dispatch(showAllCurrencies()),
      dispatch(getSubCRMUsers(roleId, uid)),
      dispatch(getCurrencyRates('USD')),
    ]);
  }, []);


  const resolveCurrentFiatWithDrawTransaction = async (rowId, userId, status) => {
    await Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const currentFiatData = { userId, resolvedStatus: status, additionalInfo: result.value ? result.value : '' };
        await dispatch(resolveWithDrawFiatTransaction(rowId, currentFiatData));
        await dispatch(getWithdrawalsByQuery({
          page, limit: rowsPerPage, query: queryFilter, isDeleted,
        }));
      }
    });
  };

  const resolveCurrentBankWithDrawTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const currentBankData = { userId, resolvedStatus: status, additionalInfo: result.value ? result.value : '' };
        await dispatch(resolveWithDrawBankTransaction(rowId, currentBankData));
        await dispatch(getWithdrawalsByQuery({
          page, limit: rowsPerPage, query: queryFilter, isDeleted,
        }));
      }
    });
  };

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(deleteTransaction(id));
      });
  };

  useEffect(() => {
    const loginData = localStorage.getItem('user');
    const userData = JSON.parse(loginData);
    const id = userData?.roleId;
    dispatch(getRole(id));
  }, []);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const resolveCurrentWithDrawTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const userData = {
          userId,
          resolvedStatus: status,
          additionalInfo: result.value ? result.value : '',
        };

        return Promise.resolve(dispatch(resolveWithDrawTransaction(rowId, userData, false)))
          .then(() => dispatch(getWithdrawalsByQuery({
            page, limit: rowsPerPage, query: queryFilter, isDeleted,
          })));
      }
      return null;
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        const userData = { userId };
        return Promise.resolve(dispatch(revertTransaction(rowId, userData, false)))
          .then(() => dispatch(getWithdrawalsByQuery({
            page, limit: rowsPerPage, query: queryFilter, isDeleted,
          })));
      }
      return null;
    });
  };
        
  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const setCRMFilters = (filter) => {
    const {
      currency, amountRange, assignedTo, status, type, isReal, timeOptionIds, uid, transactionId, 
    } = filter;

    setQueryFilter({
      transactionId,
      userId: uid,
      'transaction type': type,
      'real/fake': isReal,
      manager: assignedTo,
      currency,
      status,
      amountRange,
      'time initiated': timeOptionIds,
    });

    setUserIdFilter(uid || null);
    setTransactionIdFilter(transactionId || null);
    setAmountRange(amountRange || null);
    setCurrencyFilter(currency || null);
    setAssignedToFilter(assignedTo || null);
    setTransactionFilter(type || null);
    setIsRealFilter(isReal || null);
    setStatusFilter(status || null);
    setTimeInitiatedFilter(timeOptionIds || null);
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname,
      transactionId: filters.transactionId || '',
      uid: filters.userId || '',
      currency: filters.currency || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      type: filters['transaction type'] || [],
      isReal: filters['real/fake'] || [],
      assignedTo: filters.manager || [],
      status: filters.status || [],
      timeOptionIds: filters['time initiated'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        uid: filters.userId || '',
        transactionId: filters.transactionId || '',
        currency: filters.currency || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
        type: filters['transaction type'] || [],
        isReal: filters['real/fake'] || [],
        assignedTo: filters.manager || [],
        status: filters.status || [],
        timeOptionIds: filters['time initiated'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRange([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRange([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRange(value);
      }
    }
  };

  const columns = PendingWithdrawsSchema(
    amountRange,
    userIdFilter,
    setUserIdFilter,
    transactionIdFilter,
    setTransactionIdFilter,
    setAmountRange,
    currencyFilter,
    setCurrencyFilter,
    currencies,
    crmUsers,
    assignedToFilter,
    setAssignedToFilter,
    setTransactionFilter,
    transactionFilter,
    statusFilter,
    setStatusFilter,
    handleInfoModalToggle,
    activeInfo,
    setActiveInfo,
    permissionName,
    resolveCurrentWithDrawTransaction,
    handleRevertTransaction,
    resolveCurrentBankWithDrawTransaction,
    resolveCurrentFiatWithDrawTransaction,
    updateIsReal,
    columnConfig,
    isRealFilter,
    setIsRealFilter,
    queryFilter,
    setQueryFilter,
    setTimeInitiatedFilter,
    handleDeleteTransaction,
    isDeleted,
    timeInitiatedFilter,
    setDebounceAmountRange,
    inputFieldError, 
    setInputFieldError,
  );

  const handleExportTransactions = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (withdrawalsData.length && columns.length) {
      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile(withdrawalsData, columns, fileType)); 
      setLoader(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(queryFilter));
        setTableLoading(true);
        
        await dispatch(getWithdrawalsByQuery({
          page, limit: rowsPerPage, query: queryFilter, isDeleted,
        }));
  
        setTableLoading(false);
      }
    }

    fetchData();
  }, [page, rowsPerPage, isPaginationDT, queryFilter]);

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateTransactionState()); 
    }
  }, [exportFilterdDataToFile]);

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h3>Withdrawals</h3>
          <div className="action__btn-row">
            {
              crmFilters && (
                <DatatableFilters 
                  ref={datatableFiltersRef}
                  filters={crmFilters} 
                  setFilters={setCRMFilters}
                  createFilter={createUserCRMFilter}
                  deleteFilter={deleteUserCRMFilter}
                  updateFilter={updateUserCRMFilter}
                  storageKey={filterIdStorageName}
                  pathname={pathname}
                /> 
              )
            }
            <DownloadFile handleExport={handleExportTransactions} />
            <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
              <FontAwesomeIcon icon={faTimes} size="sm" />
              Clear
            </button>
            <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
              <FontAwesomeIcon icon={faRefresh} size="sm" />
              Refresh
            </button>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            {
              isPaginationDT
                && (
                  <DataTable
                    columns={columns}
                    data={withdrawalsData}
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[25, 50, 100, 500]}
                    onChangePage={handlePageChange}
                    paginationDefaultPage={page}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    fixedHeader
                    persistTableHead
                    highlightOnHover
                    theme="solarizedd"
                    progressPending={tableLoading}
                    progressComponent={<div className="datatable-loader__background" />}
                  />
                ) 
            }
          </div>
        </div>
      </div>
    )
  );
}

export default PendingWithdraws;

