import {
  GET_LEVERAGE_SETTINGS, 
  CREATE_LEVERAGE_SETTING, 
  UPDATE_LEVERAGE_SETTING, 
  DELETE_LEVERAGE_SETTING, 
} from './leverageSettingTypes';

export default (state = [], action) => {
  switch (action.type) {
    case GET_LEVERAGE_SETTINGS:
      return action.payload;
    case CREATE_LEVERAGE_SETTING:
      return [...state, action.payload];
    case UPDATE_LEVERAGE_SETTING:
      return state.map((setting) => (setting._id === action.payload._id ? action.payload : setting));
    case DELETE_LEVERAGE_SETTING:
      return state.filter((setting) => setting._id !== action.payload._id);
    default:
      return state;
  }
};
