import React, {
  useState, useMemo, useEffect, useRef, 
} from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ExchangeListColumns } from '../../../DataTableSchemas/ExchangeListColumns';
import { userExchangeListColumns } from '../../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters } from '../../../components';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { getExchangeOrders } from '../../../redux/exchangeOrder/exchangeOrderActions';
import { getUserLeverageOrders } from '../../../redux/leverageOrder/leverageOrderActions';

function ExchangeList() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const internalTransactions = useSelector((state) => state.exchangeOrders);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const columnsStorageName = 'DataTable_user/exchange-list_columns';
  const filtersStorageName = 'DataTable_user/exchange-list_filters';
  const filterIdStorageName = 'DataTable_user/exchange-list_filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(userExchangeListColumns);
  const [filters, setFilters] = useState();

  const [orderCoinsFilter, setOrderCoinsFilter] = useState([]);
  const [convertedCoinsFilter, setConvertedCoinsFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [orderAmountFilter, setOrderAmountFilter] = useState([0, 1000000]);
  const [convertedAmountFilter, setConvertedAmountFilter] = useState([0, 1000000]);

  const [filteredResult, setFilteredResult] = useState([]);

  const datatableFiltersRef = useRef(null);

  useEffect(() => {
    if (id && id.length) dispatch(getExchangeOrders(id));
  }, [id]);

  const exchangeData = useMemo(() => (internalTransactions || []), [internalTransactions]);

  const orderCoins = useMemo(() => {
    const coins = [];
    const uniqueCoins = [];

    if (!exchangeData || !exchangeData.length) return coins;

    exchangeData.forEach((item) => {
      const coin = item.fromCurrency?.symbol;

      if (coin && !uniqueCoins.includes(coin)) {
        uniqueCoins.push(coin);
        coins.push({
          name: coin,
          _id: coin,
        });
      }
    });

    return coins;
  }, [exchangeData]);

  const convertedCoins = useMemo(() => {
    const coins = [];
    const uniqueCoins = [];

    if (!exchangeData || !exchangeData.length) return coins;

    exchangeData.forEach((item) => {
      const coin = item.toCurrency?.symbol;

      if (coin && !uniqueCoins.includes(coin)) {
        uniqueCoins.push(coin);
        coins.push({
          name: coin,
          _id: coin,
        });
      }
    });

    return coins;
  }, [exchangeData]);

  const getExchanges = () => {
    dispatch(getExchangeOrders(id));
  };

  const handleClear = () => {
    setOrderCoinsFilter([]);
    setOrderAmountFilter([0, 1000000]);
    setConvertedCoinsFilter([]);
    setConvertedAmountFilter([0, 1000000]);
    setStatusFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setOrderCoinsFilter(filters['order coin'] || []);
      setOrderAmountFilter(filters.amountRange || [0, 1000000]);
      setConvertedCoinsFilter(filters['converted coin'] || []);
      setConvertedAmountFilter(filters.convertedAmountRange || [0, 1000000]);
      setStatusFilter(filters.status || []);
    }
  };

  const setCRMFilters = (filter) => {
    const {
      currency, convertedCurrency, status, amountRange, convertedAmountRange, 
    } = filter;

    setOrderCoinsFilter(currency);
    setOrderAmountFilter(amountRange);
    setConvertedCoinsFilter(convertedCurrency);
    setConvertedAmountFilter(convertedAmountRange);
    setStatusFilter(status);
    setFilters({
      'order coin': currency,
      amountRange,
      'converted coin': convertedCurrency,
      status,
      convertedAmountRange,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/exchange`,
      currency: filters['order coin'] || [],
      amountRange: filters.amountRange || [0, 1000000],
      convertedCurrency: filters['converted coin'] || [],
      convertedAmountRange: filters.convertedAmountRange || [0, 1000000],
      status: filters.status || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['order coin'] || [],
        amountRange: filters.amountRange || [0, 1000000],
        convertedCurrency: filters['converted coin'] || [],
        convertedAmountRange: filters.convertedAmountRange || [0, 1000000],
        status: filters.status || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const search = () => {
    const filteredResults = exchangeData.filter(
      (item) => (!orderCoinsFilter.length || orderCoinsFilter.includes(String(item.fromCurrency?.symbol)))
        && (Number(item.fromAmount) >= orderAmountFilter[0] && Number(item.fromAmount) <= orderAmountFilter[1])
        && (!convertedCoinsFilter.length || convertedCoinsFilter.includes(item.toCurrency?.symbol))
        && (Number(item.convertedAmount) >= convertedAmountFilter[0] && Number(item.convertedAmount) <= convertedAmountFilter[1])
        && (!statusFilter.length || statusFilter.includes(String(item.isResolved))),
    );

    setFilteredResult(filteredResults);
  };

  useEffect(() => {
    if (exchangeData.length) search();
    if (filters) localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  }, [orderCoinsFilter, orderAmountFilter, convertedCoinsFilter, convertedAmountFilter, statusFilter]);

  useEffect(() => {
    setFilteredResult(exchangeData);
    setStoredFilterData();
  }, [exchangeData]);

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const ordersColumns = ExchangeListColumns(
    columnConfig,
    orderCoins,
    orderCoinsFilter,
    setOrderCoinsFilter,
    convertedCoins,
    convertedCoinsFilter,
    setConvertedCoinsFilter,
    statusFilter,
    setStatusFilter,
    orderAmountFilter,
    setOrderAmountFilter,
    convertedAmountFilter,
    setConvertedAmountFilter,
    filters,
    setFilters,
    permissionName,
  );

  useEffect(() => {
    setStoredColumnsData();
  }, []);

  return (
    <div>
      <div className="action__btn-row">
        {
            crmFilters ? (
              <DatatableFilters 
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={`${pathname}/exchange`}
              /> 
            ) : null
        }
        <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
        <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
          <FontAwesomeIcon icon={faTimes} size="sm" />
          Clear
        </button>
        <button type="button" className="btn btn-default icon-btn ms-1" onClick={getExchanges}>
          <FontAwesomeIcon icon={faRefresh} size="sm" />
          Refresh
        </button>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        {exchangeData && exchangeData.length
          ? (
            <DataTable
              columns={ordersColumns}
              data={filteredResult}
              pagination
              paginationResetDefaultPage={false}
              fixedHeader
              persistTableHead
              theme="solarizedd"
            />
          )
          : (
            <div className="no-tbl-data">No Transactions Found!</div>
          )}
      </div>
    </div>
  );
}

export default ExchangeList;
