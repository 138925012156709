export const SHOW_ALL_USERS = 'SHOW_ALL_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const CHANGE_PASS = 'CHANGE_PASS';
export const REFERRALS_PER_ID = 'REFERRALS_PER_ID';
export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const CONNECT_WALLET = 'CONNECT_WALLET';
export const SHOW_SUB_ADMINS = 'SHOW_SUB_ADMINS';
export const SINGLE_SUB_ADMIN = 'SINGLE_SUB_ADMIN';
export const SHOW_SALES_AGENTS = 'SHOW_SALES_AGENTS';
export const SINGLE_SALES_AGENT = 'SINGLE_SALES_AGENT';
export const SHOW_RETENTION_AGENTS = 'SHOW_RETENTION_AGENTS';
export const SINGLE_RETENTION_AGENT = 'SINGLE_RETENTION_AGENT';
export const DELETED_USERS = 'DELETED_USERS';
export const DELETED_SUB_ADMINS = 'DELETED_SUB_ADMINS';
export const DELETED_SALES_AGENTS = 'DELETED_SALES_AGENTS';
export const DELETED_RETENTION_AGENTS = 'DELETED_RETENTION_AGENTS';
export const RECOVER_USER = 'RECOVER_USER';
export const DISCONNECT_WALLET = 'DISCONNECT_WALLET';
export const SET_WALLET = 'SET_WALLET';
export const ADD_BALANCE = 'ADD_BALANCE';
export const SHOW_ADMIN_BALANCE = 'SHOW_ADMIN_BALANCE';
export const GET_SENT_BALANCE_TO_USER = 'GET_SENT_BALANCE_TO_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const TOGGLE_USER_STATE = 'TOGGLE_USER_STATE';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const ADD_CURRENCY_AMOUNT = 'ADD_CURRENCY_AMOUNT';
export const REMOVE_CURRENCY_AMOUNT = 'REMOVE_CURRENCY_AMOUNT';
export const RESOLVE_USER_TRANSACTION = 'RESOLVE_USER_TRANSACTION';
export const REVERT_USER_TRANSACTION = 'REVERT_USER_TRANSACTION';
export const NEW_START_PRICE = 'NEW_START_PRICE';
export const DELETE_USERS = 'DELETE_USERS';
export const SHOW_SUPERVISORS = 'SHOW_SUPERVISORS';
export const SINGLE_SUPERVISOR = 'SINGLE_SUPERVISOR';
export const DELETED_SUPERVISORS = 'DELETED_SUPERVISORS';
export const SHOW_SALES_TEAMLEADS = 'SHOW_SALES_TEAMLEADS';
export const SINGLE_SALES_TEAMLEAD = 'SINGLE_SALES_TEAMLEAD';
export const DELETED_SALES_TEAMLEADS = 'DELETED_SALES_TEAMLEADS';
export const SHOW_RETENTION_TEAMLEADS = 'SHOW_RETENTION_TEAMLEADS';
export const SINGLE_RETENTION_TEAMLEAD = 'SINGLE_RETENTION_TEAMLEAD';
export const DELETED_RETENTION_TEAMLEADS = 'DELETED_RETENTION_TEAMLEADS';
export const GET_MANAGERS = 'GET_MANAGERS';
export const UPDATE_AFFILIATE_TOKEN = 'UPDATE_AFFILIATE_TOKEN';
export const CSV_FILE_IMPORTED = 'CSV_FILE_IMPORTED';
export const SHOW_AGENTS = 'SHOW_AGENTS';
export const SHOW_AGENT_ROLES = 'SHOW_AGENT_ROLES';
export const DELETED_AGENT = 'DELETED_AGENT';
export const GET_USERS_COUNT_DETAIL = 'GET_USERS_COUNT_DETAIL';
export const GET_GLOBAL_USERS = 'GET_GLOBAL_USERS';
export const UPDATE_ISREAL = 'UPDATE_ISREAL';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const EXPORT_FILTERED_DATA_TO_FILE = 'EXPORT_FILTERED_DATA_TO_FILE';
export const UPLOAD_USERS = 'UPLOAD_USERS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const FIND_ONE_USER_AND_EDIT_IN_USERS = 'FIND_ONE_USER_AND_EDIT_IN_USERS';
export const UPDATE_USERS_FIELDS = 'UPDATE_USERS_WITHDRAWAL_STATUS';
