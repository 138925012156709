/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { massUpdateCryptoPair } from '../../redux/cryptoPair/cryptoPairActions';
import { AdditionalInputComponent } from './components/AdditionalInputComponent';
import { SystemSettingsTypes } from '../../types/systemSettings';

export default function AdditionalMassEditModal({
  hideModal, selectedPairsIds = [], setSelectedPairsIds, setClearRows,
}) {
  const dispatch = useDispatch();

  const isLeverageChangeEnabled = useSelector((state) => state.systemSettingsReducer.systemSettings.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);

  const [data, setData] = useState({
    leverage: 5,
    commission: 0,
    swapLong: 0,
    swapShort: 0,
    minVolume: 0,
    maxVolume: 0,
    selectedPairsIds,
  });

  const validator = {
    leverage: [2, 100],
    commission: [0, 100],
    swapLong: [0, 100],
    swapShort: [0, 100],
    minVolume: [0, Infinity],
    maxVolume: [0, Infinity],
  };

  const submit = async () => {
    if (!data || typeof data !== 'object') {
      toast.warning('Invalid data provided.');
      return;
    }
  
    for (const [key, value] of Object.entries(data)) {
      const [minValue, maxValue] = validator[key] || [0, Infinity];

      if (key === 'leverage' && isLeverageChangeEnabled) {
        continue;
      }
  
      if (value < minValue || value > maxValue) {
        toast.warning(`${key} cannot be less than ${minValue} or more than ${maxValue}`);
        return;
      }
    }

    await dispatch(massUpdateCryptoPair(data));
    setClearRows((prev) => !prev);
    setSelectedPairsIds([]);
    hideModal();
  };

  const inputHandler = (event) => {
    const { target: { value, name } } = event;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div className="withdrawal-data">
      {!isLeverageChangeEnabled && (
        <Row className="mt-10">
          <Col md={12}>
            <AdditionalInputComponent 
              labelTitle="Leverage"
              value={data.leverage}
              type="number"
              name="leverage"
              setValue={inputHandler}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <AdditionalInputComponent 
            labelTitle="Commission %"
            value={data.commission}
            type="number"
            name="commission"
            setValue={inputHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AdditionalInputComponent 
            labelTitle="Swap Long %"
            value={data.swapLong}
            type="number"
            name="swapLong"
            setValue={inputHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AdditionalInputComponent 
            labelTitle="Swap Short %"
            value={data.swapShort}
            type="number"
            name="swapShort"
            setValue={inputHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AdditionalInputComponent 
            labelTitle="Min Volume"
            value={data.minVolume}
            type="number"
            name="minVolume"
            setValue={inputHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AdditionalInputComponent 
            labelTitle="Max Volume"
            value={data.maxVolume}
            type="number"
            name="maxVolume"
            setValue={inputHandler}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} className="additionalPageMassModalColl">
          <Button variant="secondary" onClick={hideModal}>Cancel</Button>
          <Button variant="primary" className="ms-2" onClick={submit}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}
