import React, { useEffect } from 'react';
import { toast, Flip } from 'react-toastify';
import packageJson from '../../package.json';
import { UpdateCrmNotificationMessage } from '../components';

const globalVersion = packageJson.version;

export const useVersionNotification = () => {
  const version = localStorage.getItem('version');

  const clearServiceWorkerCache = async () => {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(
        registrations.map((registration) => registration.unregister()),
      );

      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map((cacheName) => caches.delete(cacheName)),
      );

      localStorage.setItem('version', globalVersion);
      window.location.reload(true);
    } catch (error) {
      console.error('Error clearing Service Worker cache:', error);
    }
  };

  useEffect(() => {
    const toastId = `update_${globalVersion}`;
    
    if (globalVersion !== version) {
      if (!toast.isActive(toastId)) {
        toast.dark(<UpdateCrmNotificationMessage version={globalVersion} />, {
          toastId,
          icon: '🚀',
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'dark',
          transition: Flip,
          onClick: clearServiceWorkerCache, // Ensure this function is available
        });
      }
    }
  }, [version, globalVersion]);
};
