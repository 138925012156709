import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.css';
import { formatDate } from '../../../../helpers/formatDate';
import { EventModal } from '../../../../components';

export function Event({
  event, setFocusedEvent, focusedEvent, setShowEditEventModal, 
}) {
  const [showEventModal, setShowEventModal] = useState(false);
  const { _id, name, time } = event;
  const eventTime = new Date(time);
  return (
    <div 
      className={styles.eventWrapper} 
      key={_id}
    >
      {name}
      {
                eventTime < new Date() 
                    && <div className={styles.eventClosedButton}>Closed</div>
            }
      <FontAwesomeIcon 
        icon={faEllipsisVertical} 
        className={styles.moreButton} 
        onClick={() => {
          setShowEventModal(true);
          setFocusedEvent(event);
        }}
      />
      <span className={styles.eventTime}>{formatDate(eventTime)}</span>
      {showEventModal && (
        <EventModal 
          setShowEditEventModal={setShowEditEventModal}
          focusedEvent={focusedEvent}
          onClickOutside={() => setShowEventModal(false)}
        />
      )}
    </div>
  );
};
