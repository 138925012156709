import { useEffect } from 'react';

export const useMultiDebounce = (value = null, delay, callback) => {
  useEffect(() => {
    if (typeof value === 'object') {
      const handler = setTimeout(() => {
        callback(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }
  }, [...value]);
};
