/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown, faArrowUp, faBagShopping, faUser,
} from '@fortawesome/free-solid-svg-icons';
import { getUsersCount, updateUserState } from '../../../redux/users/userActions';
import { getDepositAndOrdersDetail, updateDepositOrderDetailState } from '../../../redux/transactions/transactionActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';

const currencyFormatter = require('currency-formatter');

function MiscellaneousDetails() {
  const dispatch = useDispatch();

  const [userCountLoader, setUserCountLoader] = useState(false);
  const [depositOrderLoader, setDepositOrderLoader] = useState(false);
  const usersCountDetail = useSelector((state) => state?.users?.usersCountDetail);
  const usersCountDetailFetched = useSelector((state) => state?.users?.usersCountDetailFetched);
  const depositOrderDetails = useSelector((state) => state?.transaction?.depositOrderDetails);
  const fetchedDepositOrderDetails = useSelector((state) => state?.transaction?.fetchedDepositOrderDetails);

  useEffect(() => {
    setUserCountLoader(true);
    setDepositOrderLoader(true);
    dispatch(getUsersCount());
    dispatch(getDepositAndOrdersDetail());
  }, []);

  useEffect(() => {
    if (usersCountDetailFetched) {
      setUserCountLoader(false);
      dispatch(updateUserState());
    }
  }, [usersCountDetailFetched]);

  useEffect(() => {
    if (fetchedDepositOrderDetails) {
      setDepositOrderLoader(false);
      dispatch(updateDepositOrderDetailState());
    }
  }, [fetchedDepositOrderDetails]);

  return (
    userCountLoader || depositOrderLoader ? (<FullPageTransparentLoader />) : (
      <div className="row mb-4">
        <div className="col-xl-3 col-md-6">
          <Link to="/crm-users" className="card card-animate colored-cards bg-light-black">
            <div className="card-body  d-flex flex-column justify-content-between">
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <div className="d-flex flex-row align-items-center">
                    <h1 className="text-uppercase">
                      Total active users:
                      <span className="text-orange">{usersCountDetail && Object.keys(usersCountDetail)?.length > 0 ? usersCountDetail?.activeUsers : ''}</span>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="users-info d-flex align-items-center justify-content-between">
                <div className="d-flex flex-row align-items-center">
                  <div className="avatar-sm flex-shrink-0">
                    <span className="avatar-title bg-soft-light rounded fs-3">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <h4>{usersCountDetail && Object.keys(usersCountDetail)?.length > 0 ? usersCountDetail?.totalUsers : ''}</h4>
                </div>
                <h2 className="text-green">
                  +
                  {usersCountDetail && Object.keys(usersCountDetail)?.length > 0 ? usersCountDetail?.todayUsers : ''}
                </h2>
              </div>
              <div className="d-flex align-items-end justify-content-between">
                <h1 className="text-capitalize">
                  New users this month:
                  <span className="text-orange">{usersCountDetail && Object.keys(usersCountDetail)?.length > 0 ? usersCountDetail?.monthUsers : ''}</span>
                </h1>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/external-transactions" className="card card-animate colored-cards bg-green card-basic-info">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className=" flex-grow-1 overflow-hidden">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon-info">
                      <h1 className="text-capitalize mb-2">Total deposits</h1>
                      <h2>{depositOrderDetails && Object.keys(depositOrderDetails)?.length > 0 ? (currencyFormatter.format(depositOrderDetails?.depositAmountsInUsd?.totalAmt?.toFixed(2), { code: 'USD' })) : ''}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/orders-history" className="card card-animate colored-cards bg-light-blue">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="card-top-content flex-grow-1 overflow-hidden">
                  <div className="d-flex flex-row align-items-center">
                    <div className="icon-info">
                      <h1 className="text-uppercase">orders</h1>
                    </div>
                  </div>
                </div>
                <div className="flex-shrink-0 text-right">
                  {/* <span className="arrow-right-text"><FontAwesomeIcon icon={faArrowDown} /> -3.57% </span> */}
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div className="counter-detail-wrap">
                  <h4 className="text-white mb-4">
                    <span className="counter-value">{depositOrderDetails && Object.keys(depositOrderDetails)?.length > 0 ? depositOrderDetails?.totalOrders : ''}</span>
                  </h4>
                  <Link to="/orders-history" className="text-decoration-underline">View all orders</Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className="avatar-title bg-soft-light rounded fs-3">
                    <FontAwesomeIcon icon={faBagShopping} />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-xl-3 col-md-6">
          <Link to="/external-transactions" className="card card-animate colored-cards bg-purple card-basic-info">
            <div className="card-body">
              <div className="d-flex align-items-center h-100">
                <div className=" flex-grow-1 overflow-hidden">
                  <div className="d-flex flex-row align-items-center w-100">
                    <div className="icon-info w-100">
                      <h1 className="text-capitalize mb-2">This Month&apos;s Deposits</h1>
                      <div className="d-flex align-items-center justify-content-between">
                        <h2>{depositOrderDetails && Object.keys(depositOrderDetails)?.length > 0 ? (currencyFormatter.format(depositOrderDetails?.depositAmountsInUsd?.totalAmtThisMonth?.toFixed(2), { code: 'USD' })) : ''}</h2>
                        <span className="earning-icons">
                          <FontAwesomeIcon icon={faArrowUp} className="icon-green" />
                          <FontAwesomeIcon icon={faArrowDown} className="icon-red" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    )
  );
}

export default MiscellaneousDetails;
