export const spotOrdersColumns = [
  {
    name: 'Order ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'User Name',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Spot Pair',
    field: 'spotPair',
    selected: true,
  },
  {
    name: 'Type',
    field: 'marketOrder',
    selected: true,
  },
  {
    name: 'Direction',
    field: 'tradeType',
    selected: true,
  },
  {
    name: 'Order Value',
    field: 'investedQty,tradeStartPrice',
    selected: true,
  },
  {
    name: 'Filled Qty',
    field: 'status,investedQty',
    selected: true,
  },
  {
    name: 'Order Price',
    field: 'tradeStartPrice',
    selected: true,
  },
  {
    name: 'Order Qty',
    field: 'investedQty',
    selected: true,
  },
  {
    name: 'Status',
    field: 'status',
    selected: true,
  },
  {
    name: 'Time Opened',
    field: 'createdAt',
    selected: true,
  },
];
