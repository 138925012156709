import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteUser, editUser, showSubAdmins } from '../../redux/users/userActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function SubAdmins() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);

  const subAdmins = useSelector((state) => state.users?.subAdmin);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const success = useSelector((state) => state.users.success);
  const fetched = useSelector((state) => state.users.fetched);

  const isUserCanBlockCrmUsers = permissionName && permissionName.includes('block_crm_user');
  const isUserCanEditCrmUsers = permissionName && permissionName.includes('edit_crm_user');
  const isUserCanDeleteCrmUsers = permissionName && permissionName.includes('delete_crm_user');

  useEffect(() => {
    setLoader(true);
    dispatch(showSubAdmins());
    if (fetched) {
      setLoader(false);
    }
  }, [success, fetched]);

  const userAction = (id, type) => {
    Swal.fire({
      title: `Are you sure you want to ${type && type === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${type && type === 'block' ? 'Block' : 'Unblock'}`,
    }).then((result) => {
      if (result.isConfirmed) {
        const data = { status: !(type && type === 'block') };
        dispatch(editUser(id, data));
      }
    });
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteUser(id));
      }
    });
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>Agent Details</h3>
            {permissionName && permissionName.length > 0 && permissionName.includes('add_sub_admin')
              ? <Link to="/add-sub-admin"><button type="button" className="btn btn-default">Add Agent</button></Link>
              : null}
            
            <Link to="/add-permission"><button type="button" className="btn btn-default" style={{ margin: '15px' }}>Add Permissions</button></Link>
            
            <table className="table mt-3 table-responsive table">
              <thead className="table_head">
                <tr>
                  <th>Name</th>
                  <th>User Name</th>
                  <th>Email</th>
                  {(isUserCanDeleteCrmUsers || isUserCanEditCrmUsers || isUserCanBlockCrmUsers) && <th>Action(s)</th>}
                </tr>
              </thead>
              <tbody>
                {subAdmins && subAdmins.length > 0 ? subAdmins.map((data) => (
                  <tr key={data?._id}>
                    <td>{data?.firstName}</td>
                    <td>{data?.username}</td>
                    <td>{data?.email}</td>
                    <td>
                      {isUserCanEditCrmUsers && <Link to={`/edit-sub-admin/${data?._id}`} className="btn btn-primary me-2 text-decoration-none text-light">Edit</Link>}
                      {isUserCanDeleteCrmUsers && <button type="button" className="btn btn-danger me-2" onClick={() => deleteAction(data?._id)}>Delete</button>}
                      {isUserCanBlockCrmUsers && (
                        data?.status === true
                          ? <button type="button" className="btn btn-warning me-2" onClick={() => userAction(data?._id, 'block')}>Block</button>
                          : <button type="button" className="btn btn-warning me-2" onClick={() => userAction(data?._id, 'unBlock')}>Unblock</button>
                      )}
                    </td>
                  </tr>
                )) : null}

              </tbody>
            </table>
          </div>
        </div>
      )
  );
}

export default SubAdmins;
