export const userExchangeListColumns = [
  {
    name: 'Order Coin',
    selected: true,
  },
  {
    name: 'Order Amount',
    selected: true,
  },
  {
    name: 'Converted Coin',
    selected: true,
  },
  {
    name: 'Converted Amount',
    selected: true,
  },
  {
    name: 'Status',
    selected: true,
  },
  {
    name: 'Ordered At',
    selected: true,
  },
  {
    name: 'Actions',
    selected: true,
  },
];
