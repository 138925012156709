/* eslint-disable prefer-const */
import { toast } from 'react-toastify';
import {
  DISPLAY_TRANSACTIONS, 
  DISPLAY_ALL_ADMIN_DEPOSITS, 
  GET_ASSET_DETAILS, 
  GET_DEPOSIT_ORDER_DETAILS, 
  TOGGLE_TRANSACTION_STATE, 
  TOGGLE_DEPOSIT_ORDER_DETAILS_STATE, 
  ALL_PENDING_DEPOSITS, 
  ALL_PENDING_WITHDRAWALS, 
  DISPLAY_ALL_ADMIN_WITHDRAWS, 
  ALL_WITHDRAWALS, 
  ALL_DEPOSITS, 
  ALL_USER_WITHDRAWALS, 
  UPDATE_ISREAL_TRANSACTION,
  DELETE_TRANSACTION_DEP,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_TRANSACTIONS_BY_QUERY,
  GET_WITHDRAWS_BY_QUERY,
} from './transactionTypes';
import { apiHelper } from '../apiHelper';

export const deleteTransaction = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/transaction/delete/${id}`, '');

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: DELETE_TRANSACTION_DEP,
        payload: id,
      });
    }
  } catch (error) {
    toast.error(error?.response?.message);
  }
};

export const getTransactionsByQuery = ({
  limit, page, query = {}, isDeleted = false, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&isDeleted=${isDeleted}`;

    Object.entries(query).forEach(([key, value]) => {
      if (key === 'assigned to') key = 'assignedTo';

      if (key === 'time initiated') {
        const splitedValue = value.map((el) => el.split('||'));
        key = 'period';
        value = splitedValue.length ? JSON.stringify(splitedValue) : '';
      } 

      if (key === 'real/fake') key = 'isReal';

      url += `&${key}=${value}`;
    });
    
    const res = await apiHelper('get', `/api/transaction/deposits-table-data${url}`);
    if (res.status === 200) {
      await dispatch({
        type: GET_TRANSACTIONS_BY_QUERY,
        payload: res.data.transactions,
      });
    } 
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const getWithdrawalsByQuery = ({
  page, limit, query = {}, isDeleted = false, 
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}&isDeleted=${isDeleted}`;

    Object.entries(query).forEach(([key, value]) => {
      if (key === 'manager') key = 'assignedTo';

      if (key === 'real/fake') key = 'isReal';

      if (key === 'transaction type') key = 'transactionType';

      if (key === 'time initiated') {
        const splitedValue = value.map((el) => el.split('||'));
        key = 'period';
        value = splitedValue.length ? JSON.stringify(splitedValue) : '';
      } 

      url += `&${key}=${value}`;
    });

    const res = await apiHelper('get', `/api/transaction/withdrawls-table-data${url}`);
    if (res.status === 200) {
      await dispatch({
        type: GET_WITHDRAWS_BY_QUERY,
        payload: res.data.withdrawals,
      });
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const displayTransactions = () => async (dispatch) => {
  try {
    let res;
    res = await apiHelper('get', '/api/transaction', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_TRANSACTIONS,
        payload: data,
      });
    }
  } catch (error) { 
    toast.error(error.response.message);
  }
};

export const displayAllDeposits = () => async (dispatch) => {
  try {
    let res;
    res = await apiHelper('get', '/api/transaction/all-admin-deposits', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_ALL_ADMIN_DEPOSITS,
        payload: data?.adminDeposits ? data?.adminDeposits : [],
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const displayAllWithdraws = () => async (dispatch) => {
  try {
    let res;
    res = await apiHelper('get', '/api/transaction/all-admin-withdrawals', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_ALL_ADMIN_WITHDRAWS,
        payload: data?.adminWithdrawals ? data?.adminWithdrawals : [],
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCryptoAssetsDetail = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/dashboard/get-assets-detail', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ASSET_DETAILS,
        payload: data?.assetsDetails ? data?.assetsDetails : [],
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getDepositAndOrdersDetail = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/dashboard/get-deposit-order-detail', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_DEPOSIT_ORDER_DETAILS,
        payload: data || {},
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getPendingDeposits = () => async (dispatch) => {
  try {
    let res;
    res = await apiHelper('get', '/api/transaction/pending-deposits', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: ALL_PENDING_DEPOSITS,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getPendingWithdrawals = () => async (dispatch) => {
  try {
    let res;
    res = await apiHelper('get', '/api/transaction/pending-withdrawals', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: ALL_PENDING_WITHDRAWALS,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};


export const getAllUserWithdrawals = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/transaction/user-withdrawals/${userId}`);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: ALL_USER_WITHDRAWALS,
        payload: data.allUserWithdrawals,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const getAllDeposits = (isDeleted) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/transaction/all-deposits/${isDeleted}`, '');
    if (res.data) {
      dispatch({
        type: ALL_DEPOSITS,
        payload: res.data.allDeposits,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateIsReal = (transactionId, isReal) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/transaction/update-real/${transactionId}`, { isReal });

    if (res && res.data && res.data.success) {
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_ISREAL_TRANSACTION,
        payload: transactionId,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const exportFilteredDataToFile = (filteredItems, columns, fileType) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/transaction/export', {
      filteredItems, columns, fileType,
    });
    if (res.data) {
      const { data } = res;
      if (data.fileUrl) {
        window.open(data?.fileUrl);
      } else {
        toast.error('Something went wrong.');
      }
    } else {
      toast.error('Something went wrong.');
    }
    dispatch({
      type: EXPORT_FILTERED_DATA_TO_FILE,
      payload: true,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateDepositOrderDetailState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_DEPOSIT_ORDER_DETAILS_STATE,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const updateTransactionState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_TRANSACTION_STATE,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
