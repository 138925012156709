export const kycColumns = [
  {
    name: 'UID',
    field: '_id',
    selected: true,
  },
  {
    name: 'Client',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Type',
    field: 'verifiedStatus',
    selected: true,
  },
  {
    name: 'Time Uploaded',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Time Processed',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
