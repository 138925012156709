/* eslint-disable no-unsafe-optional-chaining */
import React, {
  useState, useEffect, useRef, 
} from 'react';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateOrderState, 
  revertLeverageOrder,
  getAllOrdersByQuery, 
  getOpenOrdersPairNames,
  exportFilteredDataToFile,
} from '../../redux/leverageOrder/leverageOrderActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { OrdersHistoryColumn } from '../../DataTableSchemas/OrdersHistoryColumn';
import { DatatableColumns, DatatableFilters } from '../../components';
import { orderHistoryColumns } from '../../columnsDefaultConfig';
import { DownloadFile } from '../../components/DownloadFile';
import { useDebounce } from '../../hooks/useDebounce';

function OrdersHistory() {
  const { pathname } = useLocation();

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  const filtersStorageName = 'DataTable_orders/order-history_filters';
  const filterIdStorageName = 'DataTable_orders/order-history_filter_id'; 
  const columnsStorageName = 'DataTable_orders/order-history_columns';
  const paginationStorageName = 'DataTable_orders/order-history_pagination';
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(orderHistoryColumns);

  const datatableFiltersRef = useRef(null);

  const [typeFilter, setTypeFilter] = useState([]);
  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);
  const [amountRangeFilter, setAmountRangeFilter] = useState(null);
  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [sortItemAndDirection, setSortItemAndDirection] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState({});
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const dispatch = useDispatch();
  const { ordersHistory, totalCount } = useSelector((state) => state.LeverageOrders?.orders);
  const { pairNames } = useSelector((state) => state.LeverageOrders?.pairNames);
  const exportFilterdDataToFile = useSelector((state) => state.LeverageOrders.exportFilterdDataToFile);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && filters[key] !== value) {
      setFilters((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(amountRangeFilter, 1000, (value) => debounceCallback({ value, key: 'amountRange' }));

  const handleClear = () => {
    setAmountRangeFilter(null);
    setCoinPairsFilter([]);
    setTypeFilter([]);
    setTradingTypeFilter([]);
    setSortItemAndDirection(null);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters || {});

      setAmountRangeFilter(filters.amountRange || null);
      setCoinPairsFilter(filters['trading pair'] || []);
      setTypeFilter(filters.type || []);
      setTradingTypeFilter(filters['trading type'] || []);
    } 
  };
    
  const setStoredPagination = () => {
    const ordersHistoryRowsJSON = localStorage.getItem(paginationStorageName);
    if (ordersHistoryRowsJSON) {
      const filterRows = JSON.parse(ordersHistoryRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }
    
    setIsPaginationDT(true);
  };

  const setDebounceAmountRange = (type, value, amountRangeValue) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setAmountRangeFilter([Number(value), maxValue]);
      }
      if (type === 'max') {
        setAmountRangeFilter([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setAmountRangeFilter(value);
      }
    }
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handlePageChange = (currentPage) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page: currentPage }));
    setPage(currentPage);
  }; 

  const handleSort = (column, sortDirection) => {
    const direction = sortDirection === 'asc' ? 1 : -1;

    switch (column.name) {
      case 'User': 
        setSortItemAndDirection({ user: direction });
        break;
      case 'Profit/Loss':
        setSortItemAndDirection({ profitLoss: direction });
        break;
      case 'Start Time':
        setSortItemAndDirection({ startTime: direction });
        break;
      case 'End Time':
        setSortItemAndDirection({ endTime: direction });
        break;
      default:
    }
  };


  const setCRMFilters = (filter) => {
    const {
      currency, amountRange, type, status, 
    } = filter;

    setAmountRangeFilter(amountRange);
    setCoinPairsFilter(currency);
    setTypeFilter(type);
    setTradingTypeFilter(status);
    setFilters({
      type,
      amountRange,
      'trading pair': currency,
      'trading type': status,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname,
      currency: filters['trading pair'] || [],
      status: filters['trading type'] || [],
      type: filters.type || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 100000000],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['trading pair'] || [],
        status: filters['trading type'] || [],
        type: filters.type || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 100000000],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(async () => {
    getAllStoredData();
    dispatch(getOpenOrdersPairNames());
  }, []);

  const storeColumnConfig = (config) => {
    setColumnConfig(config);
    localStorage.setItem(columnsStorageName, JSON.stringify(config));
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  const handleRevertTradeHistory = async (e, order) => {
    e.preventDefault();
    Swal.fire({
      title: 'Are you sure you want to Revert the order?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(revertLeverageOrder(order._id, order.userId));
      }
    });
  };

  const ordersColumns = OrdersHistoryColumn(
    columnConfig,
    filters,
    setFilters,
    typeFilter,
    setTypeFilter,
    tradingTypeFilter,
    setTradingTypeFilter,
    amountRangeFilter,
    setAmountRangeFilter,
    pairNames,
    coinPairsFilter,
    setCoinPairsFilter,
    permissionName,
    handleRevertTradeHistory,
    setDebounceAmountRange,
  );
    
  const handleExportOrders = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);
    if (ordersHistory.length && columns.length) {
      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile(ordersHistory, columns, fileType)); 
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(filters));
        setTableLoading(true);
  
        await dispatch(getAllOrdersByQuery({ page, limit: rowsPerPage, query: { ...filters, ...sortItemAndDirection } }));
  
        setTableLoading(false);
      }
    }
    fetchData();
  }, [page, rowsPerPage, isPaginationDT, filters, sortItemAndDirection]);

  useEffect(() => {
    if (exportFilterdDataToFile) {
      dispatch(updateOrderState()); 
      setLoader(false);
    }
  }, [exportFilterdDataToFile]);

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <>
        <div className="action__btn-row">
          {
                crmFilters && (
                  <DatatableFilters
                    ref={datatableFiltersRef}
                    filters={crmFilters}
                    setFilters={setCRMFilters}
                    createFilter={createUserCRMFilter}
                    deleteFilter={deleteUserCRMFilter}
                    updateFilter={updateUserCRMFilter}
                    storageKey={filterIdStorageName}
                    pathname={pathname}
                  />
                )
              }
          <DownloadFile handleExport={handleExportOrders} />
          <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={refresh}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {
                isPaginationDT
                  && (
                    <DataTable
                      columns={ordersColumns}
                      data={ordersHistory}
                      fixedHeader
                      sortServer
                      pagination
                      paginationServer
                      onSort={handleSort}
                      paginationPerPage={rowsPerPage}
                      paginationTotalRows={totalCount}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationDefaultPage={page}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      persistTableHead
                      highlightOnHover
                      theme="solarizedd"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
              }
        </div>
      </>
    )
  );
}

export default OrdersHistory;
