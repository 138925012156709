import {
  SET_KYC_AML_DATA,
  EDIT_KYC_AML_DATA,
  SET_KYC_AML_REQUESTS,
  GET_KYC_STATUSES,
  SET_TOTAL_COUNT,
  SET_USER_NAME_SEARCH_FILTER,
  SET_USER_EMAIL_SEARCH_FILTER,
  SET_USER_PHONE_SEARCH_FILTER,
  SET_VERIFY_STATUS_SEARCH_FILTER,
  SET_TIME_UPLOADED_FILTER,
  SET_IS_KYC_AML_DATA_LOADING,
  SET_IS_KYA_AML_REQUESTS_LOADING,
  SET_KYC_AML_DELETED,
} from './kycAmlTypes';

export const reqVerifyStatuses = {
  pending: 'pending',
};

const initialState = {
  kycAmlData: [],
  kycAmlRequests: [],
  kycStatuses: [],
  totalCount: 0,
  userNameSearchFilter: null,
  timeUploadedFilter: [],
  verifyStatusSearchFilter: '',
  isKycAmlDataLoading: false,
  isKycAmlRequestsLoading: false,
  isKycAmlDeleted: false,
};

const kycAmlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_KYC_AML_DELETED:
      return {
        ...state,
        isKycAmlDeleted: action.payload,
      };
    case SET_IS_KYC_AML_DATA_LOADING:
      return {
        ...state,
        isKycAmlDataLoading: action.payload,
      };
    case SET_IS_KYA_AML_REQUESTS_LOADING:
      return {
        ...state,
        isKycAmlRequestsLoading: action.payload,
      };
    case SET_KYC_AML_DATA:
      return {
        ...state,
        kycAmlData: action.payload,
      };
    case EDIT_KYC_AML_DATA:
      return {
        ...state,
        kycAmlRequests: state.kycAmlRequests.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        }),
      };
    case SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: action.payload,
      };
    case SET_USER_NAME_SEARCH_FILTER:
      return {
        ...state,
        userNameSearchFilter: action.payload,
      };
    case SET_USER_EMAIL_SEARCH_FILTER:
      return {
        ...state,
        userEmailSearchFilter: action.payload,
      };
    case SET_USER_PHONE_SEARCH_FILTER:
      return {
        ...state,
        userPhoneSearchFilter: action.payload,
      };
    case SET_TIME_UPLOADED_FILTER:
      return {
        ...state,
        timeUploadedFilter: action.payload,
      };
    case SET_VERIFY_STATUS_SEARCH_FILTER:
      return {
        ...state,
        verifyStatusSearchFilter: action.payload,
      };
    case SET_KYC_AML_REQUESTS:
      return {
        ...state,
        kycAmlRequests: action.payload,
      };
    case GET_KYC_STATUSES:
      return {
        ...state,
        kycStatuses: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default kycAmlReducer;
