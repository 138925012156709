import { toast } from 'react-toastify';
import {
  GET_WHITELIST_IPS,
  GET_WHITELIST_IP,
  ADD_WHITELIST_IP,
  EDIT_WHITELIST_IP,
  DELETE_WHITELIST_IP,
  TOGGLE_WHITELIST_STATE,
  ERROR_WHITELIST_STATE,
} from './whitelistIPTypes';
import { apiHelper } from '../apiHelper';

export const getWhitelistIPs = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/whitelist-ip', '');
    if (res?.data && res?.data?.whitelistIPs) {
      const { data } = res;
      await dispatch({
        type: GET_WHITELIST_IPS,
        payload: data?.whitelistIPs,
      });
    } else {
      await dispatch({
        type: GET_WHITELIST_IPS,
        payload: [],
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getWhitelistIP = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/whitelist-ip/${id}`, '');
    if (res?.data && res?.data?.whitelistIP) {
      const { data } = res;
      await dispatch({
        type: GET_WHITELIST_IP,
        payload: data?.whitelistIP,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addWhitelistIP = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/whitelist-ip/add', data);

    if (res?.data && res?.data?.whitelistIP_) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_WHITELIST_IP,
        payload: data?.whitelistIP_,
      });
    } else {
      await dispatch({
        type: ERROR_WHITELIST_STATE,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const editWhitelistIP = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/whitelist-ip/${id}`, data);
    if (res?.data && res?.data?.whitelistIP) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_WHITELIST_IP,
        payload: data?.whitelistIP,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteWhitelistIP = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/whitelist-ip/${id}`, '');
    if (res?.data && res?.data?.whitelistIP) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_WHITELIST_IP,
        payload: data?.whitelistIP?._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_WHITELIST_STATE,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
