import React, {
  useEffect, useState, useContext,
  useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faColumns, faTimes, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { makeSuperUserCheck } from '../../redux/emailTemplates/emailTemplatesActions';
import { ModalContext } from '../../context';
import { SmsServicesColumns } from '../../DataTableSchemas/SmsServicesColumns/SmsServicesColumns';
import SmsServiceCreate from './SmsServiceCreate/SmsServiceCreate';
import {
  selectAgentFilter,
  selectDescriptionFilter, selectIsActiveFilter, selectIsSmsServicesLoading, selectServiceNameFilter, selectSmsServices,
  selectTotalCount, 
} from '../../redux/smsServices/smsServicesSelectors';
import {
  exportUsersToFile,
  fetchIntegratedSmsServices, fetchSmsServices, setAgentFilter, setDescriptionFilter, setIsActiveFilter, setServiceNameFilter, 
} from '../../redux/smsServices/smsServicesActions';
import { useDebounce } from '../../hooks/useDebounce';
import {
  createCRMFilter, deleteCRMFilter, getCRMUsers, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { smsServicesColumns } from '../../columnsDefaultConfig/smsServicesColumns';
import styles from './styles.module.css';
import { DatatableFilters } from '../../components';
import { DownloadFile } from '../../components/DownloadFile';

function SmsServices() {
  const dispatch = useDispatch();

  const datatableFiltersRef = useRef(null);
  const { showModal, hideModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  const paginationStorageName = 'DataTable_smsServices/listing_pagination';
  const columnsStorageName = 'DataTable_smsServices/columns';
  const filtersStorageName = 'DataTable_smsServices/filters'; 
  const filterIdStorageName = 'DataTable_smsServices/filters_id';

  const smsColumnsJSON = localStorage.getItem(columnsStorageName);
  const smsStorageFiltersJSON = localStorage.getItem(filtersStorageName);

  // templates variables
  const smsServices = useSelector(selectSmsServices);
  const isDataLoading = useSelector(selectIsSmsServicesLoading);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  // filters variables
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const descriptionFilter = useSelector(selectDescriptionFilter);
  const serviceNameFilter = useSelector(selectServiceNameFilter);
  const isActiveFilter = useSelector(selectIsActiveFilter);
  const agentFilter = useSelector(selectAgentFilter);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [columnConfig, setColumnConfig] = useState(smsServicesColumns);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditSmsPermission = permissions && permissions.length > 0 && permissions.includes('edit_sms_service'); 

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create Sms Service</h3>,
      bodyContent: <SmsServiceCreate hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  useEffect(() => {
    if (crmUsers && crmUsers.length === 0) {
      dispatch(getCRMUsers());
    }
  }, [crmUsers]);

  useEffect(() => {
    localStorage.setItem(filtersStorageName, JSON.stringify({
      description: descriptionFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
      assigned_to: agentFilter,
    }));

    dispatch(fetchSmsServices({
      page,
      per_page: rowsPerPage,
      description: descriptionFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
      assigned_to: agentFilter,
    }));
  }, [page, rowsPerPage, serviceNameFilter, isActiveFilter, agentFilter]);

  useDebounce(descriptionFilter, 500, () => {
    localStorage.setItem(filtersStorageName, JSON.stringify({
      description: descriptionFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
      assigned_to: agentFilter,
    }));

    dispatch(fetchSmsServices({
      page,
      per_page: rowsPerPage,
      description: descriptionFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
      assigned_to: agentFilter,
    }));
  });

  const smsServicesCol = SmsServicesColumns({ columnConfig });

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const handleClear = () => {
    dispatch(setDescriptionFilter(''));
    dispatch(setAgentFilter(''));
    dispatch(setServiceNameFilter(''));
    dispatch(setIsActiveFilter(''));

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const {
      description = '',
      assignedTo = [],
      serviceName = '',
      status = [], 
    } = filter;

    dispatch(setDescriptionFilter(description));
    dispatch(setAgentFilter(assignedTo[0] ?? ''));
    dispatch(setServiceNameFilter(serviceName));
    dispatch(setIsActiveFilter(status[0] ?? ''));

    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      description: filters.description || '',
      assignedTo: [filters.assigned_to] || [],
      serviceName: filters.service_name || '',
      status: [filters.is_active] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        description: filters.description || '',
        assignedTo: [filters.assigned_to] || [],
        service_name: filters.service_name || '',
        is_active: filters.is_active || '',
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select at least one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (smsStorageFiltersJSON) {
      const {
        description = '',
        assigned_to = '',
        service_name = '',
        is_active = '', 
      } = JSON.parse(smsStorageFiltersJSON);
  
      dispatch(setDescriptionFilter(description));
      dispatch(setAgentFilter(assigned_to));
      dispatch(setServiceNameFilter(service_name));
      dispatch(setIsActiveFilter(is_active));
    }
  };

  const refresh = async () => {
    dispatch(fetchSmsServices({
      page,
      per_page: rowsPerPage,
      description: descriptionFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
      assigned_to: agentFilter,
    }));
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const setStoredColumnsData = () => {
    if (smsColumnsJSON) {
      const columns = JSON.parse(smsColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const handleExportUser = async (fileType) => {
    const columns = columnConfig.filter(({ selected, field }) => selected && field);

    const storageFilters = localStorage.getItem(filtersStorageName);
    const filters = JSON.parse(storageFilters);

    if (smsServices.length && columns.length) {
      const { roleId, _id: userId } = JSON.parse(localStorage.getItem('user'));

      const data = {
        roleId,
        columns,
        filters: {
          description: filters.description || '',
          assignedTo: [filters.assigned_to] || [],
          serviceName: filters.service_name || '',
          status: [filters.is_active] || [],
        }, 
        fileType,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      toast.success('Export in progress. Please wait.', {
        autoClose: 1000,
      });
      dispatch(exportUsersToFile(data));
    } else {
      toastError('There is nothing to download.');
    }
  };

  useEffect(() => {
    dispatch(makeSuperUserCheck());
    setStoredPagination();
    dispatch(fetchIntegratedSmsServices());
    setStoredColumnsData();
    setStoredFilterData();
  }, []);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Sms Services Details</h3>

        <div className="action__btn-row">
          {
            isUserHasEditSmsPermission && (
              <button 
                type="button" 
                style={{ backgroundColor: 'green' }}
                className="btn btn-default btn-lg me-1 p-1"
                onClick={handleCreateBtn}
              >
                Add new
              </button>
            )
          }

          {
            permissionName && permissionName.length > 0 && permissionName.includes('export_sms_configuration_to_file')
              ? <DownloadFile handleExport={handleExportUser} />
              : null
          }

          {crmFilters && (
            <DatatableFilters
              ref={datatableFiltersRef}
              filters={crmFilters} 
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            /> 
          )}
        
          <Dropdown className="leads-columns-dropdown ms-1">
            <Dropdown.Toggle 
              variant="" 
              className="btn-default"
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                icon={faColumns}
                size="xs"
              />
              Columns
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ul className="leads-columns-list">
                {columnConfig.map((column) => (
                  <li 
                    className="leads-columns-option"
                    key={column.name}
                    onClick={() => toggleColumn(column.name)}
                  >
                    {column.selected ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#6E7F95"
                      />
                    ) : null}
                    <span className="leads-columns-option__name">{column.name}</span>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <button 
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={handleClear}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>

          <button 
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={refresh}
          >
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>

        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={smsServicesCol}
                  data={smsServices}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isDataLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default SmsServices;
