import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ModalFilters } from '../components';
import { omitColumn } from './helper';
import { toFixed } from '../helpers/helper';

export const TradingColumnsSchema = (
  handleRevertTradeHistory,
  setSelectedHistoryOrderRow,
  setTradeHistoryPriceOpen,
  setTradeHistoryPriceClose,
  setTradeHistoryVolume,
  setTradeHistoryProfit,
  setTradeHistoryTimeOpen,
  setTradeHistoryTimeClose,
  handleShowEditTradeHistory,
  columnConfig,
  typeFilter,
  setTypeFilter,
  pairs,
  pairsFilter,
  setPairsFilter,
  tradingTypeFilter,
  setTradingTypeFilter,
  filters,
  setFilters,
) => {
  const TradingColumns = [
    {
      name: (
        <ModalFilters
          data={[{ name: 'Isolated', _id: '1' }, { name: 'Cross', _id: '0' }]}
          filters={typeFilter}
          setFilters={setTypeFilter}
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row?.marginType === 1 ? 'Isolated' : 'Cross'),
      omit: omitColumn(columnConfig, 'Type'),
    },
    {
      name: (
        <ModalFilters
          data={pairs}
          filters={pairsFilter}
          setFilters={setPairsFilter}
          tabName="Trading Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row?.pairName,
      omit: omitColumn(columnConfig, 'Trading Pair'),
      minWidth: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
          filters={tradingTypeFilter}
          setFilters={setTradingTypeFilter}
          tabName="Trading Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (row?.tradeType === 1 ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Trading Type'),
      minWidth: '200px',
    },
    {
      name: 'Trading volume',
      selector: (row) => toFixed((row?.userInvestedAmount || 0) + ((row?.leverage || 0) * (row?.userInvestedAmount || 0)), 2),
      sortable: true,
      omit: omitColumn(columnConfig, 'Trading Volume'),
    },
    {
      name: 'Profit/Loss',
      minWidth: '200px',
      selector: (row) => (row?.tradeProfitOrLoss ? toFixed(row?.tradeProfitOrLoss, 2) : 0),
      sortable: true,
      omit: omitColumn(columnConfig, 'Profit/Loss'),
    },
    {
      name: 'Commission',
      minWidth: '200px',
      cell: ({ endCommission }) => toFixed(endCommission ?? 0, 3),
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: 'Start Time',
      minWidth: '200px',
      selector: (row) => moment(row.initiatedAt || row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'Start Time'),
    },
    {
      name: 'End Time',
      minWidth: '200px',
      selector: (row) => moment(row.closedAt || row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'End Time'),
    },
    {
      name: 'Action',
      selector: (row) => (
        row?.isResolved !== false
          ? (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => {
                  setSelectedHistoryOrderRow(row);
                  setTradeHistoryPriceOpen(row.tradeStartPrice);
                  setTradeHistoryPriceClose(row.exitPrice);
                  setTradeHistoryVolume(row.qty);
                  setTradeHistoryProfit(row.tradeProfitOrLoss);
                  setTradeHistoryTimeOpen(moment(row.initiatedAt || row.createdAt).format('YYYY-MM-DD HH:mm:ss'));
                  setTradeHistoryTimeClose(moment(row.closedAt || row.updatedAt).format('YYYY-MM-DD HH:mm:ss'));
                  handleShowEditTradeHistory();
                }}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={(e) => handleRevertTradeHistory(e, row?._id)}
              >
                <FontAwesomeIcon icon={faUndo} className="header-icon text-white" />

              </button>
            </>
          )
          : <span>-</span>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];

  return TradingColumns;
};
