import React from 'react';

export function ErrorSpan({
  filter, message,
  filterMin = 1, filterMax = 3,
}) {
  return (filter !== null && (filter.length >= filterMin && filter.length < filterMax)) && (
    <span className="datatable-input__error-text clr-red">
      {message}
    </span>
  );  
}
