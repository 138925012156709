import React from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';

export function TrailingStopModal(
  {
    showTrailing,
    handleCloseTrailing,
    selectedRow,
    trailingPrice,
    setTrailingPrice,
    updateTrailing,
  },
) {
  return (
    <Modal
      className="withdrawal-modal limit-modal tp-sl-modal"
      show={showTrailing}
      centered
      onHide={handleCloseTrailing}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="buy-tabs">
          <div className="mb-4">
            <span>Trailing Stop</span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span>Entry Price</span>
            <span>{selectedRow.tradeStartPrice}</span>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <span>Liq. Price</span>
            <span className="text-green">{selectedRow.tradeEndPrice}</span>
          </div>

          <div className="stop-loss-usdt">
            <div className="d-flex justify-content-between mb-1">
              <span>
                Trailing Stop
                {selectedRow?.fromCurrency?.symbol}
              </span>
              {/* <span className="text-white">Last Traded Price</span> */}
            </div>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                placeholder="Trailing Stop"
                min="0"
                value={trailingPrice}
                onChange={(e) => setTrailingPrice(e.target.value)}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.fromCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => updateTrailing()}>
          Confirm
        </Button>
        <Button variant="danger" onClick={handleCloseTrailing}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
