import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import EditorComponent from '../../../EmailTemplates/EditorComponent/EditorComponent';
import PreviewComponent from './PreviewComponent/PreviewComponent';
import { sendEmail } from '../../../../redux/crmUser/crmUserActions';
import styles from './SendEmailModal.module.css';
import { selectEmailTemplates } from '../../../../redux/emailTemplates/emailTemplatesSelectors';
import { getEmailTemplates } from '../../../../redux/emailTemplates/emailTemplatesActions';

function SendEmailModal({ userIds, handleClose }) {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const emailTemplates = useSelector(selectEmailTemplates);

  const [subject, setSubject] = useState('Support Team');
  const [showEmailPreview, setShowEmailPreview] = useState(false);

  const [emailTemplate, setEmailTemplate] = useState(null);
  const [activeTemplateOption, setActiveTemplateOption] = useState('');
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);

  const [mailHtmlBodyStringified, setMailHtmlBodyS] = useState('');
  const [mailJsonBodyStringified, setMailJsonBodyS] = useState('{"blocks":[{"text":""}],"entityMap":{}}');

  const sanitizedHTML = DOMPurify.sanitize(mailHtmlBodyStringified);
  const HTMLWithEncodedBtn = sanitizedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  const HTMLWithProperClass = HTMLWithEncodedBtn.replace(/class="button"/g, `class="${styles.button}"`);

  const scrollToBottom = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (emailTemplate !== null) {
      setTimeout(scrollToBottom, 1);
    }
  }, [emailTemplate]);

  const handlePreview = () => {
    setShowEmailPreview((isShow) => !isShow);
    setTimeout(scrollToBottom, 1);
  };

  const customButtonTitles = [
    'firstName',
    'lastName',
    'fullName',
    'email', 
    'phone',
    'country',
    'companyName',
    'siteUrl',
  ];

  const handleSubjectChange = (event) => setSubject(event.target.value);

  const handleTempMenuOpen = () => {
    setEmailTemplate(null);
    setMailHtmlBodyS('');
    setMailJsonBodyS('{"blocks":[{"text":""}],"entityMap":{}}');
    setTimeout(scrollToBottom, 1);
  };

  const handleActiveTempChange = (selectedEmailTemp) => {
    const { value } = selectedEmailTemp;
    const selectedTemplateData = emailTemplates.find((temp) => temp._id === value);

    setActiveTemplateOption(selectedEmailTemp);
    setEmailTemplate(selectedTemplateData);
  };

  const handleSubmitEmail = async () => {
    await dispatch(sendEmail({
      userIds,
      subject,
      emailBody: mailHtmlBodyStringified,
    }));
    handleClose();
  };

  useEffect(() => {
    // fetch all active templates
    if (emailTemplates.length !== 0) {
      const data = emailTemplates.map((temp) => ({
        value: temp._id,
        label: `${temp.title} - ${temp.event}`,
      }));
      setEmailTemplateOptions(data);
    }
  }, [emailTemplates]);

  useEffect(() => {
    // fetch all active templates
    dispatch(getEmailTemplates(1, 10000, { status: 'active' }));
  }, []);

  const selectColorStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      background: '#2D3440',
      color: '#ffffff',
      border: 'none',
      boxShadow: isFocused ? 'none' : 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#ffffff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#ffffff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#2D3440',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#1C242F'
        : isSelected
          ? '#2D3440'
          : '#2D3440',
      color: '#ffffff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#1C242F',
      },
    }),
  };

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className="form-group me-2">
          <label className="control-label mb-2">Subject</label>
          <div className="input-wrapper">
            <input 
              type="text" 
              className="form-control" 
              name="emailSubject" 
              value={subject} 
              onChange={handleSubjectChange}
            />
          </div>
        </div>
        <div className="form-group me-2">
          <label className="control-label mb-2">Email Templates</label>
          <div className="input-wrapper">
            <Select
              value={activeTemplateOption}
              onChange={handleActiveTempChange}
              onMenuOpen={handleTempMenuOpen}
              options={emailTemplateOptions}
              styles={selectColorStyles}
            />
          </div>
        </div>
        {
          emailTemplate && (
            <div className="form-group me-2">
              <label className="control-label mb-2">Email Body</label>
              <div className="input-wrapper">
                <EditorComponent
                  template={emailTemplate}
                  setMailHtmlBodyS={setMailHtmlBodyS}
                  setMailJsonBodyS={setMailJsonBodyS}
                  customButtonTitles={customButtonTitles}
                />
              </div>
            </div>
          )
        }
        {
          showEmailPreview && emailTemplate && (
            <div className="form-group me-2">
              <label className="control-label mb-2">Preview</label>
              <div className="input-wrapper">
                <PreviewComponent HTMLWithProperClass={HTMLWithProperClass} />
              </div>
            </div>
          )
        }
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        {
          emailTemplate && (
            <Button variant="success" onClick={handlePreview}>
              {!showEmailPreview ? 'Preview' : 'Hide Preview' }
            </Button>
          )
        }
        <Button disabled={emailTemplate === null} variant="primary" type="submit" onClick={handleSubmitEmail}>
          Send Email
        </Button>
      </div>
    </>
  );
}

export default SendEmailModal;
