/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getIndicesSettings, updateIndicesSettingState } from '../../redux/indicesSetting/indicesSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function IndicesSettings() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [indicesSettings, setIndicesSettings] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState('');

  const allIndicesSettings = useSelector((state) => state?.indicesSetting?.indicesSettings);
  const indicesSettingsFetched = useSelector((state) => state?.indicesSetting?.indicesSettingsFetched);

  useEffect(async () => {
    setLoader(true);
    await dispatch(getIndicesSettings());
    setLoader(false);
  }, []);

  useEffect(() => {
    if (indicesSettingsFetched) {
      dispatch(updateIndicesSettingState());
    }
    setIndicesSettings(allIndicesSettings || []);
    setFilteredItems(allIndicesSettings || []);
  }, [allIndicesSettings, indicesSettingsFetched, dispatch]);

  const searchByCurrency = ({ target: { value } }) => {
    const searchText = value.toLowerCase();
    const searchData = indicesSettings.filter(
      (row) => (
        row.symbol?.toLowerCase().includes(searchText)
        || row.alias?.toLowerCase().includes(searchText)
        || row.secondaryCurrency?.symbol?.toLowerCase().includes(searchText)
      ),
    );
    setFilterCurrency(value);
    setFilteredItems(searchData);
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
            </div>
            <div>
              <h4 className="mb-3 text-capitalize">Indices Settings</h4>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
              <div className="table-responsive">
                <table>
                  <tr>
                    <th>Symbol</th>
                    <th>Alias</th>
                    <th>Base Currency</th>
                    <th>Leverage</th>
                    <th>Lot Step</th>
                    <th>Lot Size</th>
                    <th>Actions</th>
                  </tr>
                  <tr className="search-holder">
                    <th>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Search"
                          name="filterCurrency"
                          value={filterCurrency}
                          onChange={(e) => searchByCurrency(e)}
                        />
                      </div>
                    </th>
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                    <th />
                  </tr>
                  {filteredItems?.length ? filteredItems.map((currentIndicesSetting) => (
                    <tr key={`indicesSetting-${currentIndicesSetting._id}`}>
                      <td>{currentIndicesSetting.symbol}</td>
                      <td>{currentIndicesSetting.alias}</td>
                      <td>{currentIndicesSetting.secondaryCurrency?.symbol}</td>
                      <td>
                        1:
                        {currentIndicesSetting.leverage}
                      </td>
                      <td>{currentIndicesSetting.lotStep || ''}</td>
                      <td>{currentIndicesSetting.lotSize || ''}</td>
                      <td>
                        <div className="action-btn">
                          <Link className="btn-complete" to={`/edit-indices-setting/${currentIndicesSetting?._id}`}>
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                    : (
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td>No Record Found</td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    )}
                </table>
              </div>
            </div>
          </div>
        </div>
      )
  );
};

export default IndicesSettings;
