import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ModalContext } from '../../../../context';
import { getOptions } from '../../../../redux/staking/stakingActions';
import { getUserStakingSettings, deleteUserStakingSetting } from '../../../../redux/userStaking/userStakingActions';
import { showAllCurrencies } from '../../../../redux/currency/currencyActions';
import StakingModal from './StakingModal';

export default function StakingDataTable({ id = '' }) {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  const stakingOptions = useSelector((state) => state.staking.options);
  const stakings = useSelector((state) => state.userStakings);

  const deleteSetting = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteUserStakingSetting(id));
        }
      });
  };

  const columns = useMemo(() => {
    const columns = [
      {
        name: 'Currency',
        cell: ({ currency: { name } }) => name,
      },
      {
        name: 'Minimum Deposit',
        cell: ({ minDeposit }) => minDeposit,
      },
    ];

    stakingOptions.forEach((option) => {
      columns.push({
        name: option.label,
        cell: ({ options }) => {
          const currenctOption = options.find(({ _id }) => _id === option._id);

          return `${currenctOption?.percent || 0}%`;
        },
      });
    });

    columns.push(
      {
        name: 'Actions',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (setting) => (
          <>
            <button 
              type="button" 
              className="btn btn-warning btn-sm me-1 p-1" 
              onClick={() => showModal({
                headerContent: <h3>EDIT STAKING</h3>,
                bodyContent: <StakingModal userId={id} setting={setting} />,
              })}
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <button 
              type="button" 
              className="btn btn-danger btn-sm" 
              onClick={() => deleteSetting(setting._id)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
  
        ),
      },
    );

    return columns;
  }, [stakingOptions]);

  useEffect(() => {
    if (!id) return;

    Promise.all([
      dispatch(getUserStakingSettings(id)),
      dispatch(getOptions()),
      dispatch(showAllCurrencies()),
    ]);
  }, [id]);

  return (
    <div>
      <button
        type="button"
        className="btn btn-default icon-btn btn-bg-green"
        onClick={() => {
          showModal({
            headerContent: <h3>ADD STAKING</h3>,
            bodyContent: <StakingModal userId={id} isCreate />,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
        Add
      </button>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={stakings}
          theme="solarizedd"
        />
      </div>
    </div>
  );
}
