export const nonNegativeAmount = (amount) => Math.max(amount, 0);

export const addShift = (close, settings) => {
  if (!settings) return close;

  const { shift, isPositive } = settings;

  const setPrecisionNumber = (closeFrom) => {
    const closeFromNum = Number(closeFrom);
    let precisionNumber = 1;
          
    if (closeFromNum < 1) {
      precisionNumber = 4;
    } else if (closeFromNum >= 1 && closeFromNum < 1000) {
      precisionNumber = 3;
    } else if (closeFromNum >= 1000 && closeFromNum < 10000) {
      precisionNumber = 2;
    } else if (closeFromNum >= 10000) {
      precisionNumber = 1;
    }
          
    return precisionNumber;
  };
    
  const addZerosToRightPartStart = (rightPart, difference) => {
    for (let i = 0; i < difference; i++) {
      rightPart = `0${rightPart}`;
    }
      
    return rightPart;
  };
  
  if (close.indexOf('.') === -1) close += '.000000';
    
  const shiftStr = String(shift);
  const [from, to] = close.split('.');
  const cuttedTo = to.substring(0, setPrecisionNumber(from));
    
  let shiftRightPart = shiftStr.substring(shiftStr.length - cuttedTo.length);
  if (cuttedTo.length > shiftRightPart.length) {
    shiftRightPart = addZerosToRightPartStart(shiftRightPart, cuttedTo.length - shiftRightPart.length);
  } 
  const shiftLeftPart = shiftStr.substring(0, shiftStr.length - shiftRightPart.length) || '0';
  const convertedShift = `${shiftLeftPart}.${shiftRightPart}`;
    
  return isPositive ? Number(close) + Number(convertedShift) : Number(close) - Number(convertedShift);
};

export const addShiftToPrice = (price, setting) => {
  const numberPrice = !Number.isNaN(Number(price)) ? Number(price) : 0;

  if (!setting) return numberPrice;

  const { isPositive } = setting;
  const multiplier = isPositive ? 1 : -1;
  const shift = setting.shift * multiplier;

  return ((shift / 100) * numberPrice) + numberPrice;
};

export const toFixed = (num, fixed) => {
  if (!num || typeof num === 'undefined' || Number.isNaN(Number(num))) return 0;

  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);

  return Number(num.toString().match(re)[0]);
};
