import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { deletedUsers, recoverUser } from '../../redux/users/userActions';

function DeletedUsers() {
  const dispatch = useDispatch();

  const deletedData = useSelector((state) => state.users.delUsers);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const success = useSelector((state) => state.users.success);

  const userData = deletedData?.users;

  useEffect(() => {
    dispatch(deletedUsers());
  }, [success]);

  const recoverAction = (id) => {
    Swal.fire({
      title: 'Are you sure want to Recover it?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(recoverUser(id));
      }
    });
  };

  return (
    <>
      {/* <div className="col-lg-9 col-md-8"> */}
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h3>Deleted Users Details</h3>
          <table className="table mt-3 table-responsive table">
            <thead className="table_head">
              <tr>
                <th>Name</th>
                <th>User Name</th>
                <th>Email</th>
                {permissionName && permissionName.length > 0 && permissionName.includes('recover_deleted_user')
                  ? <th>Action(s)</th>
                  : null}
              </tr>
            </thead>
            <tbody>
              {userData && userData.length ? userData.map((data) => (
                <tr key={data?.users._id}>
                  <td>{data?.users.firstName}</td>
                  <td>{data?.users.username}</td>
                  <td>{data?.users.email}</td>
                  <td>
                    {permissionName && permissionName.length > 0 && permissionName.includes('recover_deleted_user')
                      ? <button type="button" className="btn btn-warning me-2" onClick={() => recoverAction(data?.users._id)}>Recover</button>
                      : null}
                  </td>
                </tr>
              )) : <span className="pt-2">No Record Found!</span>}
            </tbody>
          </table>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default DeletedUsers;
