import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getStaking, editStaking, updateStakingState } from '../../redux/staking/stakingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function EditStaking() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [staking, setStaking] = useState('');

  const error = useSelector((state) => state.staking?.error);
  const stakingData = useSelector((state) => state.staking?.staking);
  const stakingFetched = useSelector((state) => state.staking?.stakingFetched);
  const stakingEditted = useSelector((state) => state.staking?.stakingEditted);

  const {
    register, handleSubmit, control, reset, formState: { errors },
  } = useForm();
  
  useEffect(() => {
    reset();
    setLoader(true);
    dispatch(getStaking(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(stakingData)?.length > 0 && stakingFetched) {
      setStaking(stakingData);
      reset(stakingData);
      dispatch(updateStakingState());
      setLoader(false);
    }
  }, [stakingData, stakingFetched]);

  useEffect(() => {
    if (stakingEditted) {
      dispatch(updateStakingState());
      setLoader(false);
      history.goBack();
    }
  }, [stakingEditted]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateStakingState());
    }
  }, [error]);

  const setNewValueToOptions = (formData = {}) => {
    if (!formData || !formData.options) return [];

    const options = formData.options.map((option) => {
      const updatedPercent = formData[option._id] || 0;

      return { ...option, percent: Number(updatedPercent) };
    });

    return options;
  };

  const handleEditStakingCurrency = (formData) => {
    setLoader(true);

    const data = {
      options: setNewValueToOptions(formData),
      minDeposit: formData.minDeposit || 0,
    };

    dispatch(editStaking(id, data));
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>{`Edit Settings For ${staking?.currency ? `${staking?.currency?.name} ${staking?.currency?.symbol}` : ''} Staking`}</h3>
          <form onSubmit={handleSubmit(handleEditStakingCurrency)}>
            <div className="form-group col-md-12">
              <label className="control-label">Min Deposit</label>
              <input
                type="number"
                className="form-control"
                {...register('minDeposit')}
                name="minDeposit"
                control={control}
                min="0"  
                step="any"
                defaultValue={staking?.minDeposit}
              />
              {errors?.minDeposit && <span className="errMsg">{errors.minDeposit.message}</span>}
            </div>
            { staking.options?.map((option) => (
              <div className="form-group col-md-12 pt-2" key={option._id}>
                <label className="control-label">{option.label}</label>
                <input
                  type="number"
                  className="form-control"
                  {...register(`${option._id}`)}
                  name={option._id}
                  control={control}
                  defaultValue={option.percent}
                />
                {errors[`${option._id}`] && <span className="errMsg">{errors[`${option._id}`].message}</span>}
              </div>
            ))}
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default EditStaking;
