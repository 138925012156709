import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faCircleInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  resolveDepositTransaction,
  revertTransaction,
} from '../redux/users/userActions';
import {
  updateIsReal, deleteTransaction, getTransactionsByQuery, 
} from '../redux/transactions/transactionActions';
import { getCurrencyRates } from '../redux/currencyRate/currencyRateActions';
import { formatDate, setTransactionActionColumn } from './helper';
import { getRateInUsdt } from '../helpers/getRateInUsdt';
import { toFixed } from '../helpers/helper';

export const DepositDetailColumns = (page = 1, limit = 25) => {
  const dispatch = useDispatch();

  const [activeInfo, setActiveInfo] = useState(null);

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const currencyRates = useSelector((state) => state.currencyRates);

  const handleDeleteTransaction = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) dispatch(deleteTransaction(id));
      });
  };

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${
        status === 1 ? 'Approve' : 'Decline'
      } it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId,
          crmUserId: loginUserId,
          resolvedStatus: status,
          additionalInfo: result.value ? result.value : '',
        };

        return Promise.resolve(
          dispatch(resolveDepositTransaction(rowId, data, false)),
        )
          .then(() => dispatch(getTransactionsByQuery({
            page, limit, query: {}, isDeleted: false, 
          })))
          .catch((error) => {
            throw new Error(error, 'resolveCurrentDepositTransaction');
          });
      }
      return null;
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = { userId };
        return Promise.resolve(dispatch(revertTransaction(rowId, data, false)))
          .then(() => dispatch(getTransactionsByQuery({
            page, limit, query: {}, isDeleted: false, 
          })))
          .catch((error) => {
            // console.log(error, 'revertCurrentwithdrawTransaction');
          });
      }
      return null;
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  useEffect(() => {
    dispatch(getCurrencyRates('USD'));
  }, []);

  const columns = [
    {
      name: 'Transaction Time',
      selector: ({ createdAt }) => formatDate(new Date(createdAt)),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'UID',
      selector: ({ _id }) => (
        <CopyToClipboard text={_id}>
          <span>
            {_id?.slice(0, 4)}
            ...
            {_id?.slice(_id.length || 0 - 4, _id.length)}
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => copyReferral()}
              className="ms-2"
            />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Type',
      selector: ({ transactionType }) => (
        <span>{transactionType ? 'Withdraw' : 'Deposit'}</span>
      ),
      sortable: true,
    },
    {
      name: 'Asset',
      selector: ({ currency }) => currency,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: ({ amount }) => amount,
      sortable: true,
    },
    {
      name: 'User Name',
      selector: ({ user }) => (permissionName?.length > 0 && permissionName.includes('edit_user') ? (
        <Link
          to={`/user-detail/${user?._id}`}
          className="text-decoration-none truncate-text"
        >
          {`${user?.firstName} ${user?.lastName}`}
        </Link>
      ) : (
        <span>
          {' '}
          {`${user?.firstName} ${user?.lastName}`}
        </span>
      )),
      sortable: true,
    },
    {
      name: 'Manager',
      minWidth: '160px',
      selector: ({ user }) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          user?.hasOwnProperty('assignedTo')
          && Object.keys(user?.assignedTo).length
        ) {
          return permissionName
            && permissionName.length
            && permissionName.includes('edit_crm_user') ? (
              <Link
                to={`/edit-admin/${user?.assignedTo?._id}`}
                className="text-decoration-none"
              >
                {`${user?.assignedTo?.firstName} ${user?.assignedTo?.lastName}`}
              </Link>
            ) : (
              <span>{`${user?.assignedTo?.firstName} ${user?.assignedTo?.lastName}`}</span>
            );
        }
        return '-';
      },
      sortable: true,
    },
    {
      name: 'In USD',
      selector: ({ amount }) => parseFloat(amount).toFixed(2),
      cell: ({ currency, amount }) => {
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return usdtValue ? toFixed(usdtValue, 2) : '-';
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: ({ _id, isResolved, additionalInfo }) => (
        <>
          {isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <span>
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setActiveInfo(activeInfo === _id ? null : _id);
              }}
            />

            {activeInfo === _id ? (
              <div className="withdrawsInfoModal">
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                  onClick={() => {
                    setActiveInfo(null);
                  }}
                />

                {additionalInfo ? (
                  <>
                    <h6>Additional Info:</h6>
                    <p>{additionalInfo}</p>
                  </>
                ) : null}
              </div>
            ) : null}
          </span>
        </>
      ),
    },
    {
      name: 'Real/Fake',
      minWidth: '160px',
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
      sortable: true,
    },
    {
      name: 'Action(s)',
      minWidth: '300px',
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        false,
        dispatch,
      ),
    },
  ];
  return columns;
};
