import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { omitColumn } from './helper';
import { ModalFilters } from '../components';
import { RangeModalFilter } from '../pages/PendingWithdraws/modalFilters';
import { addShiftToPrice, toFixed } from '../helpers/helper';

export const PendingOrderColumns = (
  setSelectedRow,
  setFutureStartPrice,
  handleShowFutureStartPrice,
  avbl,
  userOrders,
  currentMarketPrice,
  handleShowTPSL,
  setTP,
  setSL,
  handleShowTrailing,
  setTrailingPrice,
  setCoinAmount,
  setOrderRate,
  handleShowLimit,
  handleShowMarket,
  setStopRate,
  rates,
  setOrderId,
  setFutureOrderId,
  handleShowFuture,
  columnConfig,
  coinsPairs,
  coinPairsFilter,
  setCoinPairsFilter,
  amountRangeFilter,
  setAmountRangeFilter,
  filters,
  setFilters,
) => {
  // Future Active Orders
  const getInnitialMargin = (row) => {
    const val = ((parseFloat(row.qty) * parseFloat(row.tradeStartPrice)) / parseFloat(row.leverage)); // Initial margin
    return (val && !Number.isNaN(val) ? val : 0);
  };

  const getPositionMargin = (row) => {
    // Qty / (entry price x leverage) /  ( Bankruptcy Price = Entry Price * (Leverage / Leverage + 1) )
    const val = toFixed(
      getInnitialMargin(row) // Initial margin
        + (getInnitialMargin(row) * 0.03), // 3%  of  Initial margin
      2,
    );
    
    return `${val && !Number.isNaN(val) ? val : 0} ${row?.fromCurrency?.symbol}`;
  };

  const getUnrealizedPnL = (row) => {
    const marketPrice = currentMarketPrice ? currentMarketPrice?.find((line) => line.symbol === row.pairName).markPrice : 0;
    const rowPnLRate = row.setting ? addShiftToPrice(marketPrice, row.setting) : parseFloat(marketPrice || 0);
  
    const val = row?.tradeType === 1 // buy
      ? parseFloat(row?.qty) * (parseFloat(rowPnLRate) - parseFloat(row?.tradeStartPrice))
      : parseFloat(row?.qty) * (parseFloat(row?.tradeStartPrice) - parseFloat(rowPnLRate)); // sell
  
    return val && !Number.isNaN(val) ? val : 0;
  };

  const columns = [
    {
      name: (
        <ModalFilters
          data={coinsPairs}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Contracts" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => (
        <>
          <b>
            {row.pairName}
          </b>
          <div>
            {row.marginType === 0 ? 'Cross' : 'Isolated'}
            <span className={row.tradeType === 1 ? 'text-green' : 'text-red'}>
              {row.leverage}
              x
            </span>
          </div>
        </>
      ),
      omit: omitColumn(columnConfig, 'Contracts'),
    },
    {
      name: 'Qty',
      selector: (row) => `${parseFloat(row.qty).toFixed(2)} ${row?.toCurrency?.symbol}`,
      sortable: true,
      omit: omitColumn(columnConfig, 'QTY'),
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Value" 
          value={amountRangeFilter} 
          setValue={setAmountRangeFilter}
          filters={filters}
          setFilters={setFilters}
          filedName="amountRange"
        />
      ),
      selector: (row) => `${parseFloat(parseFloat(row.qty) * parseFloat(row.tradeStartPrice)).toFixed(2)} ${row?.fromCurrency?.symbol}`,
      omit: omitColumn(columnConfig, 'Value'),
    },
    {
      name: 'Entry Price',
      minWidth: '200px',
      selector: (row) => (
        <span>
          {row?.tradeStartPrice
            ? (
              <>
                {`${parseFloat(row.tradeStartPrice).toFixed(2)} ${row?.fromCurrency?.symbol}`}
                <button type="button" onClick={() => { setSelectedRow(row); setFutureStartPrice(row.tradeStartPrice); handleShowFutureStartPrice(); }} className="btn graph-table-btns ms-2">
                  <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
                </button>
    
              </>
            )
            : '-'}
        </span>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Entry Price'),
    },
    {
      name: 'Liq. Price',
      selector: (row) => (
        <>
          <span id={`${row?._id}liq`}>
            {toFixed(row.tradeEndPrice, 2)}
          </span>
          <span>
            {` ${row?.fromCurrency?.symbol}`}
          </span>
        </>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'LIQ Price'),
    },
    {
      name: 'Position Margin',
      selector: (row) => getPositionMargin(row),
      sortable: true,
      omit: omitColumn(columnConfig, 'Position Margin'),
    },
    // {
    //   name: 'Unrealized P&L (%)',
    //   selector: (row) => (
    //     <span className={`${getUnrealizedPnL(row) >= 0 ? 'text-green' : 'text-red'} d-flex flex-column`}>
    //       <span id={`${row?._id}pnl`}>
    //         {toFixed(getUnrealizedPnL(row), 2)}
    //       </span>
    //       <span>
    //         {` ${row?.fromCurrency?.symbol}`}
    //       </span>
    //       <span>
    //         {toFixed(getUnrealizedPnL(row) / (row.tradeType === 1 ? toFixed(row.userInvestedAmount, 2) : toFixed((row.userInvestedAmount * row.tradeStartPrice), 2)), 2)}
    //         %
    //       </span>
    //       <span>
    //         {`${toFixed(getUnrealizedPnL(row), 2)} USD`}
    //       </span>
    //     </span>
    //   ),
    //   sortable: true,
    //   omit: omitColumn(columnConfig, 'Unrealized P&L'),
    // },
    {
      name: 'TP/SL',
      selector: (row) => (
        <span>
          {row.tpsl
            ? (
              <>
                {row.takeProfitPrice ? `${row.takeProfitPrice} ${row?.fromCurrency?.symbol}` : '-'}
                /
                {row.stopLossPrice ? `${row.stopLossPrice} ${row?.fromCurrency?.symbol}` : '-'}
                <button type="button" onClick={() => { setSelectedRow(row); handleShowTPSL(); setTP(row.takeProfitPrice); setSL(row.stopLossPrice); }} className="btn graph-table-btns ms-2">
                  <FontAwesomeIcon icon={faPencil} className="header-icon" />
                </button>
              </>
            )
            : (
              <button
                type="button"
                onClick={() => {
                  setSelectedRow(row); handleShowTPSL();
                }}
                className="btn graph-table-btns text-white"
              >
                + Add
              </button>
            )}
        </span>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'TP/SL'),
    },
    {
      name: 'Trailing Stop',
      selector: (row) => (
        <span>
          {row.tradeTrailingPrice
            ? (
              <>
                {row.tradeTrailingPrice ? `${row.tradeTrailingPrice} ${row?.fromCurrency?.symbol}` : '-'}
                <button type="button" onClick={() => { setSelectedRow(row); handleShowTrailing(); setTrailingPrice(row.tradeTrailingPrice); }} className="btn graph-table-btns ms-2">
                  <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
                </button>
    
              </>
            )
            : (
              <button
                type="button"
                onClick={() => {
                  setSelectedRow(row); handleShowTrailing();
                }}
                className="btn btn-success btn-sm me-1 p-1"
              >
                + Add
              </button>
            )}
        </span>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Trailing Stop'),
    },
    {
      name: 'Close By',
      minWidth: '200px',
      selector: (row) => (
        <>
          {row?.status === 0
            ? (
              <>
                <button type="button" onClick={() => { setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage)); setSelectedRow(row); setOrderRate(row.tradeStartPrice); handleShowLimit(); }} className="btn btn-success btn-sm me-1 p-1">Limit</button>
                <button type="button" onClick={() => { setCoinAmount(parseFloat(row.qty) / parseFloat(row.leverage)); setSelectedRow(row); setOrderRate(row.tradeStartPrice); handleShowMarket(); }} className="btn btn-success btn-sm me-1 p-1">Market</button>
              </>
            )
            : null}
          <button type="button" onClick={() => { setStopRate(rates ? rates?.find((line) => line.symbol === row.pairName).markPrice : 0); setOrderId(row?._id); setFutureOrderId(row?._id); handleShowFuture(); }} className="btn btn-danger btn-sm me-1 p-1">Stop</button>
        </>
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'Close By'),
    },
  ];

  return columns;
};
