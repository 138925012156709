import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import { getForexCurrencies, updateForexCurrencyState } from '../../redux/forexCurrency/forexCurrencyActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { ModalContext } from '../../context';
import { EditForexCurrency } from './components';

function ForexCurrencies() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [loader, setLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchText, setSearchText] = useState('');

  const { forexCurrencies: allForexCurrencies, forexCurrenciesFetched } = useSelector((state) => state?.forexCurrency);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isUserCanSeeStock = permissionName && permissionName.includes('stock_management');

  useEffect(async () => {
    setLoader(true);
    await dispatch(getForexCurrencies());
  }, []);

  useEffect(() => {
    if (forexCurrenciesFetched) {
      dispatch(updateForexCurrencyState());
      setLoader(false);
    }

    setFilteredItems(allForexCurrencies || []);
  }, [allForexCurrencies, forexCurrenciesFetched]);

  const searchByCurrency = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    const searchData = allForexCurrencies.filter(
      (row) => (
        row?.pairSymbol?.toLowerCase()?.includes(searchText.toLowerCase())
                || row?.pairAlias?.toLowerCase()?.includes(searchText.toLowerCase())
                || row?.primaryCoin?.toLowerCase()?.includes(searchText.toLowerCase())
      ),
    );
    setFilteredItems(searchData);
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h4 className="mb-3 text-capilatize">Forex Currency Pairs</h4>
            <div className="action-btns mb-5">
              {isUserCanSeeStock && (
                <Link to="/stock-setting" className="m-1">
                  <button className="btn btn-default" type="button">
                    <FontAwesomeIcon icon={faMoneyCheck} />
                    {' '}
                    Stock
                  </button>
                </Link>
              )}
              <Link to="/commodities-setting" className="m-1">
                <button className="btn btn-default" type="button">
                  <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                  {' '}
                  Commodities
                </button>
              </Link>
              <Link to="/indices-setting" className="m-1">
                <button className="btn btn-default" type="button">
                  <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                  {' '}
                  Indices
                </button>
              </Link>
              <Link to="/crypto-setting" className="m-1">
                <button className="btn btn-default" type="button">
                  <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                  {' '}
                  Crypto
                </button>
              </Link>
              <Link to="/metals-setting" className="m-1">
                <button className="btn btn-default" type="button">
                  <FontAwesomeIcon icon={faMoneyCheck} className="me-2" />
                  {' '}
                  Metals
                </button>
              </Link>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Symbol</th>
                      <th>Alias</th>
                      <th>Base Currency</th>
                      <th>Leverage</th>
                      <th>Lot Step</th>
                      <th>Lot Size</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="search-holder">
                      <td colSpan="7">
                        <div className="input-group">
                          <input
                            type="text"
                            placeholder="Search"
                            name="filterCurrency"
                            value={searchText}
                            onChange={searchByCurrency}
                          />
                        </div>
                      </td>
                    </tr>
                    {filteredItems?.length > 0 ? (
                      filteredItems.map((currentForexCurrency) => (
                        <tr key={`forexCurrency-${currentForexCurrency?._id}`}>
                          <td>{currentForexCurrency?.pairSymbol}</td>
                          <td>{currentForexCurrency?.pairAlias}</td>
                          <td>{currentForexCurrency?.secondaryCurrency?.symbol}</td>
                          <td>{currentForexCurrency?.leverage}</td>
                          <td>{currentForexCurrency?.lotStep || ''}</td>
                          <td>{currentForexCurrency?.lotSize || ''}</td>
                          <td>
                            <div className="action-btn">
                              <button 
                                type="button"
                                className="btn-complete"
                                onClick={() => showModal({ 
                                  bodyContent: <EditForexCurrency currencyData={currentForexCurrency} />, 
                                  headerContent: <h3>Edit Forex Currency</h3>, 
                                })}
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No Record Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )
  );
}

export default ForexCurrencies;
