export const crmUsersColumns = [
  {
    name: 'User ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'Full Name',
    field: 'firstName,lastName',
    selected: true,
  },
  {
    name: 'Email',
    field: 'email',
    selected: true,
  },
  {
    name: 'Role',
    field: 'userRole.name',
    selected: true,
  },
  {
    name: 'Registration Date',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Online',
    field: 'isLogin',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
