export const selectSpotOrders = (state) => state.spotOrder.spotOrders ?? [];
export const selectSpotOrdersPagination = (state) => state.spotOrder.pagination ?? {};
export const selectSpotOrdersPairs = (state) => {
  const spotPairs = state.spotOrder.spotPairs ?? [];
  const pairs = spotPairs.map((pair) => ({
    _id: pair,
    name: pair,
  }));
  return pairs;
};
