import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone, faUndo } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import currencyFormatter from 'currency-formatter';
import Swal from 'sweetalert2';
import { resolveDepositTransaction, revertTransaction } from '../redux/users/userActions';
import { displayAffiliateDeposits } from '../redux/affiliate/affiliateActions';

export const AffiliateDepositColumns = () => {
  const dispatch = useDispatch();
  const currencyRates = useSelector((state) => state.currencyRates);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const getRateInUsdt = (coin_symbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const total_in_usdt = parseFloat(parseFloat((1 / currencyRates[coin_symbol === 'USDT' ? 'USD' : coin_symbol])) * parseFloat(amount));

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(total_in_usdt)) {
        return total_in_usdt;
      }

      return null;
    }

    return parseFloat(amount);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const resolveCurrentDepositTransaction = async (rowId, userId, status) => {
    Swal.fire({
      title: `Are you sure you want to ${status === 1 ? 'Approve' : 'Decline'} it?`,
      input: 'textarea',
      inputPlaceholder: 'Enter information/comment...',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    // eslint-disable-next-line consistent-return
    }).then((result) => {
      if (result.isConfirmed === true) {
        const userIdData = localStorage.getItem('userId');
        const loginUserId = JSON.parse(userIdData);

        const data = {
          userId, crmUserId: loginUserId, resolvedStatus: status, additionalInfo: result.value ? result.value : '',
        };

        return Promise.resolve(
          dispatch(resolveDepositTransaction(rowId, data, false)),
        )
          .then(
            () => {
              const loginData = localStorage.getItem('user');
              // eslint-disable-next-line no-shadow
              const data = JSON.parse(loginData);
              const roleId = data?.roleId;
              const uid = data?._id;

              // eslint-disable-next-line no-undef
              dispatch(displayAffiliateDeposits(roleId, uid, id));
            },
          ).catch((error) => {
            // console.log(error, 'resolveCurrentDepositTransaction');
          });
      }
    });
  };

  const handleRevertTransaction = async (rowId, userId) => {
    Swal.fire({
      title: 'Are you sure you want to Revert the transaction?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    // eslint-disable-next-line consistent-return
    }).then((result) => {
      if (result.isConfirmed === true) {
        const data = { userId };
        return Promise.resolve(
          dispatch(revertTransaction(rowId, data, false)),
        )
          .then(
            () => {
              const loginData = localStorage.getItem('user');
              // eslint-disable-next-line no-shadow
              const data = JSON.parse(loginData);
              const roleId = data?.roleId;
              const uid = data?._id;

              // eslint-disable-next-line no-undef
              dispatch(displayAffiliateDeposits(roleId, uid, id));
            },
          ).catch((error) => {
            // console.log(error, 'revertCurrentDepositTransaction');
          });
      }
    });
  };

  const depositColumns = [
    {
      name: 'UID',
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice(row?._id.length || 0 - 4, row?._id.length)}
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => copyReferral()}
              className="ms-2"
            />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Transaction Time',
      selector: (row) => formatDate(new Date(row?.createdAt)),
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Type',
      selector: (row) => (
        <span>{row.transactionType ? 'Withdraw' : 'Deposit'}</span>
      ),
      sortable: true,
    },
    {
      name: 'Asset',
      selector: (row) => row?.currency,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row?.amount,
      sortable: true,
    },
    {
      name: 'User Name',
      minWidth: '160px',
      selector: (row) => (
        <Link
          to={`/user-detail/${row?.user?._id}`}
          className="text-decoration-none"
        >
          {`${row?.user?.firstName} ${row?.user?.lastName}`}
        </Link>
      ),
      sortable: true,
    },
    {
      name: 'Manager',
      minWidth: '160px',
      selector: (row) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          row?.user?.hasOwnProperty('assignedTo')
          && Object.keys(row?.user?.assignedTo)?.length > 0
        ) {
          return (
            <Link
              to={`/edit-admin/${row?.user?.assignedTo?._id}`}
              className="text-decoration-none"
            >
              {`${row?.user?.assignedTo?.firstName} ${row?.user?.assignedTo?.lastName}`}
            </Link>
          );
        }
        return '-';
      },
      sortable: true,
    },
    {
      name: 'In USD',
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      cell: (row) => {
        const usdtValue = getRateInUsdt(row?.currency, row?.amount);
        if (usdtValue) {
          return currencyFormatter.format(usdtValue.toFixed(2), {
            code: 'USD',
          });
        }

        return '-';
      },
      sortable: true,
    },
    {
      name: 'Deposit Type',
      minWidth: '160px',
      selector: (row) => (row?.transactionRequestBy === true ? 'Real' : 'Fake'),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => (
        row?.isResolved === 0 ? (
          <span className="badge rounded-pill bg-warning">Pending</span>
        ) : row?.isResolved === 1 ? (
          <span className="badge rounded-pill bg-success">Completed</span>
        ) : (
          <span className="badge rounded-pill bg-danger">Declined</span>
        )
      ),
    },
    {
      name: 'Action(s)',
      minWidth: '200px',
      cell: (row) => (
        row?.isResolved === 0 ? (
          <>
            <button
              type="button"
              className="btn btn-success btn-sm me-1 p-1"
              onClick={() => resolveCurrentDepositTransaction(row?._id, row?.userId, 1)}
            >
              Accept
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => resolveCurrentDepositTransaction(row?._id, row?.userId, 2)}
            >
              Decline
            </button>
          </>
        ) : row?.isResolved === 1 ? (
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => handleRevertTransaction(row?._id, row?.userId)}
          >
            <FontAwesomeIcon
              icon={faUndo}
              className="header-icon text-white"
            />
          </button>
        ) : (
          <span>-</span>
        )
      ),
    },
  ];
  return depositColumns;
};
