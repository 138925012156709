import React, {
  useState, useEffect, useContext, 
} from 'react';
import {
  Dropdown, Button, Row, Col, 
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalContext } from '../../../../context';
import { createLeverageSetting, updateLeverageSetting } from '../../../../redux/leverageSetting/leverageSettingActions';
  
export default function LeverageSettingModal({ isCreate = false, setting = {}, userId = '' }) {
  const dispatch = useDispatch();
  const pairs = useSelector((state) => state.cryptoPairs.pairNames);
  const { hideModal } = useContext(ModalContext);

  const [selectedPair, setSelectedPair] = useState('');
  const [leverage, setLeverage] = useState(5);

  useEffect(() => {
    if (!Object.keys(setting).length) return;

    setSelectedPair(setting.pairName);
    setLeverage(setting.leverage);
  }, [setting]);

  const submit = () => {
    if (!selectedPair) {
      toast.warning('Select Pair');

      return;
    }

    if (leverage < 2 || leverage > 100) {
      toast.warning('Leverage can not be less then 2 and more then 100');
  
      return;
    }

    if (isCreate) {
      const data = {
        pairName: selectedPair,
        leverage,
        userId,
      };
      
      dispatch(createLeverageSetting(data));
    } else {
      dispatch(updateLeverageSetting(setting._id, { leverage }));
    }

    hideModal();
  };

  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair Name</label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" disabled={!isCreate} variant="">{selectedPair || 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {pairs.map((pair) => (
                  <Dropdown.Item key={pair} onClick={() => setSelectedPair(pair)}>{pair}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Leverage</label>
            <div className="input-wrapper">
              <input 
                type="number" 
                className="mb-3 form-control" 
                value={leverage}
                step="1"
                min="2"
                max="100"
                onChange={({ target: { value } }) => setLeverage(value)}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" onClick={submit}>Save</Button>
        </Col>
      </Row>
    </div>
  );
}
