import {
  GET_ACCOUNT_TYPES,
} from './accountTypesTypes';

const initialState = {
  accountTypes: [],
  accountTypesFetched: false,
};

export const accountTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_TYPES:
      return {
        ...state,
        accountTypes: action.payload,
        accountTypesFetched: true,
      };
    default:
      return state;
  }
};
