export const variantOptions = [
  { key: 'default', value: 'default', label: 'Default' },
  { key: 'warning', value: 'warning', label: 'Warning' },
  { key: 'error', value: 'error', label: 'Error' },
];

export const optionsAlias = [
  // { key: 'Alert message', value: 'Alert message', label: 'Alert Message' },
  // { key: 'Self User Registration Assign', value: 'Self User Registration Assign', label: 'Self User Registration Assign' },
  { key: 'Monthly inactivity fee', value: 'Monthly inactivity fee', label: 'Monthly Inactivity Fee' },
];

export const handlePercentageValue = (value) => {
  let sanitizedValue = value.replace(/[^\d]/g, '');

  sanitizedValue = sanitizedValue.replace(/^0+/, '');

  const numberValue = parseInt(sanitizedValue, 10);
  if (Number.isNaN(numberValue) || numberValue < 0) {
    return '0';
  } if (numberValue > 100) {
    return '100';
  }

  return numberValue.toString();
};


export const handleSelectOptions = (setOptions, setFormData, valueKey, nameKey) => (selectedOptions) => {
  setOptions(selectedOptions);
  const ids = selectedOptions.map(({ value }) => value);
  const names = selectedOptions.map(({ label }) => label);
  setFormData((prevValue) => ({
    ...prevValue,
    [valueKey]: ids,
    [nameKey]: names,
  }));

  // General Refactored!! Test it in Future!!!
  // setOptions(selectedOptions);
  // const managerIdToName = valueKey.map(({ label, value }) => ({ label, value }))
  // setFormData((prevValue) => ({
  //   ...prevValue,
  //   [valueKey]: ids,
  //   [nameKey]: names,
  // }));
};
