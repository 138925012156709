import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ModalFilters } from '../components';
import { RangeModalFilter } from '../pages/Transactions/modalFilters';
import { omitColumn } from './helper';
import { ModalContext } from '../context';
import { EditOrderModal } from '../pages/OrdersHistory/EditOrderModal';
import { toFixed } from '../helpers/helper';

export const OrdersHistoryColumn = (
  columnConfig,
  filters,
  setFilters,
  typeFilter,
  setTypeFilter,
  tradingTypeFilter,
  setTradingTypeFilter,
  amountRangeFilter,
  setAmountRangeFilter,
  pairNames,
  coinPairsFilter,
  setCoinPairsFilter,
  permissionName,
  handleRevertTradeHistory,
  setDebounceAmountRange,
) => {
  const { showModal, hideModal } = useContext(ModalContext);

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');

  const columns = [
    {
      name: 'User',
      cell: (row) => (
        (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard) ? (
          <Link
            to={`/user-detail/${row?.user?._id}`}
            className="text-decoration-none"
          >
            {row?.user.username}
          </Link>
        ) : row?.user.username
      ),
      sortable: true,
      omit: omitColumn(columnConfig, 'User'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Isolated', _id: '1' }, { name: 'Cross', _id: '0' }]}
          filters={typeFilter}
          setFilters={setTypeFilter}
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.marginType === 1 ? 'Isolated' : 'Cross'),
      omit: omitColumn(columnConfig, 'Type'),
    },
    {
      name: (
        <ModalFilters
          data={pairNames}
          filters={coinPairsFilter}
          setFilters={setCoinPairsFilter}
          tabName="Trading Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.pairName),
      omit: omitColumn(columnConfig, 'Trading Pair'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
          filters={tradingTypeFilter}
          setFilters={setTradingTypeFilter}
          tabName="Trading Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.tradeType === 1 ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Trading Type'),
      width: '200px',
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Trading volume"
          value={amountRangeFilter ?? [0, 100000000]} 
          setValue={setAmountRangeFilter}
          filters={{}}
          setFilters={setDebounceAmountRange}
          filedName="amountRange"
          debounceAmountRange
          maxValue={100000000}
          minValue={0}
        />
      ),
      cell: (row) => toFixed((row?.userInvestedAmount || 0) + (row?.leverage || 0) * (row?.userInvestedAmount || 0), 2),
      omit: omitColumn(columnConfig, 'Trading Volume'),
      width: '200px',
    },
    {
      name: 'Commission',
      cell: ({ endCommission }) => toFixed(endCommission, 2) ?? 0,
      omit: omitColumn(columnConfig, 'Commission'),
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2) ?? 0,
      omit: omitColumn(columnConfig, 'Swap'),
    },
    {
      name: 'Profit/Loss',
      cell: (row) => (row?.tradeProfitOrLoss ? toFixed(row?.tradeProfitOrLoss, 2) : 0),
      sortable: true,
      omit: omitColumn(columnConfig, 'Profit/Loss'),
      width: '200px',
    },
    {
      name: 'Start Time',
      cell: (row) => moment(row.initiatedAt || row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'Start Time'),
      width: '200px',
    },
    {
      name: 'End Time',
      cell: (row) => moment(row.closedAt || row.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
      sortable: true,
      omit: omitColumn(columnConfig, 'End Time'),
      width: '200px',
    },
    {
      name: 'Action',
      cell: (row) => (
        row.isResolved !== false
          ? (
            <>
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => {
                  showModal({
                    headerContent: <h3>Edit Order</h3>,
                    bodyContent: <EditOrderModal 
                      order={row} 
                      hideModal={hideModal} 
                    />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon text-white" />
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={(e) => handleRevertTradeHistory(e, row)}
              >
                <FontAwesomeIcon icon={faUndo} className="header-icon text-white" />

              </button>
            </>
          )
          : <span>-</span>
      ),
      minWidth: '200px',
      omit: omitColumn(columnConfig, 'Action'),
    },
  ];

  return columns;
};
