/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { deleteSetting, getSettings } from '../../../../redux/tradingSetting/tradingSettingActions';
import { TradingSettingsModal } from './TradingSettingsModal';
import { ModalContext } from '../../../../context';

export default function ShiftDataTable({ id = '' }) {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const settings = useSelector((state) => state.tradingSettings);

  useEffect(() => {
    dispatch(getSettings(id));
  }, [id]);

  const deleteTradingSetting = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteSetting(id));
        }
      });
  };

  const columns = [
    {
      name: 'Pair Name',
      cell: ({ pairName }) => pairName,
    },
    {
      name: 'Shift Value',
      cell: ({ isPositive, shift }) => (isPositive ? shift : `-${shift}`),
    },
    {
      name: 'Actions',
      cell: ({
        pairName, shift, isPositive, _id, 
      }) => (
        <>
          <button
            type="button"
            onClick={() => {
              showModal({
                headerContent: <h3>EDIT SHIFT</h3>,
                bodyContent: <TradingSettingsModal
                  userId={id}
                  data={{
                    pair: pairName, shift, isPositiveV: isPositive, isCreate: false, _id, 
                  }}
                />,
              });
            }}
            className="btn btn-warning btn-sm me-1 p-1"
          >
            <FontAwesomeIcon icon={faPencil} className="header-icon" />
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteTradingSetting(_id)}
          >
            <FontAwesomeIcon icon={faTrash} className="header-icon" />
          </button>
        </>
      ),
    },
  ];

  return (
    <div>
      <button
        type="button"
        className="btn btn-default icon-btn btn-bg-green"
        onClick={() => {
          showModal({
            headerContent: <h3>CREATE SHIFT</h3>,
            bodyContent: <TradingSettingsModal userId={id} data={{ isCreate: true }} />,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} size="sm" />
        Add
      </button>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          columns={columns}
          data={settings}
          theme="solarizedd"
        />
      </div>
    </div>
  );
};
