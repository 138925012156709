/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {
  getOptions,
  getStakings,
  deleteStaking,
  updateStakingState,
} from '../../redux/staking/stakingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function Stakings() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [stakings, setStakings] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filterDeposit, setFilterDeposit] = useState('');

  const allStakings = useSelector((state) => state?.staking?.stakings);
  const stakingOptions = useSelector((state) => state.staking.options);
  const stakingsFetched = useSelector((state) => state?.staking?.stakingsFetched);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      Promise.allSettled([dispatch(getOptions()), dispatch(getStakings())]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (stakingsFetched) {
      dispatch(updateStakingState());
      setLoader(false);
    }
    if (allStakings?.length > 0) {
      setStakings(allStakings);
      setFilteredItems(allStakings);
    } else {
      setStakings([]);
      setFilteredItems([]);
    }
  }, [allStakings, stakingsFetched]);

  const searchByCurrency = (e) => {
    setFilterCurrency(e.target.value);
    const searchText = e.target.value;
    const searchData = stakings.filter(
      (row) => row?.currency?.name
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase())
        || row?.currency?.symbol?.toLowerCase()?.includes(searchText.toLowerCase()),
    );
    setFilteredItems(searchData);
  };

  const searchByDeposit = (e) => {
    setFilterDeposit(e.target.value);
    const searchText = e.target.value;
    const searchData = stakings.filter((row) => row?.minDeposit
      ?.toString()
      ?.toLowerCase()
      ?.includes(searchText.toLowerCase()));
    setFilteredItems(searchData);
  };

  const deleteAction = async (stakingId) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteStaking(stakingId));
      }
    });
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h4 className="mb-3 text-capilatize">Staking</h4>
          <div className="action-btns mb-5">
            <Link to="/add-staking">
              <button type="button" className="btn btn-default">
                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                Add
              </button>
            </Link>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
            <div className="table-responsive">
              <table>
                <tr>
                  <th>Currency</th>
                  <th>Minimum Deposit</th>
                  {stakingOptions.map((option) => (
                    <th key={option._id}>{option.isFlexible ? option.label : `Locked ${option.label}`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
                <tr className="search-holder">
                  <th>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        name="filterCurrency"
                        value={filterCurrency}
                        onChange={(e) => searchByCurrency(e)}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        name="filterDeposit"
                        value={filterDeposit}
                        onChange={(e) => searchByDeposit(e)}
                      />
                    </div>
                  </th>
                  {stakingOptions.map(({ _id }) => (
                    <th key={`th-${_id}`} />
                  ))}
                  <th />
                </tr>
                {filteredItems?.length > 0 ? (
                  filteredItems.map((currentStaking) => (
                    <tr key={`staking-${currentStaking?._id}`}>
                      <td>{`${currentStaking?.currency.name} (${currentStaking?.currency.symbol})`}</td>
                      <td>{currentStaking?.minDeposit}</td>
                      {currentStaking.options?.map(({ _id, percent }) => (
                        <td key={`${currentStaking?.currency.name}-${_id}`}>{`${percent} %`}</td>
                      ))}
                      <td>
                        <div className="action-btn">
                          <Link
                            className="btn-complete"
                            to={`/edit-staking/${currentStaking?._id}`}
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                          <button
                            type="button"
                            className="btn-cancel"
                            onClick={() => deleteAction(currentStaking?._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td />
                    <td>No Record Found</td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Stakings;
