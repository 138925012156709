import React from 'react';

function PartnerList() {
  return (
    <div className="col-lg-9 col-md-8">
      <div className="content-wrapper">
        <div className="content-box">
          <h5>Partner List</h5>
          <br />
          <div className="table-responsive">
            <table className="table mt-3 table-sm table-bordered">
              <thead className="table_head">
                <tr>
                  <th>Partner Name</th>
                  <th>Username</th>
                  <th>Area Co</th>
                  <th>Mobil Phone</th>
                  <th>Email No</th>
                  <th>Bind To</th>
                  <th>My Total User Count</th>
                  <th>All Sub-admin Total User Count</th>
                  <th>Sub-partner Total Account</th>
                  <th>Contract Rebate Ratio</th>
                  <th>Contract Trader Rebate Ration</th>
                  <th>Contract API Rebate Ration</th>
                  <th>Contract Trader API Rebate Ration</th>
                  <th>Exchange Rebate Ration</th>
                  <th>Exchange Trader Rebate Ration</th>
                  <th>Exchange API Rebate Ration</th>
                  <th>Exchange Trader API Rebate Ration</th>
                  <th>Channel Code</th>
                  <th>VIP Code</th>
                  <th>Create Time</th>
                  <th>Update Time</th>
                  <th>By</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>abc</td>
                  <td>abc</td>
                  <td>abc</td>
                  <td>abc</td>
                  <td>abc</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>0.00000</td>
                  <td>Non-api</td>
                  <td>Non-trader</td>
                  <td>2022-03-22 08:30:00</td>
                  <td>2022-03-22 08:30:00</td>
                  <td>-</td>
                  <td>hca2</td>
                  <td>2022-03-22 08:30:00</td>
                  <td>2022-03-22 08:30:00</td>
                  <td>name</td>
                  <td>Edit History</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerList;
