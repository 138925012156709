import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { BsTrashFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import {
  getAffiliates, editAffiliate, deleteAffiliate,
} from '../redux/affiliate/affiliateActions';

export const AffiliateColumns = () => {
  const dispatch = useDispatch();

  const permissionName = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const userAction = async (id, type) => {
    Swal.fire({
      title: `Are you sure you want to ${type && type === 'block' ? 'block' : 'unblock'}?`,
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `${type && type === 'block' ? 'Block' : 'Unblock'}`,
    // eslint-disable-next-line consistent-return
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = { status: !(type && type === 'block') };

        return Promise.resolve(
          dispatch(editAffiliate(id, data)),
        )
          .then(
            () => {
              dispatch(getAffiliates());
            },
          ).catch((error) => {
            // console.log(error, 'getAffiliates');
          });
      }
    });
  };

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(deleteAffiliate(id));
      }
    });
  };

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };
  const columns = [
    {
      name: 'UID',
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice(row?._id.length || 0 - 4, row?._id.length)}
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => copyReferral()}
              className="ms-2"
            />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Full Name',
      selector: (row) => `${row?.firstName} ${row?.lastName}`,
      cell: (row) => (
        <>
          <span data-tip={`${row?.firstName} ${row?.lastName}`}>
            {row?.firstName}
            {' '}
            {row?.lastName}
          </span>
          <ReactTooltip />
        </>
      ),
      sortable: true,
    },
    {
      name: 'Email',
      width: '250px',
      selector: (row) => `${row?.email}`,
      cell: (row) => (
        <CopyToClipboard text={row?.email}>
          <span data-tip={row?.email}>
            {row?.email}
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => copyReferral()}
              className="ms-2"
            />
          </span>
        </CopyToClipboard>
      ),
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Link
            to={`/affiliate-detail/${row?._id}`}
            className="btn btn-success btn-sm me-1 p-1"
          >
            Edit
          </Link>
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('delete_user') ? (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(row?._id)}
            >
              <BsTrashFill size={18} />
            </button>
            ) : null}
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('block_user') ? (
              row?.status === true ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row?._id, 'block')}
                >
                  Block
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row?._id, 'unBlock')}
                >
                  Unblock
                </button>
              )
            ) : null}
        </>
      ),
    },
  ];
  return columns;
};
