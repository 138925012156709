import { GET_STAKING_INVESTMENTS, DELETE_STAKING_INVESTMENT } from './stakingInvestmentsTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_STAKING_INVESTMENTS:
      return action.payload;
    case DELETE_STAKING_INVESTMENT:
      return state.filter((item) => item._id !== action.payload);
    default:
      return state;
  }
};
