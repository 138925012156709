import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ModalContext } from '../../../context';
import { updateOtherPsp, addOtherPsp } from '../../../redux/pspOthers/pspOthersActions';

export function OtherPspModal({ row, type = 'add' }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const isCreating = type === 'add';
  const [formData, setFormData] = useState(
    {
      label: row.label,
      url: row.url,
      status: row.status,
      image: null,
    }, 
  );
  const [preview, setPreview] = useState(`${process.env.REACT_APP_SERVER_URL}/images/${row.imageUrl}`);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    try {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (!urlPattern.test(formData.url)) {
        throw new Error('URL must be a valid link');
      }

      formDataToSend.append('label', formData.label);
      formDataToSend.append('url', formData.url);
      formDataToSend.append('image', formData.image);

      if (!isCreating) {
        formDataToSend.append('status', formData.status);
      }

      if (isCreating) {
        await dispatch(addOtherPsp(formDataToSend)); 
      } else {
        await dispatch(updateOtherPsp(row._id, formDataToSend));
      }

      setFormData({
        label: '',
        url: '',
        image: null,
      });
      hideModal();
    } catch (error) {
      toast.error(error.message);
      hideModal();
    }
  };

  const handleInputChange = (event) => {
    const {
      name, value, type, files, 
    } = event.target;
  
    if (type === 'file') {
      const file = files[0];
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="label">Name</label>
        <input
          type="text"
          id="label"
          name="label"
          value={formData.label}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="url">URL</label>
        <input
          type="text"
          id="url"
          name="url"
          value={formData.url}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="image">Logo</label>
        <div className="form-control m-2 p-3 d-flex flex-column">
          {!isCreating && <img src={preview} alt="row.img" className="img-responsive" /> }
          <input
            type="file"
            accept="image/*"
            id="image"
            name="image"
            onChange={handleInputChange}
          />
        </div>
      </div>

      {!isCreating && (
        <div className="form-group">
          <label htmlFor="image">Status</label>
          <select 
            className="form-control user-status-select leads-status-select"
            name="status"
            defaultValue={row.status}
            onChange={handleInputChange}
          >
            <option value>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
      )}

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          {!isCreating ? 'Save' : 'Add'}
        </button>
      </div>
    </form>
  );
}
