import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getCryptoSettings, updateCryptoSettingState } from '../../redux/cryptoSetting/cryptoSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function CryptoSettings() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [cryptoSettings, setCryptoSettings] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState('');

  const allCryptoSettings = useSelector((state) => state?.cryptoSetting?.cryptoSettings);
  const cryptoSettingsFetched = useSelector((state) => state?.cryptoSetting?.cryptoSettingsFetched);

  useEffect(async () => {
    setLoader(true);
    await dispatch(getCryptoSettings());
    setLoader(false);
  }, []);

  useEffect(() => {
    if (cryptoSettingsFetched) {
      dispatch(updateCryptoSettingState());
    }
    setCryptoSettings(allCryptoSettings || []);
    setFilteredItems(allCryptoSettings || []);
  }, [allCryptoSettings, cryptoSettingsFetched]);

  const searchByCurrency = ({ target: { value } }) => {
    const searchText = value.toLowerCase();
    const searchData = cryptoSettings.filter(
      (row) => (
        row.symbol?.toLowerCase().includes(searchText)
                || row.alias?.toLowerCase().includes(searchText)
                || row.secondaryCurrency?.symbol?.toLowerCase().includes(searchText)
      ),
    );
    setFilterCurrency(value);
    setFilteredItems(searchData);
  };

  return (
    loader ? <FullPageTransparentLoader /> : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          </div>
          <div>
            <h4 className="mb-3 text-capitalize">Crypto Settings</h4>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Alias</th>
                    <th>Base Currency</th>
                    <th>Leverage</th>
                    <th>Lot Step</th>
                    <th>Lot Size</th>
                    <th>Actions</th>
                  </tr>
                  <tr className="search-holder">
                    <th>
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Search"
                          name="filterCurrency"
                          value={filterCurrency}
                          onChange={searchByCurrency}
                        />
                      </div>
                    </th>
                    <th colSpan="6" aria-hidden="true" />
                  </tr>
                </thead>
                <tbody>
                  {filteredItems?.length ? filteredItems.map((currentCryptoSetting) => (
                    <tr key={`cryptoSetting-${currentCryptoSetting._id}`}>
                      <td>{currentCryptoSetting.symbol}</td>
                      <td>{currentCryptoSetting.alias}</td>
                      <td>{currentCryptoSetting.secondaryCurrency?.symbol}</td>
                      <td>{currentCryptoSetting.leverage}</td>
                      <td>{currentCryptoSetting.lotStep || ''}</td>
                      <td>{currentCryptoSetting.lotSize || ''}</td>
                      <td>
                        <div className="action-btn">
                          <Link className="btn-complete" to={`/edit-crypto-setting/${currentCryptoSetting?._id}`}>
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="7">No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )    
  );
};

export default CryptoSettings;
