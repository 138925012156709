import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';

import { ModalContext } from '../../../../context';
import { getCRMUsers } from '../../../../redux/crmUser/crmUserActions';
import { updateVariables } from '../../../../redux/variables/variablesActions';
import { variantOptions, handlePercentageValue, handleSelectOptions } from '../../helper';

import styles from './EditVariablesModal.module.css';


export function EditVariableModal({
  id, brandName, variablesAction, propsValue, variant: propsVariant,
}) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const allBrands = useSelector((state) => state.brands);
  const [brandIds, setBrandIds] = useState('');
  const [variant, setVariant] = useState(propsVariant);
  const [alertMessageText, setAlertMessageText] = useState(typeof propsValue === 'string' ? propsValue : '');
  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const [managersOptions, setManagersOptions] = useState([]);
  const [percentageFee, setPercentageFee] = useState(typeof propsValue === 'number' ? propsValue.toString() : '');

  const [formData, setFormData] = useState({
    id, actions: variablesAction, value: propsValue, variant: propsVariant, 
  });

  useEffect(() => {
    dispatch(getCRMUsers());
  }, []);

  useEffect(() => {
    if (crmUsers.length > 0 && Array.isArray(propsValue)) {
      const initialManagersOptions = crmUsers
        .filter((user) => propsValue.includes(user._id))
        .map((user) => ({
          key: user._id,
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        }));
      setManagersOptions(initialManagersOptions);
    }
  }, [crmUsers, propsValue]);

  const brandsOptions = allBrands.map(({ name, _id }) => ({
    key: _id,
    value: _id,
    label: name,
  }));

  const crmUsersOptions = crmUsers.map(({ _id, firstName, lastName }) => ({
    key: _id,
    value: _id,
    label: `${firstName} ${lastName}`,
  }));

  const optionsVariablesValue = {
    value: setAlertMessageText,
    brandIds: setBrandIds,
    variant: setVariant,
  };

  const handleSelect = ({ target: { value, name } }) => {
    if (optionsVariablesValue[name]) {
      optionsVariablesValue[name](value);
    }
    setFormData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleManagers = handleSelectOptions(setManagersOptions, setFormData, 'managerIds', 'managerNames');


  const handlePercentageSelect = ({ target: { value } }) => {
    setPercentageFee(handlePercentageValue(value));
    setFormData((prev) => ({ ...prev, value: Number(handlePercentageValue(value)) }));
  };


  const submitForm = async (event) => {
    event.preventDefault();
    await dispatch(updateVariables(formData));
    hideModal();
  };

  const variantElements = {
    // 'Self User Registration Assign': (
    //   <div className="form-group mt-2 custom-milti-select">
    //     <label className="control-label" htmlFor="value">Crm User</label>
    //     <MultiSelect
    //       name="value"
    //       options={crmUsersOptions}
    //       value={managersOptions}
    //       onChange={handleManagers}
    //       labelledBy="Select"
    //       includeSelectAllOption="true"
    //     />
    //   </div>
    // ),
    // 'Alert message': (
    //   <>
    //     <div className="form-group">
    //       <label className="lead" htmlFor="variant">Variant</label>
    //       <select
    //         name="variant"
    //         value={variant}
    //         className={styles.selector}
    //         onChange={handleSelect}
    //       >
    //         { variantOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>) }
    //       </select>
    //     </div>
    //     <div className="form-group col-md-12 pt-2 mb-4">
    //       <label className="control-label" htmlFor="value">Add new alert message</label>
    //       <textarea 
    //         rows="4" 
    //         placeholder="Type Alert Message" 
    //         className="form-control" 
    //         name="value" 
    //         value={alertMessageText} 
    //         onChange={handleSelect}
    //       />
    //     </div>
    //   </>
    // ),
    'Monthly inactivity fee': (
      <div className="form-group">
        <label className="control-label">Value</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter value in %"
          aria-label=""
          aria-describedby=""
          value={percentageFee}
          name="value"
          onChange={handlePercentageSelect}
        />
      </div>
    ),
  };

  return (
    <form onSubmit={submitForm}>
      {brandName.length === 0 && (
      <div className="form-group">
        <label className="lead" htmlFor="brandId">Brand</label>
        <select
          name="brandId"
          value={brandIds}
          className={styles.selector}
          onChange={handleSelect}
        >
          <option value="">Choose brand</option>
          {brandsOptions.map(({ key, label, value }) => <option key={key} value={value}>{label}</option>)}
        </select>
      </div>
      )}
      { variantElements[variablesAction] }
      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          Add
        </button>
      </div>
    </form>
  );
}
