import { toast } from 'react-toastify';
import {
  GET_COMMODITIES_SETTINGS,
  GET_COMMODITIES_SETTING,
  ADD_COMMODITIES_SETTING,
  EDIT_COMMODITIES_SETTING,
  DELETE_COMMODITIES_SETTING,
  TOGGLE_COMMODITIES_SETTING_STATE,
  ERROR_COMMODITIES_SETTING_STATE,
} from './commoditiesSettingTypes';
import { apiHelper } from '../apiHelper';
  
export const getCommoditiesSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/commodities-setting', '');
    const payload = res?.data?.commoditiesSettings || [];
    dispatch({
      type: GET_COMMODITIES_SETTINGS,
      payload,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const getCommoditiesSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/commodities-setting/${id}`, '');
    if (res?.data && res?.data?.commoditiesSetting) {
      const { data } = res;
      dispatch({
        type: GET_COMMODITIES_SETTING,
        payload: data?.commoditiesSetting,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const addCommoditiesSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/commodities-setting/add', data);
  
    if (res?.data && res.data?.commoditiesSetting_) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: ADD_COMMODITIES_SETTING,
        payload: data?.commoditiesSetting_,
      });
    } else {
      dispatch({
        type: ERROR_COMMODITIES_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_COMMODITIES_SETTING_STATE,
    });
    toast.error(error.message);
  }
};
  
export const editCommoditiesSetting = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/commodities-setting/${id}`, bodyData);
    if (res?.data && res?.data?.commoditiesSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: EDIT_COMMODITIES_SETTING,
        payload: data?.commoditiesSetting,
      });
    } else {
      dispatch({
        type: ERROR_COMMODITIES_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_COMMODITIES_SETTING_STATE,
    });
    toast.error(error.response.message);
  }
};
  
export const deleteCommoditiesSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/commodities-setting/${id}`, '');
    if (res?.data && res?.data?.commoditiesSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: DELETE_COMMODITIES_SETTING,
        payload: data?.commoditiesSetting?._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const updateCommoditiesSettingState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_COMMODITIES_SETTING_STATE,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
