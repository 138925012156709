import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { apiHelper } from '../apiHelper';
import { GET_EXCHANGE_ORDER, REVERT_EXCHANGE_ORDER, DELETE_EXCHANGE_ORDER } from './exchangeOrderTypes';

const handleHardDelete = (message) => Swal.fire({
  title: `${message} But you can delete this order without changing amounts on user's wallets. Do you want to delete this exchange?`,
  html: '',
  showCloseButton: true,
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes',
});

export const getExchangeOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/internalOrderHistory/list?userId=${id}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_EXCHANGE_ORDER,
        payload: res.data.orders,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const revertExchangeOrder = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', `/api/internalOrderHistory/revert/${id}`, '');
  
    if (res && res.data && res.data.success) {
      dispatch({
        type: REVERT_EXCHANGE_ORDER,
        payload: res.data.order,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteExchangeOrder = (id) => async (dispatch) => {
  const res = await apiHelper('delete', `/api/internalOrderHistory/${id}`, '');

  if (res && res.data && res.data.success) {
    dispatch({
      type: DELETE_EXCHANGE_ORDER,
      payload: res.data.order,
    });

    return;
  }

  if (res && res.response && res.response.status === 409) {
    handleHardDelete(res.response.data.message)
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            const res = await apiHelper('delete', `/api/internalOrderHistory/${id}?isHardDelete=true`, '');
  
            if (res && res.data && res.data.success) {
              dispatch({
                type: DELETE_EXCHANGE_ORDER,
                payload: res.data.order,
              });
            }
          } catch (error) {
            toast.error(error.message);
          }
        }
      });
  }
};
