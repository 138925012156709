import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { addForexCurrency, updateForexCurrencyState } from '../../redux/forexCurrency/forexCurrencyActions';
import { showForexCurrencies, updateState } from '../../redux/currency/currencyActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function AddForexCurrency() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [pairSymbol, setPairSymbol] = useState('');
  const [pairAlias, setPairAlias] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedPrimaryCurrency, setSelectedPrimaryCurrency] = useState(null);
  const [selectedSecondaryCurrency, setSelectedSecondaryCurrency] = useState(null);

  const error = useSelector((state) => state.forexCurrency?.forexCurrencyError);
  const forexCurrencyAdded = useSelector((state) => state.forexCurrency?.forexCurrencyAdded);
  const currencies = useSelector((state) => state.currency?.forexCurrencies);
  const fetchedCurrencies = useSelector((state) => state.currency?.forexCurrenciesFetched);

  const { register, handleSubmit, formState: { errors } } = useForm();

  async function fetchCurrencyOptions() {
    const currencyOptions = await currencies?.map((curr) => ({ value: curr._id, label: curr.symbol }));
    setCurrencyOptions(currencyOptions);
  }

  useEffect(() => {
    setLoader(true);
    dispatch(showForexCurrencies());
  }, []);

  useEffect(() => {
    if (fetchedCurrencies) {
      setLoader(false);
      dispatch(updateState());
      if (currencies?.length > 0) {
        fetchCurrencyOptions();
      }
    }
  }, [fetchedCurrencies, currencies]);

  useEffect(() => {
    if (forexCurrencyAdded) {
      dispatch(updateForexCurrencyState());
      setLoader(false);
      history.goBack();
    }
  }, [forexCurrencyAdded]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateForexCurrencyState());
    }
  }, [error]);

  const handlePrimaryCurrencyChange = (currency) => {
    setSelectedPrimaryCurrency(currency);
  };

  const handleSecondaryCurrencyChange = (currency) => {
    setSelectedSecondaryCurrency(currency);
  };

  useEffect(() => {
    if (selectedPrimaryCurrency && selectedSecondaryCurrency) {
      setPairSymbol(`${selectedPrimaryCurrency.label}${selectedSecondaryCurrency.label}`);
      setPairAlias(`${selectedPrimaryCurrency.label}/${selectedSecondaryCurrency.label}`);
    }
  }, [selectedPrimaryCurrency, selectedSecondaryCurrency]);

  const handleAddForexCurrency = (formData) => {
    if (!selectedPrimaryCurrency?.value || !selectedSecondaryCurrency?.value) {
      toast.error('Primary coin and secondary coin is required', {
        autoClose: 1000,
      });
    } else if (selectedPrimaryCurrency?.value === selectedSecondaryCurrency?.value) {
      toast.error('Primary coin and secondary coin must be different', {
        autoClose: 1000,
      });
    } else {
      setLoader(true);
      const data = {
        primaryCoin: selectedPrimaryCurrency.value,
        secondaryCoin: selectedSecondaryCurrency.value,
        pairSymbol,
        pairAlias,
        leverage: formData.leverage ? formData.leverage : 0,
        lotStep: formData.lotStep ? formData.lotStep : 0,
        lotSize: formData.lotSize ? formData.lotSize : 0,
      };

      dispatch(addForexCurrency(data));
    }
  };

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>Add New Forex Currency Pair</h3>
          <form onSubmit={handleSubmit(handleAddForexCurrency)}>
            <div className="form-group col-md-12 pt-2 ">
              <label className="control-label">Primary Coin</label>
              <Select
                value={selectedPrimaryCurrency}
                onChange={handlePrimaryCurrencyChange}
                options={currencyOptions}
                styles={colourStyles}
              />
            </div>
            <div className="form-group col-md-12 pt-2 ">
              <label className="control-label">Secondary Coin</label>
              <Select
                value={selectedSecondaryCurrency}
                onChange={handleSecondaryCurrencyChange}
                options={currencyOptions}
                styles={colourStyles}
              />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Pair Symbol</label>
              <input type="text" className="form-control" value={pairSymbol} onChange={(e) => e.preventDefault()} disabled />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Pair Alias</label>
              <input type="text" className="form-control" value={pairAlias} onChange={(e) => e.preventDefault()} disabled />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Leverage</label>
              <input
                type="number"
                className="form-control"
                {...register('leverage')}
                name="leverage"
              />
              {errors?.leverage && <span className="errMsg">{errors.leverage.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Step</label>
              <input
                type="text"
                className="form-control"
                {...register('lotStep')}
                name="lotStep"
              />
              {errors?.lotStep && <span className="errMsg">{errors.lotStep.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Size</label>
              <input
                type="number"
                className="form-control"
                {...register('lotSize')}
                name="lotSize"
              />
              {errors?.lotSize && <span className="errMsg">{errors.lotSize.message}</span>}
            </div>
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default AddForexCurrency;
