import {
  GET_ORDER, 
  CLEAR_ORDER, 
  GET_USER_ORDER, 
  ORDER_ERROR, 
  STOP_ORDER, 
  ADD_ORDER, 
  REVERT_ORDER, 
  EDIT_HISTORY_ORDER, 
  ALL_ACTIVE_ORDERS, 
  EXPORT_FILTERED_DATA_TO_FILE, 
  TOGGLE_ORDER_STATE, 
  UPDATE_SOCKET_ORDER, 
  UPDATE_ACTIVE_ORDER, 
  START_ORDER,
  GET_OPEN_ORDERS_BY_QUERY,
  GET_OPEN_ORDERS_PAIR_NAMES,
  GET_ALL_ORDERS_BY_QUERY,
} from './leverageOrderTypes';

const initialState = {
  orders: [],
  userOrders: [],
  order: [],
  allActiveOrders: [],
  pairNames: [],
  started: false,
  success: false,
  error: false,
  updatedOrder: false,
  edittedHistoryOrder: false,
  exportFilterdDataToFile: false,
};

// eslint-disable-next-line default-param-last
const LeverageOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SOCKET_ORDER: {
      const updatedOrderId = action.payload?._id;
      const updatedIndex = state.userOrders.findIndex((obj) => obj._id === updatedOrderId);

      let updatedUserOrders;
      if (updatedIndex !== -1) {
        updatedUserOrders = [...state.userOrders];
        updatedUserOrders[updatedIndex] = action.payload;
      } else {
        updatedUserOrders = [action.payload, ...state.userOrders];
      }

      return {
        ...state,
        userOrders: updatedUserOrders,
      };
    }
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
        edittedHistoryOrder: false,
      };
    case GET_USER_ORDER:
      return {
        ...state,
        userOrders: action.payload,
        edittedHistoryOrder: false,
      };
    case UPDATE_ACTIVE_ORDER:
      return {
        ...state,
        allActiveOrders: {
          ...state.allActiveOrders,
          paginatedData: state.allActiveOrders?.paginatedData?.map((order) => {
            if (order._id === action.payload._id) {
              return { ...action.payload, user: order.user };
            }

            return order;
          }),
        },
        userOrders: state.userOrders.map((order) => (order._id === action.payload._id ? action.payload : order)),
      };
    case ADD_ORDER:
      return {
        ...state,
        orders: action.payload,
        success: true,
        error: false,
        updatedOrder: true,
        edittedHistoryOrder: false,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        started: false,
        success: false,
        error: false,
        updatedOrder: false,
        edittedHistoryOrder: false,
      };
    case START_ORDER:
      return {
        ...state,
        started: true,
      };
    case STOP_ORDER:
      return {
        ...state,
        success: false,
        error: false,
        updatedOrder: true,
        edittedHistoryOrder: false,
        userOrders: state.userOrders.filter((order) => (order._id !== action.payload._id)),
        allActiveOrders: {
          ...state.allActiveOrders,
          paginatedData: state.allActiveOrders?.paginatedData?.filter((order) => (order._id !== action.payload._id)),
        },
      };
    case REVERT_ORDER:
      return {
        ...state,
        userOrders: state.userOrders.filter((item) => item._id !== action.payload._id),
        orders: {
          ...state.orders,
          ordersHistory: state.orders?.ordersHistory.filter((item) => item._id !== action.payload._id),
        },
        edittedHistoryOrder: false,
      };
    case EDIT_HISTORY_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          ordersHistory: state.orders?.ordersHistory?.map((order) => {
            if (order._id === action.payload._id) {
              return { ...action.payload, user: order.user };
            }
    
            return order;
          }), 
        },
        userOrders: state.userOrders.map((order) => (order._id === action.payload._id ? action.payload : order)),
        edittedHistoryOrder: true,
      };
    case GET_OPEN_ORDERS_BY_QUERY:
      return {
        ...state,
        allActiveOrders: action.payload,
        edittedHistoryOrder: false,
      };
    case GET_OPEN_ORDERS_PAIR_NAMES:
      return {
        ...state,
        pairNames: action.payload,
        edittedHistoryOrder: false,
      };
    case GET_ALL_ORDERS_BY_QUERY:
      return {
        ...state,
        orders: action.payload,
        edittedHistoryOrder: false,
      };
    case ALL_ACTIVE_ORDERS:
      return {
        ...state,
        allActiveOrders: action.payload,
        edittedHistoryOrder: false,
      };
    case TOGGLE_ORDER_STATE:
      return {
        ...state,
        exportFilterdDataToFile: false,
      };
    case EXPORT_FILTERED_DATA_TO_FILE:
      return {
        ...state,
        exportFilterdDataToFile: action.payload,
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default LeverageOrderReducer;
