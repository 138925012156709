import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  faClone, faComment, faEnvelope, faPhone, faExternalLink, faCircleInfo, faTimes, 
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { editUser } from '../../redux/users/userActions';
import { getAdminComments } from '../../redux/adminComment/adminCommentActions';
import { ModalFilters } from '../../components';
import { RangeModalFilter } from '../../pages/Transactions/modalFilters';
import {
  omitColumn, formatDate, setTransactionActionColumn, dateOptions, 
} from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUsdt';
import { toFixed } from '../../helpers/helper';
import styles from './styles.module.css';


export const ExternalTransactionsSchema = (
  setTransactionIdFilter,
  permissionName,
  currencies,
  transactionIdFilter,
  uidFilter,
  setUidFilter,
  nameFilter,
  setNameFilter,
  emailFilter, 
  setEmailFilter,
  setAssetFilters,
  assetFilters,
  amountRange,
  setAmountRange,
  handleAdditionalInfoModalToggle,
  setManagerFilters,
  managerFilters,
  realFilter,
  setRealFilter,
  statusFilter,
  setStatusFilter,
  isAdditionalInfoModalShown,
  setIsAdditionalInfoModalShown,
  resolveCurrentDepositTransaction,
  handleRevertTransaction,
  assignToShow, 
  depositsFilters,
  setDepositsFilters,
  columnConfig,
  timeInitiatedFilter,
  setTimeInitiatedFilter,
  updateIsReal,
  handleDeleteTransaction,
  isDeleted,
  setDebounceAmountRange,
  inputFieldError, 
  setInputFieldError,
) => {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(0);

  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const [commentDate, setCommentDate] = useState(new Date());
  const currencyRates = useSelector((state) => state.currencyRates);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const JSONparser = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleShowComment = (userId, rowId) => {
    dispatch(getAdminComments(userId));
    if (adminComments.length > 0) {
      setCommentDate(new Date(adminComments[adminComments.length - 1].createdAt));
    } 

    setShowComments(rowId);
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    if (value.length < 3 && value !== '') setInputFieldError((prev) => ({ ...prev, errorMessage: 'Must be more than 3 symbols', [nameField]: true }));
    else setInputFieldError((prev) => ({ ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'transactionIdIsNotValid') setTransactionIdFilter(value);
    if (nameField === 'userIdIsNotValid') setUidFilter(value);
    if (nameField === 'fullNameIsNotValid') setNameFilter(value);
    if (nameField === 'emailIsNotValid') setEmailFilter(value);
  };
  
  const isUserCanSeeClientCard = permissionName && permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName && permissionName.includes('leads');

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Transaction ID
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            value={transactionIdFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'transactionIdIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.transactionIdIsNotValid && (
              <p className={styles.errorInput}>
                {inputFieldError.errorMessage}
              </p>
            )}
          </span> 
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'Transaction ID'), 
      cell: ({ _id }) => (
        <CopyToClipboard text={_id}>
          <span>      
            {_id?.slice(0, 4)}
            ...
            {_id?.slice((_id.length || 0) - 4, _id.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client ID
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            value={uidFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      width: '200px',
      omit: omitColumn(columnConfig, 'Client ID'), 
      cell: ({ userId }) => (
        <CopyToClipboard text={userId}>
          <span>
            {userId.slice(0, 4)}
            ...
            {userId.slice((userId.length || 0) - 4, userId.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client"
            value={nameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.fullNameIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Client'), 
      cell: ({ _id, user }) => (
        <div style={{
          width: '100%',
          paddingLeft: '30px',
          display: 'flex',
          gap: '3px',
          position: 'relative',
          justifyContent: 'flex-start',
        }}
        >
          {showComments === _id ? (
            <div className="depositsAdditionalInfoModal" style={{ width: '200px', left: 50, top: -100 }}>
              <h6>Comments</h6>
              <br />
              <br />
              {adminComments?.length > 0 ? (
                <>
                  {`Date: [${commentDate.getDate()} - ${commentDate.getUTCMonth()} - ${commentDate.getFullYear()}]`}
                  <br />
                  {`Comment: ${adminComments[adminComments.length - 1]?.text}`}
                  <br />
                  <br />
                </>
              ) : (
                <h6>No Comments</h6>
              )}
            </div>
          ) : null}

          <FontAwesomeIcon 
            icon={faComment} 
            size="xs" 
            onMouseEnter={() => handleShowComment(_id, _id)}
            onMouseLeave={() => setShowComments(0)}
          />
          <FontAwesomeIcon icon={faEnvelope} size="xs" />
          <FontAwesomeIcon icon={faPhone} size="xs" />
          {(user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard) ? (
            <Link to={`/user-detail/${user?._id}`} target="blank" className="text-white">
              <FontAwesomeIcon icon={faExternalLink} size="xs" />
            </Link>
          ) : null}
          {(user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard)
            ? (
              <Link to={`/user-detail/${user._id}`}>
                <span data-tip={`${user.firstName} ${user.lastName}`} className="full-name-text">
                  {`${user.firstName} ${user.lastName}`}
                </span>
              </Link>
            ) : (
              <span data-tip={`${user.firstName} ${user.lastName}`} className="full-name-text">
                {`${user.firstName} ${user.lastName}`}
              </span>
            )}
          <ReactTooltip />
        </div>
      ),
      width: '250px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Email
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.emailIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="Email"
            autoComplete="off"
            value={emailFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'emailIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.emailIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Email'), 
      width: '250px',
      cell: ({ user }) => (
        <div style={{ width: '250px', paddingLeft: '40px', marginRight: '10px' }}>
          <CopyToClipboard text={user.email}>
            <span style={{ margin: '5px' }} data-tip={user.email}>
              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
              {user.email}
            </span>
          </CopyToClipboard>
        </div>
      ),
      sortable: false,
    },
    {
      name: (
        <ModalFilters 
          data={assignToShow} 
          filters={managerFilters} 
          setFilters={setManagerFilters} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={depositsFilters}
          storageName="Assigned To"
          setUserFilters={setDepositsFilters}
        />
      ),
      width: '200px',
      omit: omitColumn(columnConfig, 'Assigned To'), 
      cell: ({ user, userId }) => {
        const assignedToAgent = user?.assignedTo ? user?.assignedTo : '';
        return (
          assignedToAgent
            ? (
              <>
                <select 
                  className="form-control user-status-select leads-status-select"
                  name="type"
                  defaultValue={assignedToAgent?._id}
                  style={{ color: assignedToAgent?.color ? assignedToAgent?.color : '#fff' }}
                  onChange={(e) => handleChangeAssignTo(e, userId)}
                >
                  <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
                  <option value={assignedToAgent._id} style={{ color: '#fff' }} color="#fff">{`${assignedToAgent.firstName} ${assignedToAgent.lastName}`}</option>
                  {assignToShow?.length > 0 && assignToShow.map((item) => {
                    if (item._id !== assignedToAgent._id) {
                      return (
                        <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                      ); 
                    }
                    return null;
                  }) }
                </select>
                <ReactTooltip />
              </>
            ) : '-'
        );
      },
    },
    {
      name: (
        <RangeModalFilter 
          columnName="Amount"
          value={amountRange ?? [0, 1000000]}
          setValue={setAmountRange}
          setFilters={setDebounceAmountRange}
          filters={{}}
          filedName="amount"
          debounceAmountRange
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Amount'), 
      selector: ({ amount }) => amount,
    },
    {
      name: (
        <ModalFilters 
          data={currencies} 
          filters={assetFilters} 
          setFilters={setAssetFilters} 
          tabName="Currency" 
          searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      minWidth: '160px',
      selector: ({ currency }) => currency,
    },
    {
      name: 'In USD',
      selector: ({ amount }) => toFixed(amount, 2),
      width: '180px',
      omit: omitColumn(columnConfig, 'In USD'), 
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return usdtValue ? toFixed(usdtValue, 2) : '-';
      },
    },
    {
      name: (
        <ModalFilters 
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusFilter} 
          setFilters={setStatusFilter} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Status'), 
      selector: ({ _id, isResolved, additionalInfo }) => (
        <>
          {isResolved === 0 ? (
            <span className="badge rounded-pill bg-warning">Pending</span>
          ) : isResolved === 1 ? (
            <span className="badge rounded-pill bg-success">Completed</span>
          ) : (
            <span className="badge rounded-pill bg-danger">Declined</span>
          )}
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="ms-2"
            size="lg"
            color="#6E7F95"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleAdditionalInfoModalToggle(_id);
            }}
          />

          {isAdditionalInfoModalShown === _id ? (
            <div className="depositsAdditionalInfoModal">
              <h5>Additional Info</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setIsAdditionalInfoModalShown('')}
              />
              {additionalInfo ? (
                JSONparser(additionalInfo)
                  ? Object.hasOwnProperty.call(JSON.parse(additionalInfo), 'name') ? (
                    <div style={{ display: 'flex' }}>
                      Name:
                      {JSON.parse(additionalInfo).name}
                      <br />
                      Card:
                      {JSON.parse(additionalInfo).number}
                      <br />
                      CVC:
                      {JSON.parse(additionalInfo).cvc}
                      <br />
                      Expire Date:
                      {JSON.parse(additionalInfo).expiry}
                      <br />
                    </div>
                  ) : (
                    additionalInfo
                  ) : additionalInfo
              ) : (
                'No Info'
              )}
            </div>
          ) : (
            null
          )}
        </>
      ),
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeInitiatedFilter} 
          setFilters={setTimeInitiatedFilter} 
          tabName="Time Initiated" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
          isDateOptions
        />
      ),
      selector: ({ createdAt }) => (createdAt ? formatDate(new Date(createdAt)) : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      minWidth: '230px',
    },
    {
      name: 'Transaction Time',
      omit: omitColumn(columnConfig, 'Transaction Time'), 
      selector: ({ createdAt }) => formatDate(new Date(createdAt)),
      sortable: false,
      minWidth: '200px',
    },
    {
      name: (
        <ModalFilters 
          data={[{ name: 'Fake', _id: false }, { name: 'Real', _id: true }]}
          filters={realFilter} 
          setFilters={setRealFilter} 
          tabName="Real/Fake" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Real/Fake'), 
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
    },
    {
      name: 'Action(s)',
      minWidth: '300px',
      omit: omitColumn(columnConfig, 'Actions'), 
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        isDeleted,
        dispatch,
      ),
    },
  ];
  return columns;
};
