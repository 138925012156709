import React, {
  useState, useEffect, useContext, useRef, 
} from 'react';
import { useDispatch } from 'react-redux';
import { HexColorPicker } from 'react-colorful';
import { editCurrency } from '../../../redux/currency/currencyActions';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { ModalContext } from '../../../context';

export function EditCurrnecyModal({ currencyData }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const hexColorPickerRef = useRef(null);

  const [currency, setCurrency] = useState(currencyData);
  const [color, setColor] = useState('#aabbcc');
  const [nameErr, setNameErr] = useState('');
  const [symbolErr, setSymbolErr] = useState('');
  const [minAmountErr] = useState('');
  const [maxAmountErr] = useState('');
  const [conversionFeeErr] = useState('');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleChange = (e) => {
    setCurrency({ ...currency, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (currencyData) {
      setColor(currencyData?.color);
      setCurrency(currencyData);
    }
  }, [currencyData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, symbol } = currency;
    const exp = /^[a-z A-Z]+$/;
    if (!name && !symbol) {
      setNameErr('Currency name is required');
      setSymbolErr('Currency symbol is required');
    } else if (name === '') {
      setNameErr('Currency name is required');
    } else if (!name.match(exp)) {
      setNameErr('Invalid Currency name (Only letters a-z allowed)');
    } else if (symbol === '') {
      setSymbolErr('Currency symbol is required');
    } else {
      const data = {
        name: currency.name,
        symbol: currency.symbol,
        color,
        minAmount: currency.minAmount,
        maxAmount: currency.maxAmount,
        conversionFee: currency.conversionFee,
      };
      await dispatch(editCurrency(currency._id, data));
      hideModal();
    }
  };

  useClickOutside({ onClickOutside: () => setDisplayColorPicker(false), ref: hexColorPickerRef });

  return (
    <form>
      <div className="form-group col-md-12">
        <label className="control-label">Currency Name</label>
        <input
          type="text"
          required="required"
          className="form-control"
          name="name"
          onChange={handleChange}
          value={currency.name}
          placeholder="Enter currency name"
        />
        {nameErr ? (<span className="errMsg">{nameErr}</span>) : ('')}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Currency Symbol</label>
        <input
          type="text"
          required="required"
          className="form-control"
          onChange={handleChange}
          name="symbol"
          value={currency.symbol}
          placeholder="Enter currency symbol"
        />
        {symbolErr ? (<span className="errMsg">{symbolErr}</span>) : ('')}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Min Convertible Amount</label>
        <input
          type="text"
          className="form-control"
          onChange={handleChange}
          name="minAmount"
          value={currency.minAmount}
          placeholder="Enter min convertible amount"
          required="required"
        />
        {minAmountErr ? (<span className="errMsg">{minAmountErr}</span>) : ('')}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Max Convertible Amount</label>
        <input
          required="required"
          className="form-control"
          onChange={handleChange}
          name="maxAmount"
          value={currency.maxAmount}
          placeholder="Enter max convertible amount"
          type="text"
        />
        {maxAmountErr ? (<span className="errMsg">{maxAmountErr}</span>) : ('')}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Conversion Fee(%)</label>
        <input
          type="text"
          required="required"
          className="form-control"
          onChange={handleChange}
          name="conversionFee"
          value={currency.conversionFee}
          placeholder="Enter conversion fee"
        />
        {conversionFeeErr ? (<span className="errMsg">{conversionFeeErr}</span>) : ('')}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Currency Color</label>
        <div className="inputWithButton">
          <input type="text" className="form-control" name="color" disabled />
          <button className="btn btn-secondary" onClick={() => setDisplayColorPicker(!displayColorPicker)} type="button">
            Pick Color
          </button>
          {displayColorPicker ? (
            <div ref={hexColorPickerRef} style={{ position: 'absolute' }}>
              <HexColorPicker color={color} onChange={setColor} />
            </div>
          ) : null}
                    
          <span className="picked-value" style={{ borderLeftColor: color }}>
            Picked color is
            {' '}
            {color}
          </span>
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default mt-3" onClick={handleSubmit}>Save</button>
      </div>
    </form>
  );
};
