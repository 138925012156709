import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { showAllCurrencies, deleteCurrency } from '../../redux/currency/currencyActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { GetCoinImg } from '../../helpers/getCoinImg';
import { CryptoCharts, EditCurrnecyModal } from './Components';
import { ModalContext } from '../../context';

function Currency() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const [loader, setLoader] = useState(false);

  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const success = useSelector((state) => state.currency?.currencies?.success);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);

  const isUserCanEditCurrencies = permissionName && permissionName.includes('edit_currency');
  const isUserCanDeleteCurrencies = permissionName && permissionName.includes('delete_currency');
  const isUserCanAddCurrencies = permissionName && permissionName.includes('add_currency');

  useEffect(() => {
    setLoader(true);

    async function fetchData() {
      await dispatch(showAllCurrencies());
      if (success) {
        setLoader(false);
      }
    }
    fetchData();
  }, [success]);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteCurrency(id));
    });
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <div className="dashboard-content-box">
            <CryptoCharts />
          </div>

          <h3 style={{ marginLeft: '20px' }}>Currencies Details</h3>
          {/* {isUserCanAddCurrencies ? (
              <Link
                to='/add-currency'
                className="btn btn-success text-decoration-none text-light"
                style={{ marginLeft: '20px' }}
              >
                Add
              </Link>
            ) : null} */}
          <div
            className="dashboard-tbl-wrapper custom-tbl-wrapper"
            style={{ overflow: 'hidden', margin: '20px' }}
          >
            <table className="table">
              <thead className="table_head">
                <tr>
                  <th>Name</th>
                  <th>Symbol</th>
                  <th>Min Con</th>
                  <th>Max Con</th>
                  <th>Con Fee(%)</th>
                  <th>Color</th>
                  {isUserCanEditCurrencies ? <th>Action(s)</th> : null }
                  {/* {isUserCanDeleteCurrencies ? <th>Delete</th> : null } */}
                </tr>
              </thead>
              <tbody>
                {currencies
                      && currencies?.length > 0
                      && currencies
                        ?.filter((row) => row?.isFiat !== true && row?.status === true)
                        ?.map((currency) => (
                          <tr key={currency._id}>
                            <td width="100px">
                              <img
                                src={GetCoinImg(currency?.symbol)}
                                alt=""
                                className="img-fluid coin-img pe-1"
                                width="44px"
                                height="44px"
                              />
                              {currency.name}
                            </td>
                            <td>{currency.symbol}</td>
                            <td>{currency.minAmount}</td>
                            <td>{currency.maxAmount}</td>
                            <td>{currency.conversionFee}</td>
                            <td>
                              <span
                                className="picked-value"
                                style={{
                                  borderLeftColor: currency?.color ? currency?.color : '#aabbcc',
                                }}
                              >
                                {currency?.color ? currency?.color : '#aabbcc'}
                              </span>
                            </td>
                            <td>
                              {isUserCanEditCurrencies && (
                                <button 
                                  type="button"
                                  className="btn btn-primary me-2 text-decoration-none text-light"
                                  onClick={() => showModal({ 
                                    bodyContent: <EditCurrnecyModal currencyData={currency} />, 
                                    headerContent: <h3>Edit Fiat Currency</h3>, 
                                  })}
                                >
                                  Edit
                                </button>
                              )}
                            </td>
                            {/* {isUserCanDeleteCurrencies ? (
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-danger me-2"
                                  onClick={() => deleteAction(currency._id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} className="header-icon" />
                                </button>
                              </td>
                            ) : null} */}
                          </tr>

                        ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  );
}

export default Currency;
