/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_SPOT_ORDER,
  IS_GET_SPOT_ORDER_LOADING,
  GET_USER_SPOT_ORDER,
  ADD_SPOT_ORDER,
  STOP_SPOT_ORDER,
  CLEAR_SPOT_ORDER,
  SPOT_ORDER_ERROR,
  COMPLETE_ORDER,
  UPDATE_SPOT_SOCKET_ORDER,
  GET_SPOT_PAIRS,
} from './spotOrderTypes';
import { apiHelper } from '../apiHelper';

export const getSpotOrders = (page = 1, limit = 10, filters = {}) => async (dispatch) => {
  try {
    dispatch({
      type: IS_GET_SPOT_ORDER_LOADING,
      isLoading: true,
    });
    const response = await apiHelper(
      'get',
      '/api/spotOrder/getSpotOrdersPagination',
      null,
      { page, limit, filters },
    );

    if (response && response.data.success) {
      await dispatch({
        type: GET_SPOT_ORDER,
        payload: response.data.orders,
        pagination: response.data.pagination,
      });
      dispatch({
        type: IS_GET_SPOT_ORDER_LOADING,
        isLoading: false,
      });
    } else {
      await dispatch({
        type: SPOT_ORDER_ERROR,
      });
      dispatch({
        type: IS_GET_SPOT_ORDER_LOADING,
        isLoading: false,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUniqueSpotPairs = () => async (dispatch) => {
  try {
    const response = await apiHelper(
      'get',
      '/api/spotOrder/getUniqueSpotPairs',
    );

    if (response && response.data.success) {
      await dispatch({
        type: GET_SPOT_PAIRS,
        payload: response.data.spotPairs,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};


export const exportSpotDataToFile = (filteredItems, columns, fileType) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/spotOrder/export', {
      filteredItems, columns, fileType,
    });
    if (res.data && res.data.fileUrl) {
      window.open(res.data.fileUrl);
    } else {
      toast.error('File was not created. Please, contact our support team.');
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUserSpotOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/spotOrder/${id}`, '');
    if (res.data && res.data.userOrders) {
      dispatch({
        type: GET_USER_SPOT_ORDER,
        payload: res.data.userOrders,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addSpotOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/spotOrder/add', data);
    if (res?.data) {
      toast.success(res.data.message);
      const { data } = res;
      dispatch({
        type: ADD_SPOT_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateSpotOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/spotOrder/update', data);
    if (res?.data) {
      toast.success(res.data.message);
      const { data } = res;
      dispatch({
        type: ADD_SPOT_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearSpotOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_SPOT_ORDER,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const stopSpotOrder = (id, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/spotOrder/stop/${id}`, '');
    if (res?.data) {
      const { data } = res;
      await dispatch(getUserSpotOrders(userId));
      toast.success(res.data.message);
      dispatch({
        type: STOP_SPOT_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const completeSpotOrder = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/spotOrder/complete/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: COMPLETE_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateSpotSocketOrder = (order) => (dispatch) => {
  if (order.status !== 1) {
    dispatch({ type: UPDATE_SPOT_SOCKET_ORDER, payload: order });
  }
};
