import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getMetalsSetting, editMetalsSetting, updateMetalsSettingState } from '../../redux/metalSetting/metalsSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function EditMetalsSetting() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [metalsSetting, setMetalsSetting] = useState('');

  const error = useSelector((state) => state.metalsSetting?.metalsSettingError);
  const metalsSettingData = useSelector((state) => state.metalsSetting?.metalsSetting);
  const metalsSettingFetched = useSelector((state) => state.metalsSetting?.metalsSettingFetched);
  const metalsSettingEditted = useSelector((state) => state.metalsSetting?.metalsSettingEditted);

  const {
    register, handleSubmit, control, reset, formState: { errors }, 
  } = useForm();

  useEffect(() => {
    reset();
    setLoader(true);
    dispatch(getMetalsSetting(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(metalsSettingData)?.length && metalsSettingFetched) {
      setMetalsSetting(metalsSettingData);
      reset(metalsSettingData);
      dispatch(updateMetalsSettingState());
      setLoader(false);
    }
  }, [metalsSettingData, metalsSettingFetched]);

  useEffect(() => {
    if (metalsSettingEditted) {
      dispatch(updateMetalsSettingState());
      setLoader(false);
      history.goBack();
    }
  }, [metalsSettingEditted]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateMetalsSettingState());
    }
  }, [error]);

  const handleEditMetalsSetting = (formData) => {
    setLoader(true);
    const data = {
      alias: formData.alias || '',
      symbol: formData.symbol || '',
      rateSymbol: formData.rateSymbol || '',
      leverage: formData.leverage || 0,
      lotStep: formData.lotStep ? Number(formData.lotStep) : 0,
      lotSize: formData.lotSize || 0,
    };
    dispatch(editMetalsSetting(id, data));
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>
            Edit Settings For
            {' '}
            {metalsSetting ? `${metalsSetting?.symbol}` : ''}
          </h3>
          <form onSubmit={handleSubmit(handleEditMetalsSetting)}>
            <div className="form-group col-md-12">
              <label className="control-label">Base Currency</label>
              <input type="text" className="form-control" defaultValue={metalsSetting?.secondaryCurrency?.symbol} disabled />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('symbol')}
                name="symbol"
                control={control}
                defaultValue={metalsSetting?.symbol}
              />
              {errors?.symbol && <span className="errMsg">{errors.symbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Alias</label>
              <input
                type="text"
                className="form-control"
                {...register('alias')}
                name="alias"
                control={control}
                defaultValue={metalsSetting?.alias}
              />
              {errors?.alias && <span className="errMsg">{errors.alias.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Rate Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('rateSymbol')}
                name="rateSymbol"
                control={control}
                defaultValue={metalsSetting?.rateSymbol}
              />
              {errors?.rateSymbol && <span className="errMsg">{errors.rateSymbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Leverage</label>
              <input
                type="number"
                className="form-control"
                {...register('leverage')}
                name="leverage"
                control={control}
                defaultValue={metalsSetting?.leverage}
              />
              {errors?.leverage && <span className="errMsg">{errors.leverage.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Step</label>
              <input
                type="text"
                className="form-control"
                {...register('lotStep')}
                name="lotStep"
                control={control}
                defaultValue={metalsSetting?.lotStep}
              />
              {errors?.lotStep && <span className="errMsg">{errors.lotStep.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Size</label>
              <input
                type="number"
                className="form-control"
                {...register('lotSize')}
                name="lotSize"
                control={control}
                defaultValue={metalsSetting?.lotSize}
              />
              {errors?.lotSize && <span className="errMsg">{errors.lotSize.message}</span>}
            </div>
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default EditMetalsSetting;
