import React from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';

export function MarketModal(
  {
    showMarket,
    handleCloseMarket,
    selectedRow,
    avbl,
    coinAmount,
    setCoinAmount,
    handleLimit,
    updateMarket,
  },
) {
  return (
    <Modal
      className="withdrawal-modal limit-modal market-modal"
      show={showMarket}
      centered
      onHide={handleCloseMarket}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="buy-tabs">
          <div className="mb-4">
            <span>Market Close</span>
          </div>

          <span>
            Order by Qty
            {selectedRow?.toCurrency?.symbol}
          </span>
          <InputGroup className="mb-3">
            <FormControl
              type="number"
              step="0.1"
              placeholder="Price"
              min="0.0"
              max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
              value={coinAmount}
              onChange={(e) => {
                const val = e.target.value;
                setCoinAmount(val);
              }}
              onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
            />
            <InputGroup.Text id="basic-addon2">{selectedRow?.toCurrency?.symbol}</InputGroup.Text>
          </InputGroup>

          <p style={{ fontSize: '12px', padding: '0 12px' }} className="mb-4 mt-2">
            4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be
            9.7290USDT (includng of EST, closing tees 3.4309USDT)
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => updateMarket()}>
          Confirm
        </Button>
        <Button variant="danger" onClick={handleCloseMarket}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
