const selectDefaultStyle = {
  control: (styles, { isSelected }) => ({
    ...styles,
    background: '#374057',
    color: '#fff',
    border: '1px solid #374057',
    boxShadow: isSelected ? 'none' : 'none',
    borderColor: isSelected ? '#374057' : '#374057',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
  input: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
  }),
  menuList: (styles) => ({
    ...styles,
    background: '#374057',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? '#16202e'
      : isSelected
        ? '#16202e'
        : undefined,
    color: '#fff',
    cursor: 'pointer',
    zIndex: 1,
    '&:hover': {
      background: '#16202e',
    },
  }),
};

export default selectDefaultStyle;
