import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_STAKING_INVESTMENTS, DELETE_STAKING_INVESTMENT } from './stakingInvestmentsTypes';

export const getStakingInvestments = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/staking-investment', '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: res.data.stakingInvestments,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const getUserStakingInvestments = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/staking-investment/user/${id}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: res.data.stakingInvestments,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteStakingInvestment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/staking-investment/${id}`, '');

    if (res && res.data && res.data.success) {
      await dispatch({
        type: DELETE_STAKING_INVESTMENT,
        payload: id,
      });

      toast.success('Record successfully deleted!', {
        autoClose: 1000,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
