import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { showAgents } from '../../../redux/users/userActions';
import { getWhitelistIP, editWhitelistIP, updateState } from '../../../redux/security/whitelistIPActions';
import { ModalContext } from '../../../context';

export function EditWhitelistIPModal({ id }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  
  const [whitelistIPData, setWhitelistIPData] = useState({ name: '', ip: '', managerId: '' });
  const agentUsers = useSelector((state) => state?.users?.allAgents);
  const whitelistIP = useSelector((state) => state?.whitelistIP?.whitelistIP);
  const whitelistIPFetched = useSelector((state) => state?.whitelistIP?.whitelistIPFetched);
  const whitelistIPEditted = useSelector((state) => state?.whitelistIP?.whitelistIPEditted);
  
  const {
    register, handleSubmit, reset, control, formState: { errors },
  } = useForm({
    defaultValues: whitelistIPData,
  });
  
  useEffect(() => {
    async function fetchData() {
      dispatch(showAgents());
      dispatch(getWhitelistIP(id));
    }
    fetchData();
  }, []);
  
  useEffect(() => {
    async function fetchData() {
      if (whitelistIPFetched) {
        dispatch(updateState());
      }
    }
    fetchData();
  }, [whitelistIPFetched]);
  
  useEffect(() => {
    if (Object.keys(whitelistIP)?.length > 0) {
      setWhitelistIPData(whitelistIP);
      reset(whitelistIP);
      dispatch(updateState());
    }
  }, [whitelistIP]);
  
  const editWhitelistIPData = {
    name: {
      required: 'Name is required',
    },
    ip: {
      required: 'IP Address is required',
      pattern: {
        value: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
        message: 'Please enter a valid IP Address',
      },
    },
  };
  
  const handleSave = (formData) => {
    const data = {
      name: formData.name,
      ip: formData.ip,
      managerId: formData.managerId,
    };
    dispatch(editWhitelistIP(id, data));
  };

  useEffect(() => {
    if (whitelistIPEditted) {
      dispatch(updateState());
      hideModal();
    }
  }, [whitelistIPEditted]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className="form-group col-md-12">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Name"
          {...register('name', editWhitelistIPData.name)}
          name="name"
          control={control}
        />
        {errors?.name && <span className="errMsg">{errors.name.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">IP Address</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter IP Address"
          {...register('ip', editWhitelistIPData.ip)}
          name="ip"
          control={control}
        />
        {errors?.ip && <span className="errMsg">{errors.ip.message}</span>}
      </div>

      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Manager</label>
        <Form.Select name="managerId" {...register('managerId')} control={control}>
          <option value="">All</option>
          {agentUsers && agentUsers.length > 0 && agentUsers.map(((agentUser) => (
            <option value={agentUser._id} key={agentUser._id}>{`${agentUser?.firstName} ${agentUser?.lastName}`}</option>
          )))}
        </Form.Select>
        {errors?.managerId && <span className="errMsg">{errors.managerId.message}</span>}
      </div>
        
      <div>
        <button className="btn btn-default" type="submit">Save</button>
      </div>
    </form>
  );
}
