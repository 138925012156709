/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  getStockSettings,
  updateStockSettingState,
} from '../../redux/stockSetting/stockSettingActions';

function StockSettings() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [filterCurrency, setFilterCurrency] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const { stockSettings, stockSettingsFetched } = useSelector((state) => state?.stockSetting);

  const handleSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    setFilterCurrency(searchText);

    const searchData = stockSettings.filter((row) => (
      row?.symbol?.toLowerCase().includes(searchText)
            || row?.alias?.toLowerCase().includes(searchText)
            || row?.secondaryCurrency?.symbol?.toLowerCase().includes(searchText)
    ));

    setFilteredItems(searchData);
  };

  const fetchData = async () => {
    await dispatch(getStockSettings());
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (stockSettingsFetched) {
      dispatch(updateStockSettingState());
    }
    setFilteredItems(stockSettings);
  }, [stockSettings, stockSettingsFetched]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
        </div>
        <div>
          <h4 className="mb-3 text-capitalize">Stock Settings</h4>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Alias</th>
                  <th>Base Currency</th>
                  <th>Leverage</th>
                  <th>Lot Step</th>
                  <th>Lot Size</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr className="search-holder">
                  <th colSpan="7">
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        name="filterCurrency"
                        value={filterCurrency}
                        onChange={handleSearch}
                      />
                    </div>
                  </th>
                </tr>
                {filteredItems.length > 0 ? (
                  filteredItems.map((currentStockSetting) => (
                    <tr key={`stockSetting-${currentStockSetting?._id}`}>
                      <td>{currentStockSetting?.symbol}</td>
                      <td>{currentStockSetting?.alias}</td>
                      <td>{currentStockSetting?.secondaryCurrency?.symbol}</td>
                      <td>{currentStockSetting?.leverage}</td>
                      <td>{currentStockSetting?.lotStep || ''}</td>
                      <td>{currentStockSetting?.lotSize || ''}</td>
                      <td>
                        <div className="action-btn">
                          <Link className="btn-complete" to={`/edit-stock-setting/${currentStockSetting?._id}`}>
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No Record Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockSettings;
