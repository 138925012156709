export const userSpotOrdersColumns = [
  {
    name: 'Spot Pairs',
    selected: true,
  },
  {
    name: 'Order Type',
    selected: true,
  },
  {
    name: 'Direction',
    selected: true,
  },
  {
    name: 'Order Value',
    selected: true,
  },
  {
    name: 'Order QTY',
    selected: true,
  },
  {
    name: 'Order Price',
    selected: true,
  },
  {
    name: 'Commission',
    selected: true,
  },
  {
    name: 'Swap',
    selected: true,
  },
  {
    name: 'Unfilled QTY',
    selected: true,
  },
  {
    name: 'Order Time',
    selected: true,
  },
  {
    name: 'Order ID',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
