import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { showAllCurrencies, updateState } from '../../redux/currency/currencyActions';
import { addStaking, updateStakingState, getOptions } from '../../redux/staking/stakingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

let currencyOptions = [];

function AddStaking() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const error = useSelector((state) => state.staking?.stakingError);
  const stakingAdded = useSelector((state) => state.staking?.stakingAdded);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const fetchedCurrencies = useSelector((state) => state.currency?.currencies?.success);
  const stakingOptions = useSelector((state) => state.staking.options);

  const { register, handleSubmit, formState: { errors } } = useForm();

  async function fetchCurrencyOptions() {
    currencyOptions = await currencies?.map((role) => ({ value: role._id, label: `${role.name} (${role.symbol})` }));
    if (currencyOptions && currencyOptions.length > 0) {
      setSelectedCurrency(currencyOptions?.[0]);
    }
  }

  useEffect(() => {
    setLoader(true);
    Promise.allSettled([dispatch(getOptions()), dispatch(showAllCurrencies())]);
  }, []);

  useEffect(() => {
    if (fetchedCurrencies) {
      setLoader(false);
      dispatch(updateState());
    }
  }, [fetchedCurrencies]);

  useEffect(() => {
    if (stakingAdded) {
      dispatch(updateStakingState());
      setLoader(false);
      history.goBack();
    }
  }, [stakingAdded]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateStakingState());
    }
  }, [error]);

  useEffect(() => {
    if (currencies?.length > 0) {
      fetchCurrencyOptions();
    }
  }, [currencies]);

  const handleCurrencyChange = (selectedCurrency) => {
    setSelectedCurrency(selectedCurrency);
  };

  const setNewValueToOptions = (formData = {}) => {
    if (!Object.keys(formData).length || !stakingOptions.length) return [];

    const options = stakingOptions.map((option) => {
      const updatedPercent = formData[option._id] || 0;

      return { ...option, percent: Number(updatedPercent) };
    });

    return options;
  };

  const handleAddStakingCurrency = (formData) => {
    setLoader(true);

    const data = {
      options: setNewValueToOptions(formData),
      currencyId: selectedCurrency.value,
    };

    dispatch(addStaking(data));
  };

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>Add New Stalking Product</h3>
          <form onSubmit={handleSubmit(handleAddStakingCurrency)}>
            <div className="form-group col-md-12 pt-2 ">
              <label className="control-label">Currency</label>
              <Select
                value={selectedCurrency}
                onChange={handleCurrencyChange}
                options={currencyOptions}
                styles={colourStyles}
              />
            </div>
            {
                stakingOptions.map((option) => (
                  <div className="form-group col-md-12 pt-2" key={option._id}>
                    <label className="control-label">{option.label}</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register(`${option._id}`)}
                      name={option._id}
                      defaultValue={option.percent}
                    />
                    {errors[`${option._id}`] && <span className="errMsg">{errors[`${option._id}`].message}</span>}
                  </div>
                ))
              }
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default AddStaking;
