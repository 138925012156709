
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { getCryptoPairs } from '../../../../redux/cryptoPair/cryptoPairActions';
import ShiftDataTable from './ShiftDataTable';
import LeverageDataTable from './LeverageDataTable';
import StakingDataTable from './StakingDataTable';
import { SystemSettingsTypes } from '../../../../types/systemSettings';

export function TradingSettings() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const isLeverageChangeEnabled = useSelector((state) => state.systemSettingsReducer.systemSettings.find((setting) => setting.name === SystemSettingsTypes.IS_LEVERAGE_CHANGE_ENABLED)?.value);


  const [key, setKey] = useState('shift');
  const isUserCanEditLeverage = permissionName && permissionName.includes('edit_leverage');
  const isUserCanSeeStaking = permissionName && permissionName.includes('staking_management');

  useEffect(() => {
    dispatch(getCryptoPairs());
  }, []);

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="shift" title="Shift">
        <ShiftDataTable id={id} />
      </Tab>
      {
        isUserCanEditLeverage && !isLeverageChangeEnabled && (
          <Tab eventKey="leverage" title="Leverage">
            <LeverageDataTable id={id} />
          </Tab>
        )
      }
      {
        isUserCanSeeStaking && (
          <Tab eventKey="staking" title="Staking">
            <StakingDataTable id={id} />
          </Tab>
        )
      }
    </Tabs>
  );
};
