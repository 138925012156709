import React, {
  useState, useMemo, useEffect, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Button, Row, Col, InputGroup, DropdownButton, Form, 
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { createSetting, updateSetting } from '../../../../redux/tradingSetting/tradingSettingActions';
import { ModalContext } from '../../../../context';
import './style.css';

export function TradingSettingsModal({ userId, data }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const {
    _id = '', pair = '', shift = '', isPositiveV = true, isCreate = true, 
  } = data;
  const [selectedPair, selectPair] = useState(pair);
  const [shiftValue, setShiftValue] = useState(String(shift));
  const [isPositive, setIsPositive] = useState(isPositiveV);

  const pairs = useSelector((state) => state.cryptoPairs.pairNames);

  useEffect(() => {
    selectPair(pair);
    setIsPositive(isPositiveV);
    setShiftValue(String(shift));
  }, [data]);

  const isBtnDisabled = useMemo(() => !selectedPair.length || !shiftValue.length, [selectedPair, shiftValue]);

  const closeModal = () => {
    selectPair('');
    setIsPositive(true);
    setShiftValue('');
    hideModal();
  };

  const submit = async () => {
    if (shiftValue < 0) {
      toast.warning('Shift value should not be less then 0');
  
      return;
    }

    const data = {
      userId,
      pairName: selectedPair,
      shift: shiftValue,
      isPositive,
    };

    await dispatch(isCreate ? createSetting(data) : updateSetting(_id, data));
    closeModal();
  };

  return (
    <div className="withdrawal-data">
      <Row>
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Pair Name</label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" disabled={!isCreate} variant="">{selectedPair.length ? selectedPair : 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {pairs.map((pair) => (
                  <Dropdown.Item key={pair} onClick={() => { selectPair(pair); }}>{pair}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mt-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2">Shift Value (in percent %)</label>
            <div className="input-wrapper">
              <InputGroup className="mb-3 t-settings_input-group">
                <DropdownButton
                  variant="outline-secondary"
                  title={isPositive ? '+' : '-'}
                  id="input-group-dropdown-1"
                >
                  <Dropdown.Item onClick={() => setIsPositive(true)}>+</Dropdown.Item>
                  <Dropdown.Item onClick={() => setIsPositive(false)}>-</Dropdown.Item>
                </DropdownButton>
                <Form.Control 
                  value={shiftValue} 
                  onChange={({ target: { value } }) => setShiftValue(value)} 
                  type="number" 
                  aria-label="Text input with dropdown button"
                />
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>{isCreate ? 'Add' : 'Edit'}</Button>
        </Col>
      </Row>
    </div>
  );
};
