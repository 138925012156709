import React from 'react';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';

export function EntryPriceModal(
  {
    showFutureStartPrice,
    handleCloseFutureStartPrice,
    selectedRow,
    futureStartPrice,
    setFutureStartPrice,
    updateFutureTradeStartprice,
  },
) {
  return (
    <Modal
      className="withdrawal-modal limit-modal tp-sl-modal"
      show={showFutureStartPrice}
      centered
      onHide={handleCloseFutureStartPrice}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="buy-tabs">
          <div className="mb-4">
            <span>
              Start Trading Price
              {selectedRow?.fromCurrency?.symbol}
            </span>
          </div>
          <div className="stop-loss-usdt">
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                placeholder="Start Trading Price"
                min="0"
                value={futureStartPrice}
                onChange={(e) => setFutureStartPrice(e.target.value)}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.fromCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => updateFutureTradeStartprice()}>
          Confirm
        </Button>
        <Button variant="danger" onClick={handleCloseFutureStartPrice}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
