import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getCryptoSetting, editCryptoSetting, updateCryptoSettingState } from '../../redux/cryptoSetting/cryptoSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function EditCryptoSetting() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [cryptoSetting, setCryptoSetting] = useState('');

  const error = useSelector((state) => state.cryptoSetting?.cryptoSettingError);
  const cryptoSettingData = useSelector((state) => state.cryptoSetting?.cryptoSetting);
  const cryptoSettingFetched = useSelector((state) => state.cryptoSetting?.cryptoSettingFetched);
  const cryptoSettingEditted = useSelector((state) => state.cryptoSetting?.cryptoSettingEditted);

  const {
    register, handleSubmit, control, reset, formState: { errors }, 
  } = useForm();

  useEffect(() => {
    reset();
    setLoader(true);
    dispatch(getCryptoSetting(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(cryptoSettingData)?.length && cryptoSettingFetched) {
      setCryptoSetting(cryptoSettingData);
      reset(cryptoSettingData);
      dispatch(updateCryptoSettingState());
      setLoader(false);
    }
  }, [cryptoSettingData, cryptoSettingFetched]);

  useEffect(() => {
    if (cryptoSettingEditted) {
      dispatch(updateCryptoSettingState());
      setLoader(false);
      history.goBack();
    }
  }, [cryptoSettingEditted]);

  useEffect(() => {
    if (error) {
      setLoader(false);
      dispatch(updateCryptoSettingState());
    }
  }, [error]);

  const handleEditCryptoSetting = (formData) => {
    setLoader(true);
    const data = {
      alias: formData.alias || '',
      symbol: formData.symbol || '',
      rateSymbol: formData.rateSymbol || '',
      leverage: formData.leverage || 0,
      lotStep: formData.lotStep ? Number(formData.lotStep) : 0,
      lotSize: formData.lotSize || 0,
    };
    dispatch(editCryptoSetting(id, data));
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          <h3>
            Edit Settings For
            {' '}
            {cryptoSetting ? `${cryptoSetting?.symbol}` : ''}
          </h3>
          <form onSubmit={handleSubmit(handleEditCryptoSetting)}>
            <div className="form-group col-md-12">
              <label className="control-label">Base Currency</label>
              <input type="text" className="form-control" defaultValue={cryptoSetting?.secondaryCurrency?.symbol} disabled />
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('symbol')}
                name="symbol"
                control={control}
                defaultValue={cryptoSetting?.symbol}
              />
              {errors?.symbol && <span className="errMsg">{errors.symbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Alias</label>
              <input
                type="text"
                className="form-control"
                {...register('alias')}
                name="alias"
                control={control}
                defaultValue={cryptoSetting?.alias}
              />
              {errors?.alias && <span className="errMsg">{errors.alias.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Rate Symbol</label>
              <input
                type="text"
                className="form-control"
                {...register('rateSymbol')}
                name="rateSymbol"
                control={control}
                defaultValue={cryptoSetting?.rateSymbol}
              />
              {errors?.rateSymbol && <span className="errMsg">{errors.rateSymbol.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Leverage</label>
              <input
                type="number"
                className="form-control"
                {...register('leverage')}
                name="leverage"
                control={control}
                defaultValue={cryptoSetting?.leverage}
              />
              {errors?.leverage && <span className="errMsg">{errors.leverage.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Step</label>
              <input
                type="text"
                className="form-control"
                {...register('lotStep')}
                name="lotStep"
                control={control}
                defaultValue={cryptoSetting?.lotStep}
              />
              {errors?.lotStep && <span className="errMsg">{errors.lotStep.message}</span>}
            </div>
            <div className="form-group col-md-12 pt-2">
              <label className="control-label">Lot Size</label>
              <input
                type="number"
                className="form-control"
                {...register('lotSize')}
                name="lotSize"
                control={control}
                defaultValue={cryptoSetting?.lotSize}
              />
              {errors?.lotSize && <span className="errMsg">{errors.lotSize.message}</span>}
            </div>
            <div>
              <button className="btn btn-default" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    )
  );
}

export default EditCryptoSetting;
