export const userTradeHistoryColumns = [
  {
    name: 'Type',
    selected: true,
  },
  {
    name: 'Trading Pair',
    selected: true,
  },
  {
    name: 'Trading Type',
    selected: true,
  },
  {
    name: 'Trading Volume',
    selected: true,
  },
  {
    name: 'Profit/Loss',
    selected: true,
  },
  {
    name: 'Commission',
    selected: true,
  },
  {
    name: 'Start Time',
    selected: true,
  },
  {
    name: 'End Time',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
