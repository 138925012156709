import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { deleteOtherPsp, updateOtherPsp } from '../redux/pspOthers/pspOthersActions';

export const OtherPspSchema = (OtherPspModal, showModal) => {
  const dispatch = useDispatch();

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOtherPsp(id));
      }
    });
  };
  
  const handleChangeStatus = async (e, id) => {
    if (e.target.value) {
      const formDataToSend = new FormData();
      formDataToSend.append('status', e.target.value);
      await dispatch(updateOtherPsp(id, formDataToSend));
    }
  };

  const columns = [
    {
      name: 'Other PSP',
      cell: (row) => row.label,
    },
    {
      name: 'Image',
      cell: (row) => (<img src={`${process.env.REACT_APP_SERVER_URL}/images/${row.imageUrl}`} alt="row.img" height="40px" />),
    },
    {
      name: 'Brands',
      cell: (row) => row.brendIds,
    },
    {
      name: 'Status',
      cell: (row) => (
        <select 
          className="form-control user-status-select leads-status-select"
          name="status"
          defaultValue={row.status}
          onChange={(e) => handleChangeStatus(e, row._id)}
        >
          <option value>Active</option>
          <option value={false}>Inactive</option>
        </select>
      ),
    },
    {
      name: 'Referal Link',
      cell: (row) => row.url,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="action-btn">
          <button
            type="button"
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({
              bodyContent: <OtherPspModal row={row} type="edit" />,
              headerContent: <h3>Edit PSP</h3>, 
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteAction(row._id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
