/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCryptoAssetsDetail,
  updateTransactionState,
} from '../../../redux/transactions/transactionActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
// import { CanvasGraph } from './CanvasGraph';
import { GetCoinImg } from '../../../helpers/getCoinImg';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';

function CryptoCharts() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const transactionAssets = useSelector((state) => state?.transaction?.assetDetails);
  const fetchedAssetDetails = useSelector((state) => state?.transaction?.fetchedAssetDetails);
  const currencyRates = useSelector((state) => state.currencyRates);

  useEffect(() => {
    setLoader(true);

    Promise.allSettled([
      dispatch(getCurrencyRates('USD')),
      dispatch(getCryptoAssetsDetail()),
    ]);
  }, []);

  useEffect(() => {
    if (fetchedAssetDetails) {
      setLoader(false);
      dispatch(updateTransactionState());
    }
  }, [dispatch, fetchedAssetDetails]);

  const getRateInUsdt = (coin_symbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const total_in_usdt = parseFloat(
        parseFloat(1 / currencyRates[coin_symbol === 'USDT' ? 'USD' : coin_symbol])
          * parseFloat(amount),
      );

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(total_in_usdt)) {
        return total_in_usdt;
      }
      return null;
    }
    return '-';
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div style={{
        display: 'flex', gap: '24px', marginBottom: '20px', overflowX: 'auto', paddingBottom: '10px',
      }}
      >
        {transactionAssets?.length > 0 ? (
          transactionAssets?.map((item) => (
            item.currency ? (
              <Link
                to={item?.isFiat ? '/fiat-currency' : '/currency'}
                style={{
                  minWidth: '387px',
                  height: '380px',
                  backgroundColor: '#0f1323',
                  color: 'white',
                  padding: '28px',
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  borderRadius: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
                key={item?.currencyId}
              >
                <h5 style={{ fontWeight: 600, marginBottom: '10px', fontSize: '28px' }}>{item?.currencyName}</h5>
                <div style={{ fontSize: '14px', color: '#E5E6ED' }}>Asset Balance</div>
                <h4 style={{ fontWeight: 700 }}>{item?.totalAmount?.toFixed(2)}</h4>
                <p style={{ color: '#11CABE', fontSize: '20px' }}>
                  $
                  {' '}
                  {getRateInUsdt(item?.currency, item?.totalAmount)?.toFixed(2)}
                  {' '}
                  USD
                </p>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '55px' }}
                >
                  <img
                    src={GetCoinImg(item?.currency)}
                    alt={item?.currency}
                    style={{ width: '50px', height: '50px' }}
                  />
                  <div>
                    <div style={{ fontSize: '16px' }}>{item?.currencyName}</div>
                    <h5 style={{ fontWeight: 700 }}>
                      USD
                      {' '}
                      {getRateInUsdt(item?.currency, 1)?.toFixed(4)}
                    </h5>
                  </div>
                  <p style={{ color: 'grey' }}>{item?.currency}</p>
                </div>
                {/* <CanvasGraph currency={item?.currency} /> */}
              </Link>
            ) : null
          ))
        ) : (
          <></>
        )}
      </div>
    )
  );
}

export default CryptoCharts;
