/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { getSubCRMUsers } from '../../../redux/crmUser/crmUserActions';
import { getCRMActivities, updateCRMActivityState } from '../../../redux/crmActivity/crmActivityActions';
import { getActivityTypes } from '../../../redux/activityType/activityTypeActions';

export function CRMUSerActivity({ uid }) {
  const dispatch = useDispatch();

  const [crmActivities, setCRMActivities] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [activityTypesToShow, setActivityTypesToShow] = useState([]);
  const [isCheckActivityType, setIsCheckActivityType] = useState([]);
  const [isCheckAssignTo] = useState([]);
  const [, setAgentUsers] = useState([]);
  const [, setAssignedTo] = useState(null);
  const [, setAssignToShow] = useState([]);
  const [, setAssignToDropdownShow] = useState(false);
  const [clientFullname, setClientFullname] = useState('');
  const [activityTypeInput, setActivityTypeInput] = useState('');
  const [activityDropdownShow, setActivityDropdownShow] = useState(false);
  const [, setIsCheckAllActivityTypes] = useState(false);

  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const activityTypes = useSelector((state) => state?.activityType?.activityTypes);
  const userCRMActivities = useSelector((state) => state?.crmActivity?.crmActivities);
  const crmActivitiesFetched = useSelector((state) => state?.crmActivity?.crmActivitiesFetched);

  // console.log(crmActivities.filter((item) => uid === item.crmUserId));

  useEffect(() => {
    const fetchData = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = data?.roleId;
      const uid = data?._id;

      await dispatch(getSubCRMUsers(roleId, uid));
      await dispatch(getActivityTypes());
      await dispatch(getCRMActivities());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (crmActivitiesFetched) {
      dispatch(updateCRMActivityState());

      if (userCRMActivities?.length > 0) {
        setCRMActivities(userCRMActivities.filter((item) => item.crmUserId === uid));
        setFilteredItems(userCRMActivities.filter((item) => item.crmUserId === uid));
      } else {
        setCRMActivities([]);
        setFilteredItems([]);
      }
    }
  }, [userCRMActivities, crmActivitiesFetched]);

  useEffect(() => {
    if (activityTypes?.length > 0) {
      setActivityTypesToShow(activityTypes);
    }
  }, [activityTypes]);

  const handleMultiSelectFilter = () => {
    const filteredItemsResult = crmActivities?.filter(
      (item) => {
        const isCheckActivityTypeLength = isCheckActivityType?.length;
        const isCheckAssignToLength = isCheckAssignTo?.length;
        const isActivityTypeInclude = isCheckActivityType?.includes(item?.activityTypeId);
        const isAssignToInclude = isCheckAssignTo?.includes(item?.crmUserId);

        if (isCheckActivityTypeLength > 0 && isCheckAssignToLength > 0) {
          return isActivityTypeInclude && isAssignToInclude;
        } if (isCheckActivityTypeLength > 0 && isCheckAssignToLength < 1) {
          return isActivityTypeInclude;
        } if (isCheckActivityTypeLength < 1 && isCheckAssignToLength > 0) {
          return isAssignToInclude;
        }
        return true;
      },
    );

    setFilteredItems([...filteredItemsResult]);
  };

  useEffect(() => {
    handleMultiSelectFilter();
  }, [isCheckActivityType, isCheckAssignTo]);

  useEffect(() => {
    if (crmUsers?.length > 0) {
      const allAgents = crmUsers?.map((agen) => ({ label: `${agen.firstName} ${agen.lastName}`, value: agen._id }));
      setAgentUsers(allAgents);
      setAssignToShow(crmUsers);
      setAssignedTo({ label: allAgents?.[0]?.label, value: allAgents?.[0]?.value });
    }
  }, [crmUsers]);

  const padTo2Digits = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => (
    `${[
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-')
    } ${
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')}`
  );

  const searchByName = (e) => {
    setClientFullname(e.target.value);
    const searchText = e.target.value;
    const searchData = crmActivities.filter(
      (row) => (
        row?.client?.firstName?.toLowerCase()?.includes(searchText.toLowerCase())
            || row?.client?.lastName?.toLowerCase()?.includes(searchText.toLowerCase())
      ),
    );
    setFilteredItems(searchData);
  };

  const handleActivityTypeDropdownShow = () => {
    setActivityDropdownShow(!activityDropdownShow);
    setAssignToDropdownShow(false);
  };

  const activityTypeInputChangeHandler = async (e) => {
    setActivityTypeInput(e.target.value);
    const activityItems = await activityTypes?.filter(
      (item) => item?.name && item?.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setActivityTypesToShow(activityItems);
  };

  const handleSelectAllActivityTypes = (e, allChecked) => {
    setIsCheckAllActivityTypes(allChecked);
    setIsCheckActivityType(activityTypes?.map((li) => li._id));
    if (!allChecked) {
      setIsCheckActivityType([]);
    }
  };

  const handleActivityTypeClick = (e) => {
    const { value, checked } = e.target;
    setIsCheckActivityType([...isCheckActivityType, value]);
    if (!checked) {
      setIsCheckActivityType(isCheckActivityType.filter((item) => item !== value));
    }
  };

  const crmUserActivityCol = [
    {
      name: 'Time',
      selector: (row) => (
        <>
          <span data-tip={formatDate(new Date(row?.createdAt))}>
            {formatDate(new Date(row?.createdAt))}
          </span>
          <ReactTooltip />
        </>
      ),
      sortable: true,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des">
          Client
          <input
            type="text"
            placeholder="Search"
            name="clientFullname"
            value={clientFullname}
            onChange={(e) => searchByName(e)}
          />
        </div>
      ),
      selector: (row) => {
        if (row?.client?._id) {
          return `${row?.client?.firstName} ${row?.client?.lastName}`;
        }
        return '-';
      },
      cell: (row) => {
        if (row?.userId) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_user') ? (
              <span
                data-tip={`${row?.client?.firstName} ${row?.client?.lastName}`}
                className="full-name-text"
              >
                {row?.client?.firstName}
                {' '}
                {row?.client?.lastName}
              </span>
            ) : (
              <span>{`${row?.client?.firstName} ${row?.client?.lastName}`}</span>
            );
        }
        return '-';
      },
      sortable: false,
    },
    {
      name: (
        <div>
          Activity
          <div className="">
            <div
              className="dropdown search-custom-dropdown dropdown-check-nostyle crm-search-dropdown"
              style={{ position: 'unset' }}
            >
              <button
                type="button"
                onClick={handleActivityTypeDropdownShow}
                className="dropbtn text-start"
              >
                Search
              </button>
              <div
                id="myDropdown1"
                className={`dropdown-content ${
                  activityDropdownShow ? 'show' : ''
                }`}
              >
                <input
                  type="text"
                  placeholder="Filter"
                  value={activityTypeInput}
                  id="myInput"
                  onChange={activityTypeInputChangeHandler}
                />
                <div className="btn-group">
                  <button
                    type="button"
                    className="actions-btn "
                    onClick={(e) => handleSelectAllActivityTypes(e, true)}
                  >
                    Check all
                  </button>
                  <button
                    type="button"
                    className="actions-btn"
                    onClick={(e) => handleSelectAllActivityTypes(e, false)}
                  >
                    Uncheck all
                  </button>
                </div>
                <div className="search-options">
                  {activityTypesToShow?.length > 0
                    ? activityTypesToShow?.map((currAct) => (
                      <>
                        <input
                          className="styled-checkbox"
                          id={`styled-checkbox-${currAct?._id}`}
                          type="checkbox"
                          value={currAct?._id}
                          checked={
                              isCheckActivityType?.includes(currAct?._id)
                              || false
                            }
                          onChange={(e) => handleActivityTypeClick(e)}
                        />
                        <label htmlFor={`styled-checkbox-${currAct?._id}`}>
                          {currAct?.name}
                        </label>
                      </>
                    ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      minWidth: '190px',
      selector: (row) => (row?.activityType ? row?.activityType?.name : ''),
      cell: (row) => <span>{row?.activityType?.name}</span>,
    },
    {
      name: 'Information',
      selector: (row) => row?.information,
      cell: (row) => <span>{row?.information}</span>,
      sortable: true,
    },
  ];

  return (
    <div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        <DataTable
          title="Activity Log"
          rows="1"
          columns={crmUserActivityCol}
          data={filteredItems}
          pagination
          highlightOnHover
          paginationRowsPerPageOptions={[10, 50, 100, 500]}
          persistTableHead
          selectableRowsHighlight
          selectableRowsVisibleOnly
          theme="solarizedd"
          className="specific-field-table custome-table-scroll"
        />
      </div>
    </div>
  );
}
