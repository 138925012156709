import React, { useEffect, useState } from 'react';
import {
  convertObjectToArray, handleAddData, handleKeyChange, handleValueChange, 
} from '../../../helpers/smsHelpers';
import styles from './AdditionalParamsComponent.module.css';

function AdditionalParamsComponent({
  onAdditionalParamsChange,
  defaultAdditionalParams = [],
}) {
  const [authData, setAuthData] = useState(convertObjectToArray(defaultAdditionalParams));

  useEffect(() => {
    const authDataObject = authData.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {});

    onAdditionalParamsChange(authDataObject);
  }, [authData]);

  return (
    <div className="form-group">
      <label className="lead">
        Additional Params
        <button
          className={styles.addBtn}
          type="button"
          onClick={() => handleAddData(authData, setAuthData, '')}
        >
          Add+
        </button>
      </label>
      <div className={styles.wrapper}>
        {
          authData.map((data, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={styles.itemWrapper}>
              <input
                type="text"
                className={styles.inputKey}
                value={data.key}
                placeholder="Key"
                onChange={(e) => handleKeyChange(index, e.target.value, authData, setAuthData)}
              />
              <input
                type="text"
                className={styles.inputValue}
                value={data.value}
                placeholder="Value"
                onChange={(e) => handleValueChange(index, e.target.value, authData, setAuthData)}
              />
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default AdditionalParamsComponent;
