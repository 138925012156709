import React, {
  useState, useMemo, useEffect, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TradingColumnsSchema } from '../../../DataTableSchemas/TradingColumnsSchema';
import { revertLeverageOrder, editLeverageHistoryOrder, getUserLeverageOrders } from '../../../redux/leverageOrder/leverageOrderActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { DatatableColumns, DatatableFilters } from '../../../components';
import { userTradeHistoryColumns } from '../../../columnsDefaultConfig';

function TradeHistory() {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const { id } = useParams();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const userOrders = useSelector((state) => state.LeverageOrders?.userOrders);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const [tradeHistoryTimeOpen, setTradeHistoryTimeOpen] = useState('');
  const [tradeHistoryTimeClose, setTradeHistoryTimeClose] = useState('');
  const [, setIsCheckCommentErr] = useState('');

  const [selectedHistoryOrderRow, setSelectedHistoryOrderRow] = useState('');
  const [showEditTradeHistory, setShowEditTradeHistory] = useState(false);
  const [tradeHistoryPriceOpen, setTradeHistoryPriceOpen] = useState(0);
  const [tradeHistoryPriceClose, setTradeHistoryPriceClose] = useState(0);
  const [tradeHistoryVolume, setTradeHistoryVolume] = useState(0);
  const [tradeHistoryProfit, setTradeHistoryProfit] = useState(0);
  const handleCloseEditTradeHistory = () => setShowEditTradeHistory(false);
  const handleShowEditTradeHistory = () => setShowEditTradeHistory(true);

  const columnsStorageName = 'DataTable_user/trade-history_columns';
  const filtersStorageName = 'DataTable_user/trade-history_filters';
  const filterIdStorageName = 'DataTable_user/trade-history_filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(userTradeHistoryColumns);
  const [filters, setFilters] = useState();

  const datatableFiltersRef = useRef(null);

  const [filteredResult, setFilteredResult] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [pairsFilter, setPairsFilter] = useState([]);
  const [tradingTypeFilter, setTradingTypeFilter] = useState([]);

  const tradeHistory = useMemo(() => (userOrders ? userOrders.filter((row) => row.isResolved !== false && row.status !== 0 && row.status !== 1) : []), [userOrders]);

  const tradingPairs = useMemo(() => {
    const uniquePairs = [];
    const pairs = [];
    if (!tradeHistory || !tradeHistory.length) return pairs;

    tradeHistory.forEach(({ pairName }) => {
      if (!uniquePairs.includes(pairName)) {
        uniquePairs.push(pairName);
        pairs.push({
          name: pairName,
          _id: pairName,
        });
      } 
    });

    return pairs;
  }, [tradeHistory]);

  const search = () => {
    const filteredResults = tradeHistory.filter(
      (item) => (!typeFilter.length || typeFilter.includes(String(item.marginType)))
        && (!pairsFilter.length || pairsFilter.includes(item.pairName))
        && (!tradingTypeFilter.length || tradingTypeFilter.includes(String(item.tradeType))),
    );

    setFilteredResult(filteredResults);
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setTypeFilter(filters.type || []);
      setPairsFilter(filters['trading pair'] || []);
      setTradingTypeFilter(filters['trading type'] || []);
    }
  };

  const handleClear = () => {
    setTypeFilter([]);
    setTradingTypeFilter([]);
    setPairsFilter([]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const { currency, depositType, status } = filter;

    setTypeFilter(depositType);
    setTradingTypeFilter(status);
    setPairsFilter(currency);
    setFilters({
      'trading pair': currency,
      type: depositType,
      'trading type': status,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/trading-history`,
      currency: filters['trading pair'] || [],
      depositType: filters.type || [],
      status: filters['trading type'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters['trading pair'] || [],
        type: filters.type || [],
        status: filters['trading type'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    if (tradeHistory.length) search();
    if (filters) localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  }, [typeFilter, pairsFilter, tradingTypeFilter]);

  useEffect(() => {
    setFilteredResult(tradeHistory);
    setStoredFilterData();
  }, [tradeHistory]);

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  const getTradesHistory = () => dispatch(getUserLeverageOrders(id));

  useEffect(() => {
    getTradesHistory();
  }, [id]);

  useEffect(() => {
    setStoredColumnsData();
  }, []);

  const handleTradeHistoryVolume = (e) => {
    const historyVolume = e.target.value;

    // Profit
    const historyProfit = (tradeHistoryPriceClose - tradeHistoryPriceOpen) * historyVolume;

    setTradeHistoryVolume(historyVolume);
    setTradeHistoryProfit(historyProfit);
  };

  const handleTradeHistoryPriceOpen = (e) => {
    const historyPriceOpen = e.target.value;

    // Profit
    const historyProfit = (tradeHistoryPriceClose - historyPriceOpen) * tradeHistoryVolume;

    setTradeHistoryPriceOpen(historyPriceOpen);
    setTradeHistoryProfit(historyProfit);
  };

  const handleTradeHistoryPriceClose = (e) => {
    const historyPriceClose = e.target.value;

    // Profit
    const historyProfit = (historyPriceClose - tradeHistoryPriceOpen) * tradeHistoryVolume;

    setTradeHistoryPriceClose(historyPriceClose);
    setTradeHistoryProfit(historyProfit);
  };

  const handleTradeHistoryProfit = (e) => {
    const historyProfit = e.target.value;

    // Price Close
    const historyPriceClose = (historyProfit / tradeHistoryVolume) + tradeHistoryPriceOpen;

    setTradeHistoryPriceClose(historyPriceClose);
    setTradeHistoryProfit(historyProfit);
  };

  const handleEditTradeHistory = () => {
    const data = { ...selectedHistoryOrderRow };

    data.initiatedAt = new Date(tradeHistoryTimeOpen);
    data.closedAt = new Date(tradeHistoryTimeClose);
    data.tradeStartPrice = parseFloat(tradeHistoryPriceOpen);
    data.exitPrice = parseFloat(tradeHistoryPriceClose);
    data.qty = parseFloat(tradeHistoryVolume);
    data.diffInProfitOrLoss = parseFloat(data.tradeProfitOrLoss - tradeHistoryProfit);
    data.tradeProfitOrLoss = parseFloat(tradeHistoryProfit);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;
    data.currentUserId = id;
    dispatch(editLeverageHistoryOrder(data?._id, data));
    handleCloseEditTradeHistory();
    // setLoader(true);
  };

  const handleRevertTradeHistory = async (e, orderId) => {
    e.preventDefault();
    setIsCheckCommentErr('');
    Swal.fire({
      title: 'Are you sure you want to Revert the order?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(revertLeverageOrder(orderId, id));
      }
    });
  };

  const tradingColumns = TradingColumnsSchema(
    handleRevertTradeHistory,
    setSelectedHistoryOrderRow,
    setTradeHistoryPriceOpen,
    setTradeHistoryPriceClose,
    setTradeHistoryVolume,
    setTradeHistoryProfit,
    setTradeHistoryTimeOpen,
    setTradeHistoryTimeClose,
    handleShowEditTradeHistory,
    columnConfig,
    typeFilter,
    setTypeFilter,
    tradingPairs,
    pairsFilter,
    setPairsFilter,
    tradingTypeFilter,
    setTradingTypeFilter,
    filters,
    setFilters,
  );

  return (
    <>
      <div>
        <div className="action__btn-row">
          {
            crmFilters ? (
              <DatatableFilters 
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={`${pathname}/trading-history`}
              /> 
            ) : null
          }
          <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={getTradesHistory}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {tradeHistory && tradeHistory.length
            ? (
              <DataTable
                columns={tradingColumns}
                data={filteredResult}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                fixedHeader
                persistTableHead
                theme="solarizedd"
              />
            )
            : (
              <div className="no-tbl-data">No Transactions Found!</div>
            )}
        </div>
      </div>

      <Modal className="withdraw-details two-factor-auth text-center edit-trade-history-modal" centered backdrop="static" size="lg" show={showEditTradeHistory} onHide={handleCloseEditTradeHistory}>
        <Modal.Header className="modal-main-heading" closeButton />
        <Modal.Body>
          <div className="mb-1"><h4>Edit Order</h4></div>
          {/* <form className="mb-3" onSubmit={handleSubmit(handleSave)}> */}
          <div className="row mb-3">
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Price Open</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Price Open"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryPriceOpen}
                onChange={(e) => { handleTradeHistoryPriceOpen(e); }}
              />
            </div>
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Price Close</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Price Closed"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryPriceClose}
                onChange={(e) => { handleTradeHistoryPriceClose(e); }}
              />
            </div>
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Volume</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Volume"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryVolume}
                onChange={(e) => { handleTradeHistoryVolume(e); }}
              />
            </div>
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Profit</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Profit"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryProfit}
                onChange={(e) => { handleTradeHistoryProfit(e); }}
              />
            </div>
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Time Opened</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Time Opened"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryTimeOpen?.replace('T', ' ')?.replace('Z', ' ')}
                onChange={(e) => { setTradeHistoryTimeOpen(e.target.value); }}
              />
            </div>
            <div className="form-group col-md-6 mt-2">
              <label className="control-label">Time Closed</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Time Closed"
                aria-label=""
                aria-describedby=""
                value={tradeHistoryTimeClose?.replace('T', ' ')?.replace('Z', ' ')}
                onChange={(e) => { setTradeHistoryTimeClose(e.target.value); }}
              />
            </div>
          </div>
          {/* </form> */}
          <div>
            <button type="button" className="btn btn-default me-2" onClick={() => { handleEditTradeHistory(); }}>Save</button>
            <button type="button" onClick={handleCloseEditTradeHistory} className="btn btn-danger">Cancel</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TradeHistory;
