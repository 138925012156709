import { toast } from 'react-toastify';
import {
  GET_CRYPTO_SETTINGS,
  GET_CRYPTO_SETTING,
  ADD_CRYPTO_SETTING,
  EDIT_CRYPTO_SETTING,
  DELETE_CRYPTO_SETTING,
  TOGGLE_CRYPTO_SETTING_STATE,
  ERROR_CRYPTO_SETTING_STATE,
} from './cryptoSettingTypes';
import { apiHelper } from '../apiHelper';
  
export const getCryptoSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/crypto-setting', '');
    const payload = res?.data?.cryptoSettings || [];
    dispatch({
      type: GET_CRYPTO_SETTINGS,
      payload,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const getCryptoSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/crypto-setting/${id}`, '');
    if (res?.data && res?.data?.cryptoSetting) {
      const { data } = res;
      dispatch({
        type: GET_CRYPTO_SETTING,
        payload: data?.cryptoSetting,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const addCryptoSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/crypto-setting/add', data);
  
    if (res?.data && res.data?.cryptoSetting_) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: ADD_CRYPTO_SETTING,
        payload: data?.cryptoSetting_,
      });
    } else {
      dispatch({
        type: ERROR_CRYPTO_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_CRYPTO_SETTING_STATE,
    });
    toast.error(error.message);
  }
};
  
export const editCryptoSetting = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/crypto-setting/${id}`, bodyData);
    if (res?.data && res?.data?.cryptoSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: EDIT_CRYPTO_SETTING,
        payload: data?.cryptoSetting,
      });
    } else {
      dispatch({
        type: ERROR_CRYPTO_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_CRYPTO_SETTING_STATE,
    });
    toast.error(error.response.message);
  }
};
  
export const deleteCryptoSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/crypto-setting/${id}`, '');
    if (res?.data && res?.data?.cryptoSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      dispatch({
        type: DELETE_CRYPTO_SETTING,
        payload: data?.cryptoSetting?._id,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
  
export const updateCryptoSettingState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_CRYPTO_SETTING_STATE,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
