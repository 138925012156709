/* eslint-disable react/no-danger */
import React from 'react';
import logoImage from '../../../../../assets/images/emailTemplates/logoPlaceholder.png';
import styles from './PreviewComponent.module.css';

function PreviewComponent({ HTMLWithProperClass }) {
  return (
    <div className={styles.wrapper}>
      <div className="col-xl-12 col-lg-12">
        <center className={styles.emailWrapper}>
          <table className={styles.main} width="100%">
            <tr>
              <td>
                <a
                  href="%siteURL%"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={logoImage}
                    alt="logo"
                    className={styles.bigLogo}
                  />
                </a>
              </td>
            </tr>

            <tr>
              <td>
                <div dangerouslySetInnerHTML={{ __html: HTMLWithProperClass }} />
              </td>
            </tr>
          </table>

          <table className={styles.footer}>
            <tr>
              <td>
                <p style={{
                  fontWeight: 400,
                  fontSize: '14px',
                  marginBottom: 0,
                }}
                >
                  Best regards,
                </p>
                <p style={{ fontWeight: 700 }}>
                  %companyName% Team
                </p>
                <img
                  className={styles.smallLogo}
                  src={logoImage}
                  alt="logo"
                />
                <p style={{ fontSize: '13px', lineHeight: '13px' }}>
                  © 2017 - 
                  {' '}
                  {(new Date()).getFullYear()}
                  <a
                    href="%siteUrl%"
                    target="_blank"
                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                  >
                    {' '}
                    %siteUrl%
                  </a>
                  ,
                  {' '}
                  All Rights Reserved.
                </p>
              </td>
            </tr>
          </table>
        </center>
      </div>
    </div>
  );
}

export default PreviewComponent;
