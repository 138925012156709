import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button, 
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateActiveOrder } from '../../redux/leverageOrder/leverageOrderActions';
import { addShiftToPrice } from '../../helpers/helper';
import TradeTypeSelect, { TradeTypes } from '../TradeTypeSelect';
import { getTradeType } from '../../helpers/tradeType';

export function FutureOrderEditModal({
  isShown, hideModal, data, currentPrice,
}) {
  const currentSymbolPrice = currentPrice ? Number(currentPrice?.find((line) => line.symbol === data?.pairName)?.markPrice) : 0;
  const dispatch = useDispatch();

  const entryPriceRef = useRef(null);
  const tpRef = useRef(null);
  const slRef = useRef(null);
  const trailingStopRef = useRef(null);

  const [tradeType, setTradeType] = useState(() => getTradeType(data?.tradeType));

  const tradeTypeChange = (selectedOption) => {
    if (selectedOption) {
      setTradeType(selectedOption);
    }
  };

  useEffect(() => {
    const tradeType = data?.tradeType;
    if (tradeType === undefined) {
      return;
    }
    setTradeType(getTradeType(tradeType));
  }, [data]);

  const handleSave = () => {
    const updatedData = {};
    const entryPrice = entryPriceRef.current.value;
    const tp = tpRef.current.value;
    const sl = slRef.current.value;
    const trailingStop = trailingStopRef.current.value;
    
    if (tradeType.value !== data.tradeType) updatedData.tradeType = tradeType.value;

    if (parseFloat(entryPrice) !== Number(parseFloat(data.tradeStartPrice).toFixed(2))) updatedData.tradeStartPrice = entryPrice;
    if (parseFloat(tp) !== parseFloat(data.takeProfitPrice || 0) || parseFloat(sl) !== parseFloat(data.stopLossPrice || 0)) {
      updatedData.tpsl = true;
      updatedData.takeProfitPrice = parseFloat(tp);
      updatedData.stopLossPrice = parseFloat(sl);
    }
    if (parseFloat(trailingStop) !== parseFloat(data.tradeTrailingPrice || 0)) {
      updatedData.tradeTrailingPrice = parseFloat(trailingStop);
      updatedData.tradeTrailingDifference = parseFloat(trailingStop) > parseFloat(data.tradeStartPrice)
        ? parseFloat(trailingStop) - parseFloat(data.tradeStartPrice)
        : parseFloat(data.tradeStartPrice) - parseFloat(trailingStop);
    }

    if (Object.keys(updatedData).length) {
      updatedData.tradeStartPrice = updatedData.tradeStartPrice || data.tradeStartPrice;
      updatedData._id = data._id;
      updatedData.userId = data.userId;
      updatedData.userInvestedAmount = data.userInvestedAmount;
      updatedData.tradeEndPrice = data.tradeEndPrice;
      updatedData.currentBuyRate = data.currentBuyRate;
      updatedData.currentSellRate = data.currentSellRate;
      updatedData.fromCurrency = data.fromCurrency._id;
      updatedData.toCurrency = data.toCurrency._id;

      dispatch(updateActiveOrder(updatedData));
      hideModal();

      return;
    } 

    toast.error('Please, put new values for editing', {
      autoClose: 1000,
    });
  };

  const getPnL = (data) => {
    let rowPnLRate = 0;
    const entryPrice = (entryPriceRef.current && entryPriceRef.current.value) || 0;
    const marketPrice = currentPrice ? currentPrice?.find((line) => line.symbol === data.pairName)?.markPrice : 0;

    if (data && data.user && data.user.settings && data.user.settings.length) {
      const setting = data.user.settings.find((s) => s.pairName === data.pairName);

      if (marketPrice && setting) rowPnLRate = addShiftToPrice(marketPrice, setting);
    } else {
      rowPnLRate = parseFloat(marketPrice || 0);
    }

    const val = tradeType === TradeTypes.BUY // buy
      ? parseFloat(data?.qty) * (parseFloat(rowPnLRate) - parseFloat(entryPrice))
      : parseFloat(data?.qty) * (parseFloat(entryPrice) - parseFloat(rowPnLRate));

    return val && !Number.isNaN(Number(val)) ? val : 0;
  };

  return (
    !!data && (
    <Modal 
      className="widthdrawal-modal"
      centered
      show={isShown}
      onHide={hideModal}
    > 
      <ModalHeader closeButton> 
        <h5>{`Edit Order #${data._id}`}</h5>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="form-group mt-2">
            <label className="control-label">Type</label>
            <TradeTypeSelect tradeType={tradeType} onTradeTypeChange={tradeTypeChange} />
          </div>
          <div className="form-group mt-2">
            <label className="control-label">Entry Price</label>
            <input
              ref={entryPriceRef}
              type="number"
              className="form-control"
              defaultValue={data.tradeStartPrice ? parseFloat(data.tradeStartPrice).toFixed(2) : 0}
            />
          </div>
          <div className="form-group mt-2">
            <label className="control-label">{`Take Profit ${data.fromCurrency?.symbol}`}</label>
            <input
              ref={tpRef}
              type="number"
              className="form-control"
              min="0"
              defaultValue={data.takeProfitPrice || 0}
            />
          </div>
          <div className="form-group mt-2">
            <label className="control-label">{`Stop Loss ${data.fromCurrency?.symbol}`}</label>
            <input
              ref={slRef}
              type="number"
              className="form-control"
              min="0"
              defaultValue={data.stopLossPrice || 0}
            />
          </div>
          <div className="form-group mt-2">
            <label className="control-label">{`Trailing Stop ${data.fromCurrency?.symbol}`}</label>
            <input
              ref={trailingStopRef}
              type="number"
              className="form-control"
              min="0"
              defaultValue={data.tradeTrailingPrice || 0}
            />
          </div>
          <div className="form-group mt-2">
            <label className="control-label">PnL</label>
            <input
              type="text"
              className="form-control"
              style={{ color: getPnL(data) >= 0 ? 'green' : 'red' }}
              disabled
              value={
                getPnL(data).toFixed(4)
              }
            />
          </div>

          <div className="form-group mt-2">
            <label className="control-label">Price</label>
            <input
              type="text"
              className="form-control"
              disabled
              value={currentSymbolPrice.toFixed(4)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={() => hideModal()}>Cancel</Button>
        <Button variant="primary" onClick={() => handleSave()}>Save</Button>
      </ModalFooter>
    </Modal>
    )
  );
}
