import React, {
  useState, useEffect, useMemo, useRef, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Modal, InputGroup, FormControl, Button,
} from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {
  getUserLeverageOrders, updateLeverageOrder, stopLeverageOrder, updateSocketOrder,
} from '../../../redux/leverageOrder/leverageOrderActions';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../../redux/crmUser/crmUserActions';
import { PendingOrderColumns } from '../../../DataTableSchemas/PendingOrderColumns';
import { userPendingOrdersColumns } from '../../../columnsDefaultConfig';
import { DatatableColumns, DatatableFilters } from '../../../components';

let localOrderBook = [];
let futures = [];
let futuresCounter = 0;

const stopped = [];
const started = [];

function PendingOrder() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState('');
  const [futureStartPrice, setFutureStartPrice] = useState(0);
  const [showFutureStartPrice, setShowFutureStartPrice] = useState(false);
  const [coinAmount, setCoinAmount] = useState(0);
  const [orderRate, setOrderRate] = useState(0);
  const [stopRate, setStopRate] = useState(0);
  const [, setOrderId] = useState('');
  const [futureOrderId, setFutureOrderId] = useState('');
  const [avbl] = useState(0);
  const [TP, setTP] = useState(0);
  const [SL, setSL] = useState(0);
  const [trailingPrice, setTrailingPrice] = useState(0);
  const [rate, setRate] = useState(0);

  /**
    * socket states
    */
  const [, setOrderBookAPI] = useState([]);
  const [, setFutureTradesAPI] = useState([]);
  const [pairName] = useState('ETHUSDT');
  const [socket, setSocket] = useState(null);
  const [currentMarketPrice, setcurrentMarketPrice] = useState(0);
  const [startSocket, setStartSocket] = useState(true);
  const [rates, setRates] = useState('');
  const [showTPSL, setShowTPSL] = useState(false);
  const [showTrailing, setShowTrailing] = useState(false);
  const [showMarket, setShowMarket] = useState(false);
  const [showFuture, setShowFuture] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [percentage, setPercentage] = useState(`${0}%`);
  const [, setLoader] = useState(false);

  const columnsStorageName = 'DataTable_user/pending-orders_columns';
  const filtersStorageName = 'DataTable_user/pending-orders_filters';
  const filterIdStorageName = 'DataTable_user/pending-orders_filter_id'; 
  const columnsJSON = localStorage.getItem(columnsStorageName);
  const filtersJSON = localStorage.getItem(filtersStorageName);
  const [columnConfig, setColumnConfig] = useState(userPendingOrdersColumns);
  const [filters, setFilters] = useState();

  const [coinPairsFilter, setCoinPairsFilter] = useState([]);
  const [amountRangeFilter, setAmountRangeFilter] = useState([0, 1000000]);

  const [filteredResult, setFilteredResult] = useState([]);

  const datatableFiltersRef = useRef(null);

  const handleShowFutureStartPrice = () => setShowFutureStartPrice(true);
  const handleShowTPSL = () => setShowTPSL(true);
  const handleCloseTPSL = () => setShowTPSL(false);
  const handleShowTrailing = () => setShowTrailing(true);
  const handleCloseTrailing = () => setShowTrailing(false);
  const handleShowMarket = () => setShowMarket(true);
  const handleShowFuture = () => setShowFuture(true);
  const handleShowLimit = () => setShowLimit(true);
  const handleCloseLimit = () => setShowLimit(false);
  const handleCloseFutureStartPrice = () => setShowFutureStartPrice(false);
  const handleCloseMarket = () => setShowMarket(false);
  const handleCloseFuture = () => setShowFuture(false);

  const userOrders = useSelector((state) => state.LeverageOrders?.userOrders);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const socketURL = process.env.REACT_APP_SOCKET_URL;

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getUserLeverageOrders(id));
    };
    fetchData();
  }, [id]);

  const handleUpdateLeverageOrderRes = (data) => {
    dispatch(updateSocketOrder(data));
  };

  useEffect(() => {
    if (socket && id) {
      socket.on(`onUpdateLeverageOrderRes${id}`, handleUpdateLeverageOrderRes);
    }
    return () => {
      socket?.off(`onUpdateLeverageOrderRes${id}`);
    };
  }, [socket, id]);

  useEffect(() => {
    let newSocket = null;
    
    if (pairName) {
      newSocket = io(socketURL, { transports: ['websocket'] });
      setSocket(newSocket);
      setStartSocket(true);
      setFutureTradesAPI([]);
      setOrderBookAPI([]);
      setcurrentMarketPrice(0);
      localOrderBook = [];
      futures = [];
      futuresCounter = 0;
    }

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      } 
    };
  }, [pairName]);

  useEffect(() => {
    if (pairName && startSocket && socket) {
      let letCurrentMarketPrice = 0;
      const getBinanceFutureTradesResponse = (response) => {
        futuresCounter += 1;
        if (futuresCounter % 20 === 0) {
          futures.unshift(response);
          if (futures.length > 20) futures.splice(20);
          setFutureTradesAPI([...futures]);
          futuresCounter = 0;
        }
      };

      const getCurrentMarketPriceResponse = (resCurrentMarketPrice) => {
        letCurrentMarketPrice = resCurrentMarketPrice;
        setcurrentMarketPrice(resCurrentMarketPrice);
      };

      const getBinanceMarketDepthResponse = (response) => {
        const filteredA = response.a?.filter((row) => parseFloat(row['1']) > 0).slice(0, 15) || [];
        const filteredB = response.b?.filter((row) => parseFloat(row['1']) > 0).slice(0, 15) || [];

        const data = {
          a: localOrderBook.a ? [...filteredA, ...localOrderBook.a] : filteredA,
          b: localOrderBook.b ? [...filteredB, ...localOrderBook.b] : filteredB,
        };

        localOrderBook = data;
        setOrderBookAPI(data);
        setRates(data.b && data.b.length ? letCurrentMarketPrice : 0);
      };

      socket.emit('getBinanceFutureTradesRequest', { pairName });
      socket.on(`getBinanceFutureTradesRequestResponse${pairName}`, getBinanceFutureTradesResponse);

      socket.emit('getCurrentMarketPriceRequest', { pairName });
      socket.on(`getCurrentMarketPriceResponse${pairName}`, getCurrentMarketPriceResponse);

      socket.emit('getBinanceMarketDepthRequest', { pairName });
      socket.on(`getBinanceMarketDepthRequest${pairName}`, getBinanceMarketDepthResponse);
    }
    return () => {
      socket?.off(`getBinanceFutureTradesRequestResponse${pairName}`);
      socket?.off(`getCurrentMarketPriceResponse${pairName}`);
      socket?.off(`getBinanceMarketDepthRequest${pairName}`);
    };
  }, [socket, pairName, startSocket]);

  const updateFutureTradeStartprice = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeStartPrice) !== parseFloat(futureStartPrice)) {
      data.tradeStartPrice = parseFloat(futureStartPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }

    handleCloseFutureStartPrice();
  };

  const updateTPSL = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.takeProfitPrice) !== parseFloat(TP) || parseFloat(data.stopLossPrice) !== parseFloat(SL)) {
      data.tpsl = true;
      data.takeProfitPrice = parseFloat(TP);
      data.stopLossPrice = parseFloat(SL);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }
    handleCloseTPSL();
  };

  const updateTrailing = () => {
    const data = { ...selectedRow };

    if (parseFloat(data.tradeTrailingPrice) !== parseFloat(trailingPrice)) {
      data.tradeTrailingPrice = parseFloat(trailingPrice);
      data.tradeTrailingDifference = parseFloat(trailingPrice) > parseFloat(data.tradeStartPrice) ? parseFloat(trailingPrice) - parseFloat(data.tradeStartPrice) : parseFloat(data.tradeStartPrice) - parseFloat(trailingPrice);
      data.fromCurrency = data.fromCurrency._id;
      data.toCurrency = data.toCurrency._id;
      dispatch(updateLeverageOrder(data, id));
      // setLoader(true);
    }

    handleCloseTrailing();
  };

  const handleLimit = (val, update = 0) => {
    if (avbl) {
      const validNumber = (/^\d*\.?\d*$/);
      if (
        !val.toString().match(validNumber)
        || parseFloat(val) > (parseFloat(avbl) + update)
      ) {
        Swal.fire({
          text: 'Invalid number entered. Please enter a valid number',
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'OK',
        });
        setCoinAmount(0);
      }
    }
  };

  const updateMarket = () => {
    const data = { ...selectedRow };
    data.tpsl = true;
    data.tradeStartPrice = parseFloat(orderRate);
    data.userInvestedAmount = data.tradeType === 1 ? parseFloat(coinAmount) * parseFloat(orderRate) : parseFloat(coinAmount);
    data.qty = parseFloat(coinAmount) * parseFloat(data.leverage);
    data.fromCurrency = data.fromCurrency._id;
    data.toCurrency = data.toCurrency._id;
    dispatch(updateLeverageOrder(data, id));
    handleCloseMarket();
    handleCloseLimit();
    // setLoader(true);
  };

  function rangeValue(value) {
    const val = value;
    setPercentage(`${val}%`);
    if (avbl) {
      setCoinAmount(avbl * (val / 100));
    }
  }

  const marks = {
    0: '0%',
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
  };

  function wait(ms) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  const handleFutureStop = () => {
    dispatch(stopLeverageOrder(futureOrderId, parseFloat(stopRate), false));
    setShowFuture(false);
    setFutureOrderId('');
    setStopRate(0);
  };

  const getOrders = () => {
    let PnL = 0;
    userOrders?.filter((row) => (row.futuresOrder === 1 && row.marginType === 0 && row.status === 1)).forEach((ordr) => {
      // PnL
      PnL += parseFloat(document.getElementById(`${ordr?._id}pnl`)?.innerText);
    });

    if (PnL) {
      // const vall = avbl + PnL;
      // updateAvbl(vall > 0 ? vall : 0)
    }
  };

  useEffect(() => {
    getOrders();
  }, [currentMarketPrice]);

  const handleClear = () => {
    setCoinPairsFilter([]);
    setAmountRangeFilter([0, 1000000]);
    setFilters({});
    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setStoredFilterData = () => {
    if (filtersJSON) {
      const filters = JSON.parse(filtersJSON);
      setFilters(filters);

      setCoinPairsFilter(filters.contracts || []);
      setAmountRangeFilter(filters.amountRange || [0, 1000000]);
    }
  };

  const setCRMFilters = (filter) => {
    const { currency, amountRange } = filter;

    setCoinPairsFilter(currency);
    setAmountRangeFilter(amountRange);
    setFilters({
      contrats: currency,
      amountRange,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);
    const data = {
      name,
      crmUserId,
      pathname: `${pathname}/pending-orders`,
      currency: filters.contracts || [],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        currency: filters.contracts || [],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toast.error('Select atleast one filter to complete this action.', {
        autoClose: 1000,
      });
    }
  };

  const setStoredColumnsData = () => {
    if (columnsJSON) {
      const columns = JSON.parse(columnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const storeColumnConfig = (data) => {    
    setColumnConfig(data);
    localStorage.setItem(columnsStorageName, JSON.stringify(data));
  };

  useEffect(() => {
    setStoredColumnsData();
  }, []);

  const pendingOrders = useMemo(() => (userOrders ? userOrders.filter((row) => row.futuresOrder === 1).filter((row) => row.status === 0) : []), [userOrders]);

  const coinsPairs = useMemo(() => {
    const pairs = [];
    const uniquePairs = [];

    if (!pendingOrders || !pendingOrders.length) return pairs;

    pendingOrders.forEach(({ pairName }) => {
      if (!uniquePairs.includes(pairName)) {
        uniquePairs.push(pairName);
        pairs.push({
          name: pairName,
          _id: pairName,
        });
      } 
    });

    return pairs;
  }, [pendingOrders]);

  useEffect(() => {
    setFilteredResult(pendingOrders);
    setStoredFilterData();
  }, [pendingOrders]);

  const search = () => {
    const filteredResults = pendingOrders.filter(
      (item) => (!coinPairsFilter.length || coinPairsFilter.includes(String(item.pairName)))
        && (Number(parseFloat(parseFloat(item.qty) * parseFloat(item.tradeStartPrice)).toFixed(2)) >= amountRangeFilter[0] && Number(parseFloat(parseFloat(item.qty) * parseFloat(item.tradeStartPrice)).toFixed(2)) <= amountRangeFilter[1]),
    );

    setFilteredResult(filteredResults);
  };

  useEffect(() => {
    if (pendingOrders.length) search();
    if (filters) localStorage.setItem(filtersStorageName, JSON.stringify(filters));
  }, [coinPairsFilter, amountRangeFilter]);

  const futureColumns = PendingOrderColumns(
    setSelectedRow,
    setFutureStartPrice,
    handleShowFutureStartPrice,
    avbl,
    userOrders,
    currentMarketPrice,
    handleShowTPSL,
    setTP,
    setSL,
    handleShowTrailing,
    setTrailingPrice,
    setCoinAmount,
    setOrderRate,
    handleShowLimit,
    handleShowMarket,
    setStopRate,
    rates,
    setOrderId,
    setFutureOrderId,
    handleShowFuture,
    columnConfig,
    coinsPairs,
    coinPairsFilter,
    setCoinPairsFilter,
    amountRangeFilter,
    setAmountRangeFilter,
    filters,
    setFilters,
  );

  return (
    <>
      <div>
        <div className="action__btn-row">
          {
            crmFilters ? (
              <DatatableFilters 
                ref={datatableFiltersRef}
                filters={crmFilters} 
                setFilters={setCRMFilters}
                createFilter={createUserCRMFilter}
                deleteFilter={deleteUserCRMFilter}
                updateFilter={updateUserCRMFilter}
                storageKey={filterIdStorageName}
                pathname={`${pathname}/pending-orders`}
              /> 
            ) : null
          }
          <DatatableColumns setColumns={storeColumnConfig} columnConfig={columnConfig} />
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={handleClear}>
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>
          <button type="button" className="btn btn-default icon-btn ms-1" onClick={getOrders}>
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          {pendingOrders && pendingOrders.length
            ? (
              <DataTable
                columns={futureColumns}
                data={filteredResult}
                pagination
                fixedHeader
                persistTableHead
                theme="solarizedd"
              />
            ) : (
              <div className="no-tbl-data">No Orders Found!</div>
            )}
        </div>
      </div>

      {/* entry price model */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showFutureStartPrice} centered onHide={handleCloseFutureStartPrice}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4">
              <span>
                Start Trading Price
                {selectedRow?.fromCurrency?.symbol}
              </span>
            </div>
            <div className="stop-loss-usdt">
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Start Trading Price"
                  min="0"
                  value={futureStartPrice}
                  onChange={(e) => setFutureStartPrice(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateFutureTradeStartprice()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseFutureStartPrice}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* entry price model close */}

      {/* TP/SL model */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTPSL} centered onHide={handleCloseTPSL}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>TP/SL</span></div>

            <div className="d-flex justify-content-between mb-2">
              <span>Entry Price</span>
              <span>{selectedRow.tradeStartPrice}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span>Liq. Price</span>
              <span className="text-green">{selectedRow.tradeEndPrice}</span>
            </div>

            <div className="take-profit-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>Take Profit USDT</span>
                <span>Last Traded Price</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={TP}
                  onChange={(e) => setTP(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Take Profit</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>
                  Stop Loss
                  {selectedRow?.fromCurrency?.symbol}
                </span>
                <span>Last Traded Price</span>
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  min="0"
                  value={SL}
                  onChange={(e) => setSL(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  <span style={{ color: 'rgba(255, 255, 255, 0.46)', fontSize: '12px', marginRight: '5px' }}>Stop Loss</span>
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateTPSL()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseTPSL}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* TP/SL model close */}

      {/* Trailing Stop model  */}
      <Modal className="withdrawal-modal limit-modal tp-sl-modal" show={showTrailing} centered onHide={handleCloseTrailing}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Trailing Stop</span></div>
            <div className="d-flex justify-content-between mb-2">
              <span>Entry Price</span>
              <span>{selectedRow.tradeStartPrice}</span>
            </div>
            <div className="d-flex justify-content-between mb-4">
              <span>Liq. Price</span>
              <span className="text-green">{selectedRow.tradeEndPrice}</span>
            </div>

            <div className="stop-loss-usdt">
              <div className="d-flex justify-content-between mb-1">
                <span>
                  Trailing Stop
                  {selectedRow?.fromCurrency?.symbol}
                </span>
                {/* <span className="text-white">Last Traded Price</span> */}
              </div>
              <InputGroup className="mb-3">
                <FormControl
                  type="number"
                  placeholder="Trailing Stop"
                  min="0"
                  value={trailingPrice}
                  onChange={(e) => setTrailingPrice(e.target.value)}
                />
                <InputGroup.Text id="basic-addon2">
                  {selectedRow?.fromCurrency?.symbol}
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateTrailing()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseTrailing}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/*  Trailing Stop model close */}

      {/* Limit Close model */}
      <Modal className="withdrawal-modal limit-modal" show={showLimit} centered onHide={handleCloseLimit} backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Limit Close</span></div>
            <span>
              Entry Price
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-4">
              <FormControl
                placeholder="Rate"
                aria-label=""
                aria-describedby=""
                value={orderRate}
                onChange={(e) => { setOrderRate(e.target.value); }}
              />
              <InputGroup.Text
                className="point"
                onClick={() => {
                  setRate(!rate);
                }}
              >
                +/-
              </InputGroup.Text>
            </InputGroup>
            <span>
              Order by Qty
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                step="0.1"
                placeholder="Price"
                min="0.0"
                max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                value={coinAmount}
                onChange={(e) => {
                  const val = e.target.value;
                  setCoinAmount(val);
                }}
                onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.toCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>

            <Slider
              min={0}
              step={0.1}
              marks={marks}
              defaultValue={[0, 25, 50, 75, 100]}
              value={percentage.replace('%', '')}
              onChange={() => rangeValue}
              className="mb-4 range-slider"
            />
            <p style={{ fontSize: '12px', padding: '0 12px' }} className="">4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be 9.7290USDT (includng of EST, closing tees 3.4309USDT)</p>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateMarket()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseLimit}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Limit Close model close */}

      {/* Market model */}
      <Modal className="withdrawal-modal limit-modal market-modal" show={showMarket} centered onHide={handleCloseMarket}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="buy-tabs">
            <div className="mb-4"><span>Market Close</span></div>

            <span>
              Order by Qty
              {selectedRow?.toCurrency?.symbol}
            </span>
            <InputGroup className="mb-3">

              <FormControl
                type="number"
                step="0.1"
                placeholder="Price"
                min="0.0"
                max={avbl ? avbl + selectedRow.userInvestedAmount : 10000000}
                value={coinAmount}
                onChange={(e) => {
                  const val = e.target.value;
                  setCoinAmount(val);
                }}
                onBlur={(e) => handleLimit(e.target.value, parseFloat(selectedRow.userInvestedAmount))}
              />
              <InputGroup.Text id="basic-addon2">
                {selectedRow?.toCurrency?.symbol}
              </InputGroup.Text>
            </InputGroup>

            <p style={{ fontSize: '12px', padding: '0 12px' }} className="mb-4 mt-2">4.70 contract(s) will be closed at 1,216.65 price, and your expected points will be 9.7290USDT (includng of EST, closing tees 3.4309USDT)</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => updateMarket()}>
            Confirm
          </Button>
          <Button variant="danger" onClick={handleCloseMarket}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Market model close */}

      {/* Stop trade model */}
      <Modal className="withdraw-details two-factor-auth text-center" centered backdrop="static" show={showFuture} onHide={handleCloseFuture}>
        <Modal.Header className="modal-main-heading" closeButton> </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <span>
              Are you sure want to stop it at
              {stopRate}
              {' '}
              rate?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={handleCloseFuture}>No</Button>
          <Button variant="danger" onClick={() => { handleFutureStop(); }}>Yes</Button>
        </Modal.Footer>
      </Modal>
      {/* Stop trade model close */ }

    </>
  );
}

export default PendingOrder;
