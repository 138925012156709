import { 
  GET_USER_STAKING_SETTINGS,
  CREATE_USER_STAKING_SETTING,
  UPDATE_USER_STAKING_SETTING,
  DELETE_USER_STAKING_SETTING,
} from './userStakingTypes';


export default (state = [], action) => {
  switch (action.type) {
    case GET_USER_STAKING_SETTINGS:
      return action.payload;
    case CREATE_USER_STAKING_SETTING:
      return [...state, action.payload];
    case UPDATE_USER_STAKING_SETTING:
      return state.map((item) => (item._id === action.payload._id ? action.payload : item));
    case DELETE_USER_STAKING_SETTING:
      return state.filter((item) => item._id !== action.payload._id);
    default:
      return state;
  }
};
