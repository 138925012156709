/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import {
  getMetalsSettings,
  updateMetalsSettingState,
} from '../../redux/metalSetting/metalsSettingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function MetalsSettings() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loader, setLoader] = useState(true);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState('');

  const { metalsSettings, metalsSettingsFetched } = useSelector((state) => state?.metalsSetting);

  useEffect(() => {
    dispatch(getMetalsSettings());
  }, []);

  useEffect(() => {
    if (metalsSettingsFetched) {
      dispatch(updateMetalsSettingState());
      setLoader(false);
    }
    setFilteredItems(metalsSettings);
  }, [metalsSettings, metalsSettingsFetched]);

  const searchByCurrency = (e) => {
    const searchText = e.target.value.toLowerCase();
    const searchData = metalsSettings.filter(
      (row) => (
        row.symbol?.toLowerCase().includes(searchText)
                || row.alias?.toLowerCase().includes(searchText)
                || row.secondaryCurrency?.symbol?.toLowerCase().includes(searchText)
      ),
    );
    setFilteredItems(searchData);
    setFilterCurrency(searchText);
  };

  return (
    loader ? <FullPageTransparentLoader /> : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
          </div>
          <div>
            <h4 className="mb-3 text-capitalize">Metals Settings</h4>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Alias</th>
                    <th>Base Currency</th>
                    <th>Leverage</th>
                    <th>Lot Step</th>
                    <th>Lot Size</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="search-holder">
                    <td colSpan="7">
                      <div className="input-group">
                        <input
                          type="text"
                          placeholder="Search"
                          name="filterCurrency"
                          value={filterCurrency}
                          onChange={searchByCurrency}
                        />
                      </div>
                    </td>
                  </tr>
                  {filteredItems.length > 0 ? (
                    filteredItems.map((currentMetalsSetting) => (
                      <tr key={`metalsSetting-${currentMetalsSetting._id}`}>
                        <td>{currentMetalsSetting.symbol}</td>
                        <td>{currentMetalsSetting.alias}</td>
                        <td>{currentMetalsSetting.secondaryCurrency?.symbol}</td>
                        <td>{currentMetalsSetting.leverage}</td>
                        <td>{currentMetalsSetting.lotStep || ''}</td>
                        <td>{currentMetalsSetting.lotSize || ''}</td>
                        <td>
                          <div className="action-btn">
                            <Link className="btn-complete" to={`/edit-metals-setting/${currentMetalsSetting._id}`}>
                              <FontAwesomeIcon icon={faPencil} />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No Record Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    ));
};

export default MetalsSettings;
