import { toast } from 'react-toastify';
import { UPDATE_ACCOUNT_FIELDS } from './accountTypes';
import { apiHelper } from '../apiHelper';

export const updateAccountFields = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('patch', '/api/account', data);
    if (res?.data && res?.data?.success) {
      const { data } = res;
      dispatch({
        type: UPDATE_ACCOUNT_FIELDS,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
