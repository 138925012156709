import { toast } from 'react-toastify';
import {
  GET_STOCK_SETTINGS,
  GET_STOCK_SETTING,
  ADD_STOCK_SETTING,
  EDIT_STOCK_SETTING,
  DELETE_STOCK_SETTING,
  TOGGLE_STOCK_SETTING_STATE,
  ERROR_STOCK_SETTING_STATE,
} from './stockSettingTypes';
import { apiHelper } from '../apiHelper';
  
export const getStockSettings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/stock-setting', '');
    if (res?.data && res?.data?.stockSettings) {
      const { data } = res;
      await dispatch({
        type: GET_STOCK_SETTINGS,
        payload: data?.stockSettings,
      });
    } else {
      await dispatch({
        type: GET_STOCK_SETTINGS,
        payload: [],
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const getStockSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/stock-setting/${id}`, '');
    if (res?.data && res?.data?.stockSetting) {
      const { data } = res;
      await dispatch({
        type: GET_STOCK_SETTING,
        payload: data?.stockSetting,
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const addStockSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/stock-setting/add', data);
  
    if (res?.data && res?.data?.stockSetting_) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: ADD_STOCK_SETTING,
        payload: data?.stockSetting_,
      });
    } else {
      await dispatch({
        type: ERROR_STOCK_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STOCK_SETTING_STATE,
    });
    //   console.log(error.message);
    toast.error(error.message);
  }
};
  
export const editStockSetting = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/stock-setting/${id}`, bodyData);
    if (res?.data && res?.data?.stockSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: EDIT_STOCK_SETTING,
        payload: data?.stockSetting,
      });
    } else {
      await dispatch({
        type: ERROR_STOCK_SETTING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STOCK_SETTING_STATE,
    });
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const deleteStockSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/stock-setting/${id}`, '');
    if (res?.data && res?.data?.stockSetting) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: DELETE_STOCK_SETTING,
        payload: data?.stockSetting?._id,
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const updateStockSettingState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STOCK_SETTING_STATE,
    });
  } catch (error) {
    //   console.log(error.response.message);
  }
};
