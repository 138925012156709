import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { editForexCurrency, updateForexCurrencyState } from '../../../redux/forexCurrency/forexCurrencyActions';
import { ModalContext } from '../../../context';

export function EditForexCurrency({ currencyData }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);

  const [forexCurrency, setForexCurrency] = useState(currencyData);

  const error = useSelector((state) => state.forexCurrency?.forexCurrencyError);
  const forexCurrencyData = useSelector((state) => state.forexCurrency?.forexCurrency);
  const forexCurrencyFetched = useSelector((state) => state.forexCurrency?.forexCurrencyFetched);

  const {
    register, handleSubmit, control, reset, formState: { errors }, 
  } = useForm();

  useEffect(() => {
    if (Object.keys(forexCurrencyData)?.length > 0 && forexCurrencyFetched) {
      setForexCurrency(forexCurrencyData);
      reset(forexCurrencyData);
      dispatch(updateForexCurrencyState());
    }
  }, [forexCurrencyData, forexCurrencyFetched]);

  useEffect(() => {
    if (error) {
      dispatch(updateForexCurrencyState());
    }
  }, [error]);

  const handleEditForexCurrency = (formData) => {
    const data = {
      pairAlias: formData.pairAlias ? formData.pairAlias : '',
      leverage: formData.leverage ? formData.leverage : 0,
      lotStep: formData.lotStep ? Number(formData.lotStep) : 0,
      lotSize: formData.lotSize ? formData.lotSize : 0,
    };

    dispatch(editForexCurrency(forexCurrency._id, data));
    hideModal();
  };

  return (
    <form onSubmit={handleSubmit(handleEditForexCurrency)}>
      <div className="form-group col-md-12">
        <label className="control-label">Primary Coin</label>
        <input type="text" className="form-control" defaultValue={forexCurrency?.primaryCurrency?.symbol} disabled />
      </div>
      <div className="form-group col-md-12">
        <label className="control-label">Secondary Coin</label>
        <input type="text" className="form-control" defaultValue={forexCurrency?.secondaryCurrency?.symbol} disabled />
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Pair Symbol</label>
        <input type="text" className="form-control" defaultValue={forexCurrency?.pairSymbol} disabled />
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Pair Alias</label>
        <input
          type="text"
          className="form-control"
          {...register('pairAlias')}
          name="pairAlias"
          control={control}
          defaultValue={forexCurrency?.pairAlias}
        />
        {errors?.pairAlias && <span className="errMsg">{errors.pairAlias.message}</span>}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Leverage</label>
        <input
          className="form-control"
          type="number"
          {...register('leverage')}
          name="leverage"
          control={control}
          defaultValue={forexCurrency?.leverage}
        />
        {errors?.leverage && <span className="errMsg">{errors.leverage.message}</span>}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Lot Step</label>
        <input
          type="text"
          className="form-control"
          {...register('lotStep')}
          name="lotStep"
          control={control}
          defaultValue={forexCurrency?.lotStep}
        />
        {errors?.lotStep && <span className="errMsg">{errors.lotStep.message}</span>}
      </div>
      <div className="form-group col-md-12 pt-2">
        <label className="control-label">Lot Size</label>
        <input
          className="form-control"
          type="number"
          {...register('lotSize')}
          name="lotSize"
          control={control}
          defaultValue={forexCurrency?.lotSize}
        />
        {errors?.lotSize && <span className="errMsg">{errors.lotSize.message}</span>}
      </div>
      <div>
        <button className="btn btn-default mt-3" type="submit">Save</button>
      </div>
    </form>      
  );
}
