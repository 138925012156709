import { toast } from 'react-toastify';
import {
  GET_FOREX_CURRENCIES,
  GET_FOREX_CURRENCY,
  ADD_FOREX_CURRENCY,
  EDIT_FOREX_CURRENCY,
  DELETE_FOREX_CURRENCY,
  TOGGLE_FOREX_CURRENCY_STATE,
  ERROR_FOREX_CURRENCY_STATE,
} from './forexCurrencyTypes';
import { apiHelper } from '../apiHelper';
  
export const getForexCurrencies = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/forex-currency', '');
    if (res?.data && res?.data?.forexCurrencies) {
      const { data } = res;
      await dispatch({
        type: GET_FOREX_CURRENCIES,
        payload: data?.forexCurrencies,
      });
    } else {
      await dispatch({
        type: GET_FOREX_CURRENCIES,
        payload: [],
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const getForexCurrency = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/forex-currency/${id}`, '');
    if (res?.data && res?.data?.forexCurrency) {
      const { data } = res;
      await dispatch({
        type: GET_FOREX_CURRENCY,
        payload: data?.forexCurrency,
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const addForexCurrency = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/forex-currency/add', data);
  
    if (res?.data && res?.data?.forexCurrency_) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: ADD_FOREX_CURRENCY,
        payload: data?.forexCurrency_,
      });
    } else {
      await dispatch({
        type: ERROR_FOREX_CURRENCY_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_FOREX_CURRENCY_STATE,
    });
    //   console.log(error.message);
    toast.error(error.message);
  }
};
  
export const editForexCurrency = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/forex-currency/${id}`, bodyData);
    if (res?.data && res?.data?.forexCurrency) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: EDIT_FOREX_CURRENCY,
        payload: data?.forexCurrency,
      });
    } else {
      await dispatch({
        type: ERROR_FOREX_CURRENCY_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_FOREX_CURRENCY_STATE,
    });
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const deleteForexCurrency = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/forex-currency/${id}`, '');
    if (res?.data && res?.data?.forexCurrency) {
      const { data } = res;
  
      toast.success(data?.message, {
        autoClose: 1000,
      });
  
      await dispatch({
        type: DELETE_FOREX_CURRENCY,
        payload: data?.forexCurrency?._id,
      });
    }
  } catch (error) {
    //   console.log(error.response.message);
    toast.error(error.response.message);
  }
};
  
export const updateForexCurrencyState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_FOREX_CURRENCY_STATE,
    });
  } catch (error) {
    //   console.log(error.response.message);
  }
};
