export const openOrdersColumns = [
  {
    name: 'Order ID',
    field: '_id',
    selected: true,
  },
  {
    name: 'User Name',
    field: 'user.firstName,user.lastName',
    selected: true,
  },
  {
    name: 'Contracts',
    field: 'marginType',
    selected: true,
  },
  {
    name: 'QTY',
    field: 'qty',
    selected: true,
  },
  {
    name: 'Value',
    field: 'qty,tradeStartPrice',
    selected: true,
  },
  {
    name: 'Entry Price',
    field: 'tradeStartPrice',
    selected: true,
  },
  {
    name: 'Liq Price',
    field: 'liq',
    selected: true,
  },
  {
    name: 'Position Margin',
    field: 'positionMargin',
    selected: true,
  },
  {
    name: 'Unrealized P&L',
    field: 'unrealizedPnl',
    selected: true,
  },
  {
    name: 'Time Opened',
    field: 'createdAt',
    selected: true,
  },
  {
    name: 'Commission',
    field: 'commission',
    selected: true,
  },
  {
    name: 'Swap',
    field: 'endSwap',
    selected: true,
  },
  {
    name: 'Actions',
    field: '',
    selected: true,
  },
];
