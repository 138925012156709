export const convertObjectToArray = (obj) => Object.entries(obj).map(([key, value]) => ({ key, value }));

export const handleKeyChange = (index, newKey, authData, setAuthData) => {
  const newAuthData = [...authData];
  newAuthData[index].key = newKey;
  setAuthData(newAuthData);
};

export const handleValueChange = (index, newValue, authData, setAuthData) => {
  const newAuthData = [...authData];
  newAuthData[index].value = newValue;
  setAuthData(newAuthData);
};

export const handleAddData = (authData, setAuthData, dataKey) => {
  const newAuthData = [...authData, { key: dataKey, value: '' }];
  setAuthData(newAuthData);
};
