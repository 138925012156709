export const userDepositColumns = [
  {
    name: 'Received At',
    selected: true,
  },
  {
    name: 'Currency',
    selected: true,
  },
  {
    name: 'Amount',
    selected: true,
  },
  {
    name: 'In USD',
    selected: true,
  },
  {
    name: 'From',
    selected: true,
  },
  {
    name: 'To',
    selected: true,
  },
  {
    name: 'Account',
    selected: true,
  },
  {
    name: 'Status',
    selected: true,
  },
  {
    name: 'Info',
    selected: true,
  },
  {
    name: 'Real/Fake',
    selected: true,
  },
  {
    name: 'Action',
    selected: true,
  },
];
