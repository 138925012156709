import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  CREATE_TRADING_SETTING, DELETE_TRADING_SETTING, UPDATE_TRADING_SETTING, GET_TRADING_SETTINGS, 
} from './tradingSettingTypes';

export const getSettings = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/trading-setting/${id}`, '');

    if (res && res.data && res.data.settings) {
      await dispatch({
        type: GET_TRADING_SETTINGS,
        payload: res.data.settings,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const createSetting = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/trading-setting', data);

    if (res && res.data && res.data.setting) {
      await dispatch({
        type: CREATE_TRADING_SETTING,
        payload: res.data.setting,
      });

      toast.success('Setting was successfully created');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateSetting = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/trading-setting/${id}`, data);

    if (res && res.data && res.data.setting) {
      await dispatch({
        type: UPDATE_TRADING_SETTING,
        payload: res.data.setting,
      });

      toast.success('Setting was successfully updated');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteSetting = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/trading-setting/${id}`, '');

    if (res && res.data && res.data.success) {
      await dispatch({
        type: DELETE_TRADING_SETTING,
        payload: id,
      });

      toast.success('Settings was successfully deleted');
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
